'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popup from './Popup.react';
import CopyToClipboard from '../Dashboard/Header/CalendarSync/CopyToClipboard.react';
import { getConfig } from '../../utils/Env';
import _ from 'lodash';
import './MultiSharePopup.scss';

export default class MultiSharePopup extends Component {
    static propTypes = {
        meals: PropTypes.array,
        buttonText: PropTypes.string,
        popupText: PropTypes.string,
        dropdownBtnClass: PropTypes.string,
        positionClassName: PropTypes.string,
        onClick: PropTypes.func,
        loading: PropTypes.bool,
    };

    static contextTypes = {
        recipes: PropTypes.object,
        foods: PropTypes.object
    }

    static defaultProps = {
        buttonText: "share",
        popupText: "To share, copy and paste this URL into an email, message or social media post.",
        dropdownBtnClass: null,
        loading: false,
    };

    render = () => {
        const { meals, className, buttonText, popupText, dropdownBtnClass, positionClassName, onClick, loading } = this.props;
        const { recipes, foods } = this.context;

        if (_.isEmpty(meals)) {
            return <span />;
        }

        const isSingleMeal = meals.length === 1;

        let shareDetails = meals.map((meal, index) => {
            if (meal.recipe_uuid && recipes[meal.recipe_uuid]) {
                const recipeTitle = recipes[meal.recipe_uuid].title;
                const dish = isSingleMeal ? '' : `DISH ${index + 1}: ${recipeTitle}`;
                return { name: dish, url: 'https://' + getConfig('www_host') + `/recipes/${meal.recipe_uuid}` };
            } else if (meal.food_uuid && foods[meal.food_uuid]) {
                const food = foods[meal.food_uuid];
                const foodName = food.pretty_name || food.name;
                const dish = isSingleMeal ? '' : `DISH ${index + 1}: ${foodName}`;
                return { name: dish, url: 'https://' + getConfig('www_host') + `/foods/${meal.food_uuid}` };
            } else {
                return;
            }
        });

        shareDetails = shareDetails.filter((details) => details !== undefined);

        if (_.isEmpty(shareDetails)) {
            return <span />;
        }

        return  (
            <Popup positionClassName={positionClassName} dropdownBtnClass={dropdownBtnClass} className={classNames("share-popup", "multi-share-popup", className)} button={<><i onClick={onClick} className="feather feather-share" /> <em>{buttonText}</em></>}>
                {loading ? <i className="icon-spinner"/>
                : (<div className="share-popup-content">
                    <p>{popupText}</p>
                    {shareDetails.map(({name, url}, key) => (
                        <div className="copy-url-container" key={key}>
                            <div className='title'>{name}</div>
                            <CopyToClipboard value={url} />
                        </div>
                    ))}
                </div>)}
            </Popup>
        );
    }
}
