'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Expander from '../Expander.react';
import SkillLevelFilter from '../Filters/SkillLevel.react';

import allTags from '../../../tables/tags';

export default class SkillLevel extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
    };

    static defaultProps = {
        isExpander: false,
    };

    render() {
        const { params, onChangeParams, isExpander } = this.props;
        let badge = <em data-active={false} />;

        const skillTags = (params.filters['!tags'] || []).concat(params.filters.tags || [])
                                                         .filter(tag => allTags.skill.tags.includes(tag));

        if (skillTags.length > 0) {
            badge = <em data-active={true}>1</em>
        }

        const button = (
            <span className="global-search-filter-btn">
                Skill Level {badge}
            </span>
        );
        const isFilterAvailable = params.types.includes('recipe') || params.types.includes('combo');

        if (!isFilterAvailable) {
            return <span />
        }

        return (
            <span className="global-search-hideable-filter" data-active={true}>
                <Expander button={button}>
                    <SkillLevelFilter params={params} onChangeParams={onChangeParams} />
                </Expander>
            </span>
        );
    }
}
