'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import Select from '../../../pro/components/Widgets/Select.react';
import UnitsMode from '../../../pro/components/Widgets/UnitsMode.react';
import DateSelector from '../../../pro/components/Widgets/DateSelector.react';
import ConfigWarning from '../../Widgets/ConfigWarning.react';
import Alert from '../../Widgets/Alert/Alert.react';
import AuthStore from '../../../stores/AuthStore';
import { getConfig } from '../../../utils/Env';
import energyFormulas from '../../../utils/EnergyCalculator';
import { conditionSortCompare } from '../../../utils/Conditions';
import { cmToFeetInches } from '../../../pro/utils/Patients';
import conditionsCopy from '../../../tables/conditions-descriptions';
import '../../../pro/components/Modals/Modals.scss';
import '../../MyAccount/PreferencesForm.scss';
import './EstimatorModal.scss';
import NutritionPatternStore from '../../../stores/NutritionPatternStore';
import NutritionPatternActions from '../../../actions/NutritionPatternActions';

const mifflin = energyFormulas.filter(f => f.prop === 'mifflin')[0];

const heightFeetOpts = [
    {value: 3, label: '3 ft.'},
    {value: 4, label: '4 ft.'},
    {value: 5, label: '5 ft.'},
    {value: 6, label: '6 ft.'},
    {value: 7, label: '7 ft.'},
    {value: 8, label: '8 ft.'},
];

const heightInchOpts = [
    {value: 0, label: '0 in.'},
    {value: 1, label: '1 in.'},
    {value: 2, label: '2 in.'},
    {value: 3, label: '3 in.'},
    {value: 4, label: '4 in.'},
    {value: 5, label: '5 in.'},
    {value: 6, label: '6 in.'},
    {value: 7, label: '7 in.'},
    {value: 8, label: '8 in.'},
    {value: 9, label: '9 in.'},
    {value: 10, label: '10 in.'},
    {value: 11, label: '11 in.'},
];

export default class EnergyEstimatorModal extends Component {
    static propTypes = {
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        let {
            conditions = [],
            activity_level = '',
            height_cm = '',
            weight_kg = '',
            units_mode = 'english',
            due_date = null,
            fetus_count = null,
            birthdate = null,
            gender = null,
            target_energy_kcal = null,
        } = props;

        const height = cmToFeetInches(height_cm || 0);

        if (!['english', 'metric'].includes(units_mode)) {
            units_mode = 'english';
        }

        if (target_energy_kcal == 2000) {
            target_energy_kcal = '';
        }

        this.state = {
            conditions,
            activity_level,
            height_cm,
            weight_kg,
            units_mode,
            due_date,
            fetus_count,
            birthdate,
            gender,
            target_energy_kcal,
            avoidances: [],
            daily_totals: {},
            height_feet: height.feet,
            height_inches: height.inches,
            weight_lbs: weight_kg ? Math.round(weight_kg * 2.20462) : '',
            mealType: {},
            mealTypes: [],
            dirty: false,
            isExpanderOpen: false,
            isExpanderOpening: false,
            nutritionPatterns: NutritionPatternStore.getNutritionPatterns(),
            nutritionPatternsLoading: NutritionPatternStore.isLoading(),
        };
    }

    componentDidMount = () => {
        const { nutritionPatterns } = this.state;
        
        if (nutritionPatterns && !nutritionPatterns.length) {
            NutritionPatternActions.reload();
        } else {
            const defaultNutritionPattern = nutritionPatterns[0];
            this.setDefaultNutritionPattern(defaultNutritionPattern);
            this.setState({
                conditions: [{name: defaultNutritionPattern.name, uuid: defaultNutritionPattern.uuid}],
            });
        }

        NutritionPatternStore.addChangeListener(this.onNutritionPatternsChange);
    }

    componentWillUnmount = () => {
        NutritionPatternStore.removeChangeListener(this.onNutritionPatternsChange);
    }

    setDefaultNutritionPattern = async (defaultNutritionPattern) => {
        const { gender, birthdate, weight_kg, target_energy_kcal } = this.state;

        if (!gender || !birthdate || !weight_kg || !target_energy_kcal) {
            return;
        }

        const {preferences} = await this.resolvePatterns(
            {gender, birthdate, weight_kg, target_energy_kcal, calculate_implicit: true},
            defaultNutritionPattern
        );

        this.setState({
            preferences: preferences[0],
            mealTypes: preferences[0]?.meal_types
        });
    }

    onNutritionPatternsChange = () => {
        const { conditions } = this.state;
        const nutritionPatterns = NutritionPatternStore.getNutritionPatterns();
        const nutritionPatternsLoading = NutritionPatternStore.isLoading();

        if(!conditions.length && nutritionPatterns) {
            const defaultNutritionPattern = nutritionPatterns[0];
            this.setDefaultNutritionPattern(defaultNutritionPattern);
            this.setState({
                conditions: [{name: defaultNutritionPattern.name, uuid: defaultNutritionPattern.uuid}],
            });
        }

        this.setState({nutritionPatterns, nutritionPatternsLoading});
    }
 
    resolvePatterns = async (query, pattern) => {
        try {
            const preferences = await AuthStore.fetch({
                url: getConfig('users_api') + pattern.links.resolve.href,
                query
            });
            return preferences;
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    calc = async () => {
        const { conditions, gender, birthdate, weight_kg, height_cm, activity_level, goal_weight_kg, nutritionPatterns } = this.state;

        let age = 32, ageDays = 32 * 365;
        if (!(gender && birthdate && weight_kg && height_cm && activity_level)) {
            return;
        }

        if (moment(birthdate).isValid()) {
            age = moment().diff(birthdate, 'year');
            ageDays = moment().diff(birthdate, 'day');
        }

        const params = {
            gender,
            weightKg: weight_kg,
            heightCm: height_cm,
            activity: activity_level,
            age,
            ageDays,
        };

        let target_energy_kcal = Math.round(mifflin.compute(params));

        if (target_energy_kcal === false) {
            this.setState({target_energy_kcal: '', dirty: true});
            return;
        }

        if (conditions.includes('Pregnancy')) {
            // What trimester are we in, and how many fetus's do we have?
            const { due_date, fetus_count = 1 } = this.state;

            const weeksPregnant = moment().diff(moment(due_date).subtract(42, 'weeks'), 'weeks');

            // First Trimester - NO ADDITIONAL CALORIE NEEDED
            // Second Trimester
            if (weeksPregnant >= 14 && weeksPregnant <= 27) {
                // 340 kcal for the first baby, and 175 kcal for each additional
                target_energy_kcal += 340 + (fetus_count - 1) * 175;
            }

            // Third Trimester
            if (weeksPregnant >= 28) {
                target_energy_kcal += 450 + (fetus_count - 1) * 225;
            }
        } else if (goal_weight_kg) {
            if (goal_weight_kg > weight_kg * 1.025) {
                // Gain weight
                target_energy_kcal += 500;
            } else if (goal_weight_kg < weight_kg * .975) {
                // Lose weight
                target_energy_kcal -= 500;
            }
        }

        if (conditions.includes('Lactation')) {
            target_energy_kcal += 500;
        }

        if (age > 12 && target_energy_kcal < 1200) {
            target_energy_kcal = 1200;
        } else if (age <= 12 && target_energy_kcal < 900) {
            target_energy_kcal = 900;
        }

        const condition = conditions[0];
        const nutritionPattern = nutritionPatterns.find((pattern) => pattern.uuid === condition.uuid);

        const {preferences} = await this.resolvePatterns(
            {gender, birthdate, weight_kg, target_energy_kcal, calculate_implicit: true},
            nutritionPattern
        );

        this.setState({target_energy_kcal, preferences: preferences[0], mealTypes: preferences[0]?.meal_types, dirty: true});
    }

    onChangeCondition = (conditionUuid) => {
        let { gender, avoidances, nutritionPatterns } = this.state;

        const pattern = nutritionPatterns.find((pattern) => pattern.uuid == conditionUuid);

        if (pattern.tags.includes('Pregnancy') || pattern.tags.includes('Lactation')) {
            gender = 'female';
        }

        (pattern.avoidances || []).forEach(avoid => {
            if (!avoidances.includes(avoid)) {
                avoidances.push(avoid);
            }
        });

        this.setState({conditions: [{name: pattern.name, uuid: pattern.uuid}], avoidances, gender, dirty: true}, this.calc);
    }

    toggleUnitsMode = () => {
        let { units_mode } = this.state;
        units_mode = units_mode === 'metric' ? 'english' : 'metric';

        this.setState({units_mode, dirty: true});
    }

    onChangeHeightFeet = (height_feet) => {
        const { height_inches } = this.state;

        const feet = parseInt(height_feet) || 0,
              inches = parseInt(height_inches) || 0;

        const height_cm = Math.round(((feet * 12) + inches) * 2.54) || '';

        this.setState({height_feet, height_cm, dirty: true}, this.calc);
    }

    onChangeHeightInches = (height_inches) => {
        const { height_feet } = this.state;

        const feet = parseInt(height_feet) || 0,
              inches = parseInt(height_inches) || 0;

        const height_cm = Math.round(((feet * 12) + inches) * 2.54) || '';

        this.setState({height_inches, height_cm, dirty: true}, this.calc);
    }

    onChangeHeightCm = (ev) => {
        const height_cm = ev.target.value;

        const english = cmToFeetInches(height_cm) || {feet: '', inches: ''};

        this.setState({height_cm, height_feet: english.feet, height_inches: english.inches}, this.calc);
    }


    onChangeWeightLbs = (ev) => {
        const weight_lbs = ev.target.value;

        const weight_kg = (Math.round(weight_lbs * 0.453592 * 10) / 10) || '';

        this.setState({weight_lbs, weight_kg}, this.calc);
    }

    onChangeWeightKg = (ev) => {
        const weight_kg = ev.target.value;

        const weight_lbs = (Math.round(weight_kg / 0.453592 * 10) / 10) || '';

        this.setState({weight_kg, weight_lbs}, this.calc);
    }

    onChangeActivityLevel = (activity_level) => {
        this.setState({activity_level}, this.calc);
    }

    onChangeGoalWeightLbs = (ev) => {
        let { weight_kg, weekly_goal_kcal } = this.state;
        const goal_weight_lbs = ev.target.value;
        const goal_weight_kg = (Math.round(goal_weight_lbs * 0.453592 * 10) / 10)|| '';

        if (goal_weight_kg > weight_kg * 1.025) {
            // Gain weight
            weekly_goal_kcal = 500;
        } else if (goal_weight_kg < weight_kg * .975) {
            // Lose weight
            weekly_goal_kcal = -500;
        } else {
            weekly_goal_kcal = 0;
        }

        this.setState({goal_weight_lbs, goal_weight_kg, weekly_goal_kcal}, this.calc);
    }

    onChangeGoalWeightKg = (ev) => {
        let { weight_kg, weekly_goal_kcal } = this.state;
        const goal_weight_kg = ev.target.value;
        const goal_weight_lbs = (Math.round(goal_weight_kg / 0.453592 * 10) / 10) || '';

        if (goal_weight_kg > weight_kg * 1.025) {
            // Gain weight
            weekly_goal_kcal = 500;
        } else if (goal_weight_kg < weight_kg * .975) {
            // Lose weight
            weekly_goal_kcal = -500;
        } else {
            weekly_goal_kcal = 0;
        }

        this.setState({goal_weight_kg, goal_weight_lbs, weekly_goal_kcal}, this.calc);
    }

    onChangeTargetEnergyKcal = (ev) => {
        const target_energy_kcal = ev.target.value;

        this.setState({target_energy_kcal, dirty: true});
    }

    onChangeDueDate = (due_date) => {
        this.setState({due_date, dirty: true}, this.calc);
    }

    onChangeFetusCount = (fetus_count) => {
        this.setState({fetus_count, dirty: true}, this.calc);
    }

    onChangeGender = (gender) => {
        this.setState({gender, dirty: true}, this.calc);
    }

    onChangeBirthdate = (birthdate) => {
        this.setState({
            birthdate,
            dirty: true,
            age: birthdate ? moment().diff(birthdate, 'years') : false,
        }, this.calc);
    }

    onChangeCalories = (target_energy_kcal) => {
        this.setState({target_energy_kcal});
    }

    validate = () => {
        const { mealType, target_energy_kcal, birthdate, gender, conditions, nutritionPatterns } = this.state;

        if (!conditions.length) {
            return {error: 'conditions', alert: 'Please select a health condition / lifestyle'};
        }

        if (!target_energy_kcal) {
            return {alert: 'Please choose calories per day', error: 'target_energy_kcal'};
        }

        if (!mealType.name) {
            return {alert: 'Please choose which meal type to calculate nutrition limits for', error: 'mealType'};
        }

        return true;
    }

    apply = async () => {

        let alert = false;

        if (true !== (alert = this.validate())) {
            this.setState(alert);
            return;
        }

        const { completed, closeModal, onChangeValues } = this.props;

        const {
            conditions,
            activity_level,
            height_cm,
            weight_kg,
            units_mode,
            due_date,
            fetus_count,
            birthdate,
            gender,
            target_energy_kcal,
            mealType,
            preferences
        } = this.state;

        delete preferences.available_eating_patterns;

        onChangeValues({
            conditions,
            activity_level,
            height_cm,
            weight_kg,
            units_mode,
            due_date,
            fetus_count,
            birthdate,
            gender,
            target_energy_kcal,
            preferences,
        }, mealType);

        closeModal();
    }

    getAvoidancesFromConditions = (conditions) => {
        const { nutritionPatterns } = this.state;
        conditions = conditions.map(({uuid}) => nutritionPatterns.find((pattern) => pattern.uuid === uuid)).filter(v => v);

        let avoidances = [];

        conditions.forEach(condition => avoidances = avoidances.concat(condition.avoidances || []));

        // De-duplicate
        avoidances = avoidances.filter((avoidance, i) => avoidances.indexOf(avoidance) === i);

        return avoidances;
    }

    openExpander = () => {
        this.setState({isExpanderOpening: true}, () => {
            setTimeout(() => {
                this.setState({isExpanderOpen: true});
            }, 333);
        })
    }

    render() {
        const { closeModal } = this.props;
        const {
            conditions,
            activity_level,
            height_cm,
            weight_kg,
            units_mode,
            due_date,
            fetus_count,
            birthdate,
            gender,
            target_energy_kcal,
            age,
            height_feet,
            height_inches,
            weight_lbs,
            mealType,
            error,
            alert,
            isExpanderOpening,
            nutritionPatterns,
            mealTypes,
            preferences
        } = this.state;

        if (!conditions.length || !nutritionPatterns.length) {
            return <></>;
        }

        const condition = conditions[0];
        const selected = nutritionPatterns.find((pattern) => condition.uuid == pattern.uuid);
        const copy = selected ? conditionsCopy[selected.name] : null;
        const conditionsOpts = nutritionPatterns.sort(conditionSortCompare).map((pattern) => ({
            label: pattern.special_name || pattern.consumer_name || pattern.name,
            value: pattern.uuid,
        }));

        const caloriesOpts = [];
        let calories = 1200;
        for (; calories <= 2800; calories += 100) {
            caloriesOpts.push({label: calories + ' calories', value: calories});
        }

        if (target_energy_kcal && caloriesOpts.filter(co => co.value == target_energy_kcal).length == 0) {
            let label = target_energy_kcal + ' calories ('+ age + ' yr old ' + gender + ')',
                value = target_energy_kcal;

            caloriesOpts.unshift({label, value});
        }

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Energy Estimator"
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay">
                <div className="el-modal-container el-modal2-container filter-estimator-container">
                    <header>
                        <h2>Calculate Per-Meal Prescription</h2>
                    </header>

                    <div className="el-modal-body-container el-modal2-body-container el-fonts">
                        <ConfigWarning profile={{
                            conditions: conditions,
                            preferences: {
                                diets: [],
                                avoidances: this.getAvoidancesFromConditions(conditions),
                                daily_totals: {},
                                meal_types: [],
                                ...preferences
                            },
                            birthdate,
                            target_energy_kcal,
                            height_cm,
                            weight_kg,
                        }} />

                        {alert ?
                            <Alert type="error">{alert}</Alert>
                        : null}

                        <div className="el-labeled-input conditions">
                            <label>Health Condition / Lifestyle</label>
                            <Select defaultClassName="el-select-container"
                                options={conditionsOpts}
                                value={condition.uuid}
                                onChange={this.onChangeCondition}
                                placeholder="Choose a condition..." />
                        </div>

                        {copy ? <p className="description">{copy.heading}</p> : null}

                        {condition.name == 'Pregnancy' ?
                            <div className="el-labeled-input due-date">
                                <label>Due Date</label>
                                <DateSelector initialDate={due_date} onChange={this.onChangeDueDate} delta_years={1}
                                    data-error={error === 'due_date'} />
                            </div>
                        : null}

                        {condition.name == 'Pregnancy' ?
                            <div className="el-labeled-input how-many-babies" data-error={error === 'fetus_count'}>
                                <label>How many fetus?</label>

                                <div className="toggle-container">
                                    <button className="el-flat-pill-toggle-btn male-btn" data-active={fetus_count == 1} onClick={() => this.onChangeFetusCount(1)}>
                                        Single
                                    </button>
                                    <button className="el-flat-pill-toggle-btn female-btn" data-active={fetus_count == 2} onClick={() => this.onChangeFetusCount(2)}>
                                        Twins
                                    </button>
                                </div>
                            </div>
                        : null}

                        {!selected.tags.includes('Pregnancy') || !selected.tags.includes('Lactation') ?
                            <div className="el-labeled-input gender">
                                <label>Birth Sex <em>(optional)</em></label>

                                <div className="toggle-container">
                                    <button className="el-flat-pill-toggle-btn el-btn-icon-left female-btn" data-active={gender == 'female'} onClick={() => this.onChangeGender('female')}>
                                        <i className="icon-female-sign" />Female
                                    </button>
                                    <button className="el-flat-pill-toggle-btn el-btn-icon-left male-btn" data-active={gender == 'male'} onClick={() => this.onChangeGender('male')}>
                                        <i className="icon-male-sign" />Male
                                    </button>
                                </div>
                            </div>
                        : null}

                        <div>
                            <DateSelector initialDate={birthdate} onChange={this.onChangeBirthdate}
                                labelClassName="el-labeled-input"
                                selectClassName="el-select-container"
                                data-error={error === 'birthdate'} />

                            <UnitsMode value={units_mode}
                                onClick={this.toggleUnitsMode} />

                            <div className="el-labeled-input height">
                                <label>Height</label>

                                {units_mode === 'english' ?
                                    <Select defaultClassName="el-select-container" className="height-feet"
                                        value={height_feet}
                                        placeholder="feet"
                                        options={heightFeetOpts}
                                        data-error={error === 'height'}
                                        onChange={this.onChangeHeightFeet}>
                                        <p>feet.</p>
                                    </Select>
                                : null}

                                {units_mode === 'english' ?
                                    <Select defaultClassName="el-select-container" className="height-inches"
                                        value={height_inches}
                                        placeholder="inches"
                                        options={heightInchOpts}
                                        data-error={error === 'height'}
                                        onChange={this.onChangeHeightInches}>
                                        <p>inches.</p>
                                    </Select>
                                : null}

                                {units_mode === 'metric' ?
                                    <div className="with-units height-cm">
                                        <input className="pseudo-input" type="number" placeholder="Height"
                                            value={height_cm}
                                            data-error={error === 'height'}
                                            onChange={this.onChangeHeightCm} />

                                        <label>cm.</label>
                                    </div>
                                : null}
                            </div>

                            <div className="el-labeled-input weight">
                                {condition == 'Pregnancy'
                                    ? <label>Pre-Pregnancy Weight</label>
                                    : <label>Current Weight</label>
                                }

                                {units_mode === 'english' ?
                                    <div className="with-units weight-lbs">
                                        <input className="pseudo-input" type="number"
                                            value={weight_lbs}
                                            data-error={error === 'weight'}
                                            onChange={this.onChangeWeightLbs} />
                                        <label>Lbs.</label>
                                    </div>
                                : null}

                                {units_mode === 'metric' ?
                                    <div className="with-units weight-kg">
                                        <input className="pseudo-input" type="number"
                                            value={weight_kg}
                                            data-error={error === 'weight'}
                                            onChange={this.onChangeWeightKg} />

                                        <label>Kg.</label>
                                    </div>
                                : null}
                            </div>

                            <div className="el-labeled-input activity-level">
                                <label>Activity Level</label>

                                <Select defaultClassName="el-select-container"
                                    options={mifflin.activity_levels}
                                    value={activity_level} showAbove={true}
                                    data-error={error == 'activity_level'}
                                    placeholder="Activity Level"
                                    onChange={this.onChangeActivityLevel} />
                            </div>
                        </div>

                        <div className="el-labeled-input target-energy-kcal">
                            <label>Calories per Day</label>
                            <Select defaultClassName="el-select-container"
                                options={caloriesOpts}
                                value={target_energy_kcal}
                                showAbove={true}
                                onChange={this.onChangeCalories}
                                placeholder="Select calories per day" />

                            <footer>
                                {!isExpanderOpening ?
                                    <button onClick={this.openExpander} className="calc-energy-btn">
                                        calculate calorie needs
                                    </button>
                                : null}
                            </footer>
                        </div>

                        <section className="el-labeled-input meal-types-container" data-error={error == 'meal-type'}>
                            <label>Calculate nutrient needs for which meal?</label>

                            <div className="toggle-container">
                                {mealTypes.map((mt) => (<button className="meal-type el-flat-pill-toggle-btn el-btn-icon-left"
                                        data-active={mealType.name === mt.name}
                                        onClick={() => this.setState({mealType: mt, dirty: true})}>
                                        <i className="icon-breakfast" />
                                        {mt.name}
                                        </button>)
                                )}
                            </div>
                        </section>
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={closeModal}>Cancel</button>
                        <button className="el-modal-ok-btn" onClick={this.apply}>Apply</button>
                    </footer>

                </div>
            </Modal>
        );
    }
}
