'use strict';

import { Component } from 'react';
import moment from 'moment';
import TelephoneInput from 'react-telephone-input';
import Birthdate from '../../Widgets/DateSelector.react';
import Select from '../../Widgets/Select.react';
import UnitsMode from '../../Widgets/UnitsMode.react';
import DateSelector from '../../../../components/DailyLog/DateSelector.react';
import UserStore from '../../../../stores/UserStore';
import { cmToFeetInches, hidePatientFor } from '../../../utils/Patients';
import PropTypes from 'prop-types';
import './AboutYourPatient.scss';
import './PatientForm.scss';
import './TelephoneInput.scss';

export default class AboutYourPatient extends Component {
    static propTypes = {
        patient: PropTypes.object,
        onChangePatient: PropTypes.func,
        showBasicInfo: PropTypes.bool,
        hidePatientOnExpirationChange: PropTypes.bool,
    };

    static defaultProps = {
        patient: {},
        showBasicInfo: true,
    };

    constructor(props) {
        super(props);

        const { patient } = this.props;

        const height = cmToFeetInches(patient.height_cm);
        const weight_lbs = Math.round(patient.weight_kg * 2.20462) || '';
        const goal_weight = patient.units_mode === 'english' ?
            (Math.round(patient.goal_weight_kg * 2.20462) || '') : (patient.goal_weight_kg || '');

        const user = UserStore.getUser();

        this.state = {
            user,
            first_name: patient.first_name || '',
            last_name: patient.last_name || '',
            email: patient.email || '',
            email2: patient.email || '',
            mobile_number: patient.mobile_number || '',
            patient_number: patient.patient_number || '',
            birthdate: patient.birthdate,
            gender: patient.gender,
            gender_identity: patient.gender_identity,
            height_feet: height.feet,
            height_inches: height.inches,
            height_cm: patient.height_cm || '',
            units_mode: patient.units_mode,
            practice_type: patient.practice_type || user.practice_type || 'dietetics',
            inhibit_jabber: patient.inhibit_jabber ? true : false,
            weight_kg: patient.weight_kg || '',
            weight_lbs,
            goal_weight,
            expiration_dropdown: patient.active_until && patient.expiration !== 'unlimited' ? 'date' : 'unlimited',
            active_until: patient.active_until || null,

            dirty: false,
            dirtyFields: [],
        };
    }

    /**
     * Converts a user entered string either in US or metric and returns
     * centimeters.
     */
    parseHeight = (height, unit) => {
        if (unit === 'metric') {
            // @todo - Do we need to extract 'cm' from the end?
            return parseInt(height);
        }

        const parts = String(height).split(' ');

        if (parts.length == 0 || parts.length > 2) {
            return false;
        }

        let inches = 0;

        parts.forEach((part) => {
            let found = part.match(/([0-9.]+)(['"]*)/);

            if (!found) {
                // no idea, skip it.
                return;
            }

            let value = found[1],
                u = found[2];

            if (u === "'") value *= 12;
            if (u === '"') value *= 1; // for completeness

            inches += value;
        });

        return inches * 2.54;
    };

    parseWeight = (weight, unit) => {
        weight = parseFloat(weight);

        if (!weight || isNaN(weight)) {
            return false;
        }

        // convert lbs to kg
        if (unit == 'us') {
            weight = weight * 0.453592;
        }

        return weight;
    };

    isDirty = () => {
        return this.state.dirty;
    };

    getDirtyFields = () => {
        return this.state.dirtyFields;
    };

    mutate = (patient, finished = false) => {
        const { hidePatientOnExpirationChange } = this.props;

        if (this.validate(finished) !== true) {
            return patient;
        }

        const {
            first_name,
            last_name,
            email,
            mobile_number,
            birthdate,
            gender,
            gender_identity,
            patient_number,
            units_mode,
            weight_kg,
            goal_weight,
            height_cm,
            practice_type,
            expiration_dropdown,
            active_until,
        } = this.state;

        patient.first_name = first_name;
        patient.last_name = last_name;
        patient.email = email.trim();
        patient.mobile_number = mobile_number;
        patient.gender = gender;
        patient.gender_identity = gender_identity;
        patient.birthdate = birthdate ? moment(birthdate).format('YYYY-MM-DD') : null;
        patient.height_cm = height_cm;
        patient.weight_kg = weight_kg;
        patient.goal_weight_kg = patient.units_mode === 'metric' ? parseFloat(goal_weight) : parseFloat(goal_weight) * 0.453592;
        patient.patient_number = patient_number;
        patient.units_mode = units_mode;
        patient.practice_type = practice_type;
        patient.completed = patient.completed || [];
        patient.expiration = expiration_dropdown === 'unlimited' ? 'unlimited' : 'manual';
        patient.active_until = active_until ? moment(active_until).format('YYYY-MM-DDTHH:mm:ssZ') : null;

        // If birthdate, height, weight and gender are filled out, we consider 'anthro' completed
        if (birthdate && height_cm && weight_kg && gender && !patient.completed.includes('anthro')) {
            patient.completed.push('anthro');
        }

        if (
            hidePatientOnExpirationChange &&
            patient.expiration === 'manual' &&
            moment(patient.active_until).diff(moment(), 'days') > 14
        ) {
            hidePatientFor(patient, 1);
        }

        return patient;
    };

    validate = (finished = false) => {
        const { first_name, last_name, email, email2, birthdate, gender, height_cm, weight_kg, practice_type } =
            this.state;

        if (!first_name) {
            this.setState({ error: 'first_name' });
            return 'First name is required';
        }

        if (!last_name) {
            this.setState({ error: 'last_name' });
            return 'Last name is required';
        }

        if (!email) {
            this.setState({ error: 'email' });
            return 'Email is required';
        }

        if (email.trim() != email2.trim()) {
            this.setState({ error: 'email2' });
            return "Emails don't match";
        }

        if (birthdate) {
            const parsedBirthdate = moment(birthdate, true);

            if (!parsedBirthdate.isValid()) {
                this.setState({ error: 'birthdate' });
                return 'Invalid birthdate format';
            }

            if (moment().diff(parsedBirthdate, 'years') == 0) {
                this.setState({ error: 'birthdate' });
                return 'Age must be 1 year or older';
            }
        }

        if ((!finished || practice_type === 'dietetics') && !birthdate) {
            this.setState({ error: 'birthdate' });
            return 'Birthdate is required';
        }

        if ((!finished || practice_type === 'dietetics') && !gender) {
            this.setState({ error: 'gender' });

            return 'Birth Sex is required';
        }

        if ((!finished || practice_type === 'dietetics') && !height_cm) {
            this.setState({ error: 'height' });
            return 'Height is required';
        }

        if ((!finished || practice_type === 'dietetics') && !weight_kg) {
            this.setState({ error: 'weight' });
            return 'Weight is required';
        }

        this.setState({ error: null });

        return true;
    };

    setFieldError = (field) => {
        this.setState({ error: field });
    };

    onChangeHeightFeet = (height_feet) => {
        const { height_inches } = this.state;

        const feet = parseInt(height_feet) || 0,
            inches = parseInt(height_inches) || 0;

        const height_cm = Math.round((feet * 12 + inches) * 2.54) || '';

        this.setState({ height_feet, height_cm, dirty: true });
    };

    onChangeHeightInches = (height_inches) => {
        const { height_feet } = this.state;

        const feet = parseInt(height_feet) || 0,
            inches = parseInt(height_inches) || 0;

        const height_cm = Math.round((feet * 12 + inches) * 2.54) || '';

        this.setState({ height_inches, height_cm, dirty: true });
    };

    onChangeHeightCm = (ev) => {
        const height_cm = ev.target.value;

        const english = cmToFeetInches(height_cm) || { feet: '', inches: '' };

        this.setState({ height_cm, height_feet: english.feet, height_inches: english.inches, dirty: true });
    };

    onChangeWeightLbs = (ev) => {
        const weight_lbs = ev.target.value;

        const weight_kg = Math.round(weight_lbs * 0.453592 * 10) / 10 || '';

        this.setState({ weight_lbs, weight_kg, dirty: true });
    };

    onChangeWeightKg = (ev) => {
        const weight_kg = ev.target.value;

        const weight_lbs = Math.round((weight_kg / 0.453592) * 10) / 10 || '';

        this.setState({ weight_kg, weight_lbs, dirty: true });
    };

    toggleUnitsMode = () => {
        let { units_mode } = this.state;

        units_mode = units_mode === 'metric' ? 'english' : 'metric';

        this.setState({ units_mode, dirty: true });
    };

    focused = null;

    autofocus = (el) => {
        if (el && !this.focused) {
            this.focused = el;
            this.focused.focus();
        }
    };

    onChangePracticeType = (practice_type) => {
        const { patient, onChangePatient } = this.props;

        patient.practice_type = practice_type;
        onChangePatient && onChangePatient(patient);

        this.setState({ practice_type, dirty: true });
    };

    onChangeExpiration = (expiration_dropdown) => {
        const { active_until } = this.state;
        let newActiveUntil = active_until;
        if (expiration_dropdown !== 'unlimited' && !active_until && expiration_dropdown !== 'months') {
            newActiveUntil = moment().add(14, 'days').endOf('day');
        } else if (
            expiration_dropdown !== 'unlimited' &&
            (!active_until || moment(active_until).diff(moment().endOf('day'), 'months') < 1)
        ) {
            newActiveUntil = moment().add(1, 'months').endOf('day');
        } else if (expiration_dropdown === 'unlimited' && active_until) {
            newActiveUntil = null;
        }
        this.setState({ expiration_dropdown, active_until: newActiveUntil, dirty: true });
    };

    onChangeDate = (date) => {
        this.setState({ active_until: moment(date).endOf('day'), dirty: true });
    };

    onChangeDays = (days) => {
        this.setState({ active_until: moment().add(days, 'days').endOf('day'), dirty: true });
    };

    onChangeMonths = (months) => {
        this.setState({ active_until: moment().add(months, 'months').endOf('day'), dirty: true });
    };

    onChangeGenderIdentity = (gender_identity) => {
        this.setState({ gender_identity: gender_identity, dirty: true });
    };

    render() {
        const { patient, showBasicInfo } = this.props;
        const {
            user,
            first_name,
            last_name,
            email,
            email2,
            mobile_number,
            birthdate,
            gender,
            gender_identity,
            patient_number,
            error,
            practice_type,
            expiration_dropdown,
            active_until,
            units_mode,
            height_feet,
            height_inches,
            height_cm,
            weight_lbs,
            weight_kg,
            goal_weight
        } = this.state;

        const heightFeetOpts = [
            { value: 1, label: '1 ft.' },
            { value: 2, label: '2 ft.' },
            { value: 3, label: '3 ft.' },
            { value: 4, label: '4 ft.' },
            { value: 5, label: '5 ft.' },
            { value: 6, label: '6 ft.' },
            { value: 7, label: '7 ft.' },
            { value: 8, label: '8 ft.' },
        ];

        const heightInchOpts = [
            { value: 0, label: '0 in.' },
            { value: 1, label: '1 in.' },
            { value: 2, label: '2 in.' },
            { value: 3, label: '3 in.' },
            { value: 4, label: '4 in.' },
            { value: 5, label: '5 in.' },
            { value: 6, label: '6 in.' },
            { value: 7, label: '7 in.' },
            { value: 8, label: '8 in.' },
            { value: 9, label: '9 in.' },
            { value: 10, label: '10 in.' },
            { value: 11, label: '11 in.' },
        ];

        const practiceTypeOpts = [
            { label: 'Dietetics', value: 'dietetics' },
            { label: 'Personal Trainer', value: 'fitness' },
        ];

        const inhibitJabberOpts = [
            { label: 'Yes', value: 'false' },
            { label: 'No (disabled)', value: 'true' },
        ];

        const expirationOpts = [
            { label: 'Unlimited', value: 'unlimited' },
            { label: 'Set expiration date', value: 'date' },
            { label: 'Set number of days', value: 'days' },
            { label: 'Set number of months', value: 'months' },
        ];

        const genderIdentityOpts = [
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
            { label: 'Transgender man/transman/female-to-male(FTM)', value: 'ftm' },
            { label: 'Transgender woman/transwoman/male-to-female(MTF)', value: 'mtf' },
            { label: 'Genderqueer/gender nonconforming neither excessively male nor female', value: 'genderqueer' },
            { label: 'Non-binary', value: 'non-binary' },
            { label: 'Other', value: 'other' },
            { label: 'Decline to Answer', value: 'decline' },
        ];

        const daysAfterOpts = [...Array(365).keys()].map((element, index) => {
            return { label: index + 1, value: index + 1 };
        });
        const monthsAfterOpts = [...Array(12).keys()].map((element, index) => {
            return { label: index + 1, value: index + 1 };
        });

        let tabIndex = 1;

        let hasAcceptedInvite = patient.invite_accepted ? true : false;
        const canChangePracticeType = user.practice && user.practice.can_change_practice_type;

        return (
            <div className="about-your-patient patient-form">
                {showBasicInfo ? (
                    <div className="with-label first-name">
                        <label htmlFor="first-name">First Name</label>
                        <input
                            id="first-name"
                            type="text"
                            value={first_name}
                            tabIndex={tabIndex++}
                            data-error={error === 'first_name'}
                            ref={this.autofocus}
                            disabled={hasAcceptedInvite}
                            onChange={(ev) => this.setState({ first_name: ev.target.value, dirty: true })}
                        />
                    </div>
                ) : null}
                {showBasicInfo ? (
                    <div className="with-label last-name">
                        <label htmlFor="last-name">Last Name</label>
                        <input
                            id="last-name"
                            type="text"
                            value={last_name}
                            tabIndex={tabIndex++}
                            data-error={error === 'last_name'}
                            disabled={hasAcceptedInvite}
                            onChange={(ev) => this.setState({ last_name: ev.target.value, dirty: true })}
                        />
                    </div>
                ) : null}
                {showBasicInfo ? (
                    <div className="with-label email1">
                        <label htmlFor="email">Email Address</label>
                        <input
                            id="email"
                            type="text"
                            value={email}
                            tabIndex={tabIndex++}
                            data-error={error === 'email'}
                            disabled={hasAcceptedInvite}
                            onChange={(ev) => this.setState({ email: ev.target.value, dirty: true })}
                        />
                    </div>
                ) : null}
                {showBasicInfo ? (
                    <div className="with-label email2">
                        <label htmlFor="confirm-email">Confirm Email Address</label>
                        <input
                            id="confirm-email"
                            type="text"
                            value={email2}
                            tabIndex={tabIndex++}
                            data-error={error === 'email2'}
                            disabled={hasAcceptedInvite}
                            onChange={(ev) => this.setState({ email2: ev.target.value, dirty: true })}
                        />
                    </div>
                ) : null}

                <div className="with-label patient-number">
                    <label>
                        {practice_type === 'dietetics' ? 'Patient Number' : 'Client Number'} <em>(optional)</em>
                    </label>
                    <input
                        type="text"
                        value={patient_number}
                        tabIndex={tabIndex++}
                        id="patient_number"
                        onChange={(ev) => this.setState({ patient_number: ev.target.value, dirty: true })}
                    />
                </div>

                {showBasicInfo ? (
                    <div className="with-label phone-number" data-error={error === 'mobile_number'}>
                        <label>Mobile Number</label>
                        <TelephoneInput
                            value={mobile_number}
                            preferredCountries={['us', 'ca']}
                            defaultCountry="us"
                            tabIndex={tabIndex++}
                            flagsImagePath="https://static.chewba.info/images/country-flags.png"
                            onChange={(num) => this.setState({ mobile_number: num, dirty: true })}
                        />
                    </div>
                ) : null}

                <Birthdate
                    initialDate={birthdate}
                    startTabIndex={tabIndex++}
                    data-error={error === 'birthdate'}
                    onChange={(birthdate) => this.setState({ birthdate, dirty: true })}
                />

                <div className="with-label genders-container" data-error={error === 'gender'}>
                    <label>Birth Sex</label>
                    <button
                        data-testid="female"
                        className="gender toggle-button"
                        data-active={gender === 'female'}
                        tabIndex={(tabIndex += 2)}
                        onClick={() =>
                            this.setState({ gender: 'female', error: error === 'gender' ? null : error, dirty: true })
                        }
                    >
                        <i className="icon-female2" />
                        Female
                    </button>

                    <button
                        data-testid="male"
                        className="gender toggle-button"
                        data-active={gender === 'male'}
                        tabIndex={tabIndex++}
                        onClick={() =>
                            this.setState({ gender: 'male', error: error === 'gender' ? null : error, dirty: true })
                        }
                    >
                        <i className="icon-male2" />
                        Male
                    </button>
                </div>

                <div data-testid="gender-identity" className="with-label current-gender-identity">
                    <label>Current Gender Identity</label>
                    <Select
                        value={gender_identity || gender || null}
                        onChange={this.onChangeGenderIdentity}
                        options={genderIdentityOpts}
                        showAbove={true}
                    />
                </div>

                <div className="height-container" data-units={units_mode}>
                    <UnitsMode tabIndex={tabIndex++} value={units_mode} onClick={this.toggleUnitsMode} />

                    <div data-testid="height" className="with-label height">
                        <label>Height</label>
                        {units_mode === 'english' ? (
                            <Select
                                className="height-feet"
                                value={height_feet}
                                placeholder="feet"
                                options={heightFeetOpts}
                                tabIndex={tabIndex++}
                                showAbove={true}
                                onChange={this.onChangeHeightFeet}
                                data-error={error === 'height'}
                            >
                                <p>feet.</p>
                            </Select>
                        ) : null}

                        {units_mode === 'english' ? (
                            <Select
                                className="height-inches"
                                value={height_inches}
                                placeholder="inches"
                                options={heightInchOpts}
                                tabIndex={tabIndex++}
                                showAbove={true}
                                onChange={this.onChangeHeightInches}
                                data-error={error === 'height'}
                            >
                                <p>inches.</p>
                            </Select>
                        ) : null}

                        {units_mode === 'metric' ? (
                            <div className="with-units height-cm">
                                <input
                                    type="text"
                                    value={height_cm}
                                    tabIndex={tabIndex++}
                                    data-error={error == 'height'}
                                    onChange={this.onChangeHeightCm}
                                />

                                <label>cm.</label>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="weight-container" data-units={units_mode}>
                    <div className="with-label weight">
                        <label htmlFor="weight">Weight</label>
                        {units_mode === 'english' ? (
                            <div className="with-units weight-lbs">
                                <input
                                    id="weight"
                                    type="text"
                                    value={weight_lbs}
                                    tabIndex={tabIndex++}
                                    data-error={error == 'weight'}
                                    onChange={this.onChangeWeightLbs}
                                />
                                <label>Lbs.</label>
                            </div>
                        ) : null}

                        {units_mode === 'metric' ? (
                            <div className="with-units weight-kg">
                                <input
                                    type="text"
                                    value={weight_kg}
                                    tabIndex={tabIndex++}
                                    data-error={error == 'weight'}
                                    onChange={this.onChangeWeightKg}
                                />

                                <label>Kg.</label>
                            </div>
                        ) : null}
                    </div>

                    {!(patient.pregnant || patient.lactating) ? (
                        <div className="with-label goal-weight">
                            <label>Goal Weight <em>(optional)</em></label>

                            <div className="with-units">
                                <input type="text"
                                    value={goal_weight} tabIndex={tabIndex++}
                                    onChange={ev => this.setState({goal_weight: ev.target.value, dirty: true})} />
                                {units_mode === 'english' ? <label>lbs</label> : null}
                                {units_mode === 'metric' ? <label>kg</label> : null}
                            </div>
                        </div>
                    ) : null}
                </div>

                {user.dietitian &&
                    user.dietitian.practice_role === 'admin' &&
                    canChangePracticeType &&
                    showBasicInfo ? (
                        <div className="with-label practice-type">
                            <label>Practice Type</label>
                            <Select
                                value={practice_type}
                                onChange={this.onChangePracticeType}
                                options={practiceTypeOpts}
                            />
                        </div>
                    ) 
                : null}

                <div className="with-label expiration">
                    <label>Eatlove Subscription</label>
                    <Select value={expiration_dropdown}
                        onChange={this.onChangeExpiration}
                        options={expirationOpts}
                        showAbove={true} />
                </div>

                {expiration_dropdown === 'date' ?
                <div className="with-label ">
                    <label>Expiration Date</label>
                    <DateSelector className={"date-selector-popover"} showAbove={true} date={active_until ?
                        moment(active_until) : moment().add(14, 'days')} min={moment().subtract(1, 'days')}
                        onChange={this.onChangeDate} />
                </div>
                : null}

                {expiration_dropdown === 'days' ?
                <div className="with-label days-after">
                    <label>Days</label>
                    <Select value={active_until ? moment(active_until).diff(moment().endOf('day'), 'days') : 14}
                        onChange={this.onChangeDays}
                        options={daysAfterOpts}
                        showAbove={true} />
                </div>
                : null}

                {expiration_dropdown === 'months' ? (
                    <div className="with-label days-after">
                        <label>Months</label>
                        <Select
                            value={active_until ? moment(active_until).diff(moment().endOf('day'), 'months') : 1}
                            onChange={this.onChangeMonths}
                            options={monthsAfterOpts}
                            showAbove={true}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}
