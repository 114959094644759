'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';

import MealDraggable from '../../Dashboard/Grid/MealDraggable.react';

import Select from '../../../pro/components/Widgets/Select.react';
import { getPrimaryMeal } from '../../../utils/Meals';

import { getAssetsForMeals } from '../../../utils/Meals';
import Analytics from '../../../utils/Analytics';

import './ConfirmOverwriteModal.scss';

export default class ConfirmOverwriteModal extends Component {

    static contextTypes = {
        isMobile: PropTypes.bool,
    }

    static propTypes = {
        overlaps: PropTypes.array,
        clears: PropTypes.array,

        closeModal: PropTypes.func.isRequired,

        onModifyMeals: PropTypes.func.isRequired,
        logPastMeals: PropTypes.func
    };

    static defaultProps = {
        ctaTitle: 'Save Meal',
    };


    static childContextTypes = {
        recipes: PropTypes.object,
        foods: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const overlaps = props.overlaps.map(overlap => ({...overlap, action: 'replace'}));

        this.state = {
            overlaps,

            recipes: {},
            foods: {}
        };
    }

    getChildContext = () => {
        const { recipes, foods } = this.state;

        return {
            recipes,
            foods,
        };
    }

    componentDidMount = async () => {
        const { overlaps, clears } = this.props;

        const overlapMeals = overlaps.reduce((carry, overlap) => {
            return carry.concat(overlap.destMeals).concat(overlap.sourceMeals);
        }, []);

        const assets = await getAssetsForMeals(overlapMeals);

        this.setState({...assets});
    }

    getPrimaryMeal = (meals) => {
        const { recipes, foods } = this.state;

        return getPrimaryMeal(meals, recipes, foods);
    }

    setOverlapAction = (i, action) => {
        const { overlaps } = this.state;

        Analytics.confirmOverwriteModalChangeDropdown({
            "New value": action,
            "Old value": overlaps[i].action,
            "Date": overlaps[i].date.format("YYYY-MM-DD"),
            "Meal Type": overlaps[i].mealType,
            "Source Meal UUIDs": overlaps[i].sourceMeals.map(meal => meal.uuid),
            "Destination Meal UUIDs": overlaps[i].destMeals.map(meal => meal.uuid),
        });

        overlaps[i].action = action;

        this.setState({overlaps});
    }

    onSaveReschedule = () => {
        const { overlaps } = this.state;
        const { meals, clears, date, mealType, onModifyMeals, closeModal } = this.props;

        let dirtyMeals = [], mealsToDelete = [], leftoverDates = clears.map(c => moment(c.date));

        overlaps.forEach((overlap) => {
            if (overlap.action === 'swap') {
                // Loop through all destMeals and set their mealType/date to the value from sourceMeals[0],
                // add each meal to the dirtyMeals array
                overlap.destMeals.forEach(meal => {
                    meal.meal = overlap.sourceMeals[0].meal;
                    meal.date = overlap.sourceMeals[0].date;

                    if (dirtyMeals.indexOf(meal) === -1) {
                        dirtyMeals.push(meal);
                    }
                });
            } else if (overlap.action === 'replace') {
                // Loop through each destMeal and add it to the mealsToDelete array
                overlap.destMeals.forEach(meal => {
                    meal.meal = overlap.sourceMeals[0].meal;
                    meal.date = overlap.sourceMeals[0].date;

                    if (mealsToDelete.indexOf(meal) === -1) {
                        mealsToDelete.push(meal);
                    }
                });
            } else if (overlap.action === 'combine') {
                // Don't do anything with destMeals, we're going to leave them alone.
            }

            // Loop through sourceMeals and if not a leftover, set the mealType/date to the overlap mealType/date
            // and then add to dirtyMeals array
            overlap.sourceMeals.forEach(meal => {
                if (meal.meal_type === 'leftover') {
                    if (!leftoverDates.find(d => d.isSame(meal.date, 'day'))) {
                        leftoverDates.push(moment(meal.date));
                    }
                    return;
                }

                meal.meal = overlap.mealType;
                meal.date = overlap.date.format('YYYY-MM-DD');

                if (dirtyMeals.indexOf(meal) === -1) {
                    dirtyMeals.push(meal);
                }
            });

            // Add to leftoverDates
            leftoverDates.push(overlap.date);
        });

        meals.forEach(meal => {
            meal.meal = mealType;
            meal.date = date.format('YYYY-MM-DD');

            if (dirtyMeals.indexOf(meal) === -1) {
                dirtyMeals.push(meal);
            }
        });

        onModifyMeals(dirtyMeals, mealsToDelete, leftoverDates);


        Analytics.confirmOverwriteModalConfirm({
            "Actions": overlaps.map(overlap => overlap.action),
            "Dates": overlaps.map(overlap => overlap.date.format("YYYY-MM-DD")),
            "Meal Types": overlaps.map(overlap => overlap.mealType),
            "Source Meal UUIDs": overlaps.map(overlap =>
                overlap.sourceMeals.map(meal => meal.uuid)
            ),
            "Destination Meal UUIDs": overlaps.map(overlap =>
                overlap.destMeals.map(meal => meal.uuid)
            ),
        });


        closeModal(true);
    }

    onSaveRepeat = () => {
        const { overlaps } = this.state;
        const { clears } = this.props;
        const { onModifyMeals, closeModal, logPastMeals } = this.props;

        let dirtyMeals = [], mealsToDelete = [];

        overlaps.forEach(({action, destMeals, sourceMeals, mealType, date}) => {
            if (action === 'replace') {
                // Loop through each destMeal and add it to the mealsToDelete array
                destMeals.forEach(meal => {
                    if (mealsToDelete.indexOf(meal) === -1) {
                        mealsToDelete.push(meal);
                    }
                });
            } else if (action === 'combine') {
                // Don't do anything with destMeals, we're going to leave them alone.
            }

            sourceMeals.forEach(meal => {
                meal.meal = mealType;
                meal.date = date.format('YYYY-MM-DD');

                if (dirtyMeals.indexOf(meal) === -1) {
                    dirtyMeals.push(meal);
                }
            });
        });

        clears.forEach(({sourceMeals, mealType, date}) => {
            sourceMeals.forEach(meal => {
                meal.meal = mealType;
                meal.date = date.format('YYYY-MM-DD');

                if (dirtyMeals.indexOf(meal) === -1) {
                    dirtyMeals.push(meal);
                }
            });
        });

        onModifyMeals(dirtyMeals, mealsToDelete);

        if (logPastMeals) {
            dirtyMeals = logPastMeals(dirtyMeals);
        }

        Analytics.confirmOverwriteModalConfirm({
            "Actions": overlaps.map(overlap => overlap.action),
            "Dates": overlaps.map(overlap => overlap.date.format("YYYY-MM-DD")),
            "Meal Types": overlaps.map(overlap => overlap.mealType),
            "Source Meal UUIDs": overlaps.map(overlap =>
                overlap.sourceMeals.map(meal => meal.uuid)
            ),
            "Destination Meal UUIDs": overlaps.map(overlap =>
                overlap.destMeals.map(meal => meal.uuid)
            ),
        });

        closeModal(true);
    }

    sortOverlaps = (a, b) => {
        const properOrder = ['Breakfast', 'Lunch', 'Snack', 'Dinner'];
        if (a.date.valueOf() !== b.date.valueOf()) {
            return a.date.valueOf() - b.date.valueOf();
        }

        // #2 Sort by meal type
        let ai = properOrder.indexOf(a.mealType),
            bi = properOrder.indexOf(b.mealType);

        return ai - bi;
    }

    renderMealsContainer = (overlap, i) => {
        const { isMobile } = this.context;
        const { isRepeatMeal, isRecipeSearch } = this.props;

        const destMealInfo = this.getPrimaryMeal(overlap.destMeals);
        const sourceMealInfo = this.getPrimaryMeal(overlap.sourceMeals);

        const imageDimensions = isMobile ? {"width": 75, "height": 75} : {"width": 150, "height": 150};

        const actionOpts = [
            (!isRepeatMeal && !isRecipeSearch) ? {value: 'swap', label: 'SWAP with'} : null,
            {value: 'replace', label: 'REPLACE with'},
            {value: 'combine', label: 'COMBINE with'},
        ].filter(v => v);

        if (isMobile) {
            return (
                <div className="meals-container">
                    <h4>{overlap.mealType}, {overlap.date.format('dddd, MMM Do')}</h4>
                    <div className="meals-row">
                        <MealDraggable mode="future" meals={overlap.destMeals} imageDimensions={imageDimensions}
                            disableDnD={true} disableGrocery={true} disableControls={true} />
                        <div className="meals-titles-wrapper">
                            <p className="meals-titles">
                                {destMealInfo.primary?.meal_type === 'leftover' ? <em>LEFTOVER: </em>: null}
                                {destMealInfo.titles.join(' + ')}
                            </p>
                        </div>
                    </div>

                    <Select options={actionOpts} value={overlap.action}
                        onChange={action => this.setOverlapAction(i, action)} />

                    <div className="meals-row">
                        <MealDraggable mode="future" meals={overlap.sourceMeals} imageDimensions={imageDimensions}
                            disableDnD={true} disableGrocery={true} disableControls={true} />
                        <div className="meals-titles-wrapper">
                            <p className="meals-titles">
                                {sourceMealInfo.primary?.meal_type === 'leftover' ? <em>LEFTOVER: </em>: null}
                                {sourceMealInfo.titles.join(' + ')}
                            </p>
                        </div>
                    </div>
                </div>

            )
        }

        else return (
            <div className="meals-container">
                <h4>{overlap.mealType}, {overlap.date.format('dddd, MMM Do')}</h4>
                <MealDraggable mode="future" meals={overlap.destMeals} imageDimensions={imageDimensions}
                    disableDnD={true} disableGrocery={true} disableControls={true} />

                <Select options={actionOpts} value={overlap.action}
                onChange={action => this.setOverlapAction(i, action)} />

                <MealDraggable mode="future" meals={overlap.sourceMeals} imageDimensions={imageDimensions}
                    disableDnD={true} disableGrocery={true} disableControls={true} />
            </div>
        )

    }

    closeModal = (event, clickedCancel = false) => {
        const { closeModal } = this.props;

        if (clickedCancel) {
            Analytics.confirmOverwriteModalCancel();
            closeModal();
            return;
        }
        Analytics.confirmOverwriteModalClose();
        closeModal();

    }

    render() {
        const { isRepeatMeal, closeModal } = this.props;
        const { overlaps } = this.state;

        return (
            <Modal isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Overwrite with leftovers"
                className="log-portions-modal el-modal el-modal2"
                overlayClassName="feed-modal-overlay log-portions-overlay"
                closeTimeoutMS={250}>
                <div className="leftover-offsets-container confirm-overwrite-container el-modal-container el-modal2-container">
                    <header>
                        <button className="el-modal-close-x" onClick={this.closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>

                        <h2>What should we do with your previously scheduled {overlaps.length > 1 ? 'meals' : 'meal'}?</h2>
                    </header>

                    <section className="confirm-overwrite-form preferences-form el-modal-body-container el-modal2-body-container el-form el-fonts">
                        <ul className="overlap-list">
                            {overlaps.sort(this.sortOverlaps).map((overlap, i) => (
                                <li key={i}>
                                    {this.renderMealsContainer(overlap, i)}
                                </li>
                            ))}
                        </ul>
                    </section>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={(event) => this.closeModal(event, true)}>cancel</button>
                        <button className="el-modal-ok-btn" onClick={isRepeatMeal ? this.onSaveRepeat : this.onSaveReschedule}>Save</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
