'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import RestaurantMenu from './Restaurants/Menu.react';
import RestaurantLocations from './Restaurants/Locations.react';

import './Restaurants.scss';

export default class Restaurants extends Component {
    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedLocation: null,
        };
    }

    onSelectLocation = (selectedLocation) => {
        const { confirm } = this.context;

        this.setState({ selectedLocation }, () => {
            setTimeout(() => {
                confirm(
                    <div className="nutrition-disclaimer">
                        <h2>Nutrition Disclaimer</h2>
                        <p>
                            Nutrition info on this menu is estimated. Please check ingredients before purchasing to
                            ensure any of your avoidances are not included. Menu items may change without notice and not
                            be available in all locations.
                        </p>
                        <p>
                            PLEASE NOTE: Some nutrition and allergen data are not available for restaurant foods. Your
                            nutrition profile may include parameters that are not supported in this feature due to
                            limited data availability. Please exercise care when consuming restaurant food.
                        </p>
                    </div>,
                    (accept) => false,
                    null,
                    { acceptText: 'Got it!', rejectText: null }
                );
            }, 1000);
        });
    };

    returnToMap = () => {
        this.setState({ selectedLocation: null });
    };

    render() {
        const { loading, locations, groupedLocations, locationParams, selectedLocation, showAutocomplete } = this.state;

        return (
            <div className="brands-db-location-search">
                {selectedLocation ? (
                    <RestaurantMenu selectedLocation={selectedLocation} returnToMap={this.returnToMap} />
                ) : (
                    <RestaurantLocations onSelectLocation={this.onSelectLocation} />
                )}
            </div>
        );
    }
}
