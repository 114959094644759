'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import MyTabs from '../../Widgets/Tabs.react';
import NutritionInfo from '../../Products/NutritionInfo.react';

import UserStore from '../../../stores/UserStore';

import { roundForHumansByUnit } from '../../../utils/Math';

import allNutrients from '../../../tables/nutrients';
import Analytics from '../../../utils/Analytics';

import './NutritionAnalysisModal.scss';

const nutrKeys = {
    208: {
        Units: '',
        NutrDesc: 'Calories',
    },
    VEG: {
        Units: ' servings',
        NutrDesc: 'Vegetables',
    },
    FRU: {
        Units: ' servings',
        NutrDesc: 'Fruit',
    },
};

export default class NutritionAnalysisModal extends Component {
    static propTypes = {
        profile: PropTypes.object,
        mismatches: PropTypes.object,
        recipe: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            mismatches: this.massageMismatches(props.mismatches),
            isMismatched: false,
            isOverLimits: false,
        };
    }

    componentDidMount = () => {
        this.syncState(this.props, this.context);
    };

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.syncState(nextProps, nextContext);
    };

    massageMismatches = (mismatches) => {
        mismatches = JSON.parse(JSON.stringify(mismatches)); // deeeep deep down.

        Object.keys(mismatches).forEach((mealType) => {
            const variances = mismatches[mealType].filter((variance) => {
                const nutrient = nutrKeys[variance.nutrNo] || allNutrients[variance.nutrNo];

                if (!nutrient) {
                    return false;
                }

                const { value, min, max } = variance;

                if (min && value < min) {
                    let roundedUnder = roundForHumansByUnit(min - value, nutrient.Units);

                    if (!roundedUnder) {
                        return false;
                    }
                }

                if (max && value > max) {
                    let roundedOver = roundForHumansByUnit(value - max, nutrient.Units);

                    if (!roundedOver) {
                        return false;
                    }
                }

                return true;
            });

            if (variances.length === 0) {
                delete mismatches[mealType];
                return;
            }

            mismatches[mealType] = variances;
        });

        return mismatches;
    };

    syncState = (props, context) => {
        let { recipe, profile, mismatches } = props || this.props;
        const { preferences = { avoidances: [] } } = profile;
        const avoidances = recipe.ingredient_tags.filter((tag) => preferences.avoidances?.includes(tag));

        mismatches = this.massageMismatches(mismatches);
        let isMismatched = Object.keys(mismatches).length > 0 || avoidances.length > 0;
        let isOverLimits = Object.keys(mismatches).filter((mealType) => {
            return mismatches[mealType].find((miss) => miss.value > miss.max);
        });

        this.setState({ isMismatched, isOverLimits, mismatches, avoidances });
    };

    renderVariance = (variance, i) => {
        const nutrient = nutrKeys[variance.nutrNo] || allNutrients[variance.nutrNo];

        if (!nutrient) {
            return;
        }

        const { value, min, max } = variance;

        if (value < min) {
            let roundedUnder = roundForHumansByUnit(min - value, nutrient.Units);

            return (
                <li key={i}>
                    <span>{nutrient.NutrDesc}</span>
                    <em>
                        {roundedUnder}
                        {nutrient.Units}
                    </em>
                    <span className="under">Under</span>
                </li>
            );
        }

        if (value > max) {
            let roundedOver = roundForHumansByUnit(value - max, nutrient.Units);

            return (
                <li key={i}>
                    <span>{nutrient.NutrDesc}</span>
                    <em>
                        {roundedOver}
                        {nutrient.Units}
                    </em>
                    <span className="over">Over</span>
                </li>
            );
        }

        return null;
    };

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    renderNutritionAnalysisModal = () => {
        const { mismatches, isMismatched, avoidances, isModalOpen } = this.state;
        const { recipe, profile } = this.props;

        if (!isModalOpen) {
            return;
        }

        let mismatchesTabTitle;

        let one = 2;

        if (isMismatched) {
            mismatchesTabTitle = 'Client Needs';
            const user = UserStore.getUser();

            // Is this my profile?
            if (user && profile.uuid == user.uuid) {
                mismatchesTabTitle = 'Your Needs';
            } else if (profile.practice_type === 'dietetics') {
                // Or maybe it's a patient
                mismatchesTabTitle = 'Patient Needs';
            }
        }

        return (
            <Modal
                isOpen={true}
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Recipe Nutritional Analysis"
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal2-container">
                    <div className="nutrition-comparison-body">
                        <MyTabs defaultTab="Nutrition Facts">
                            <section data-title="Nutrition Facts">
                                <NutritionInfo nutrients={recipe.nutrients.values} profile={profile} />
                            </section>

                            {isMismatched ? (
                                <section data-title={mismatchesTabTitle}>
                                    <div className="mismatches">
                                        <h3>For {profile.name} this meal is...</h3>

                                        {Object.keys(mismatches).map((mealType) => (
                                            <section className="meal-variance" key={mealType}>
                                                <header>As a {mealType}</header>
                                                <ul>
                                                    {mismatches[mealType].map(this.renderVariance).filter((v) => v)}
                                                </ul>
                                            </section>
                                        ))}

                                        {avoidances && avoidances.length > 0 ? (
                                            <p>Contains foods avoided by your profile: {avoidances.join(', ')}</p>
                                        ) : null}
                                    </div>
                                </section>
                            ) : null}
                        </MyTabs>
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.closeModal}>
                            done
                        </button>
                    </footer>
                </div>
            </Modal>
        );
    };

    render = () => {
        const { recipe, profile } = this.props;
        const { rd_override = false, hide_nutrition = false } = profile || {};

        if (!rd_override && hide_nutrition) {
            return <span />;
        }

        if (!recipe.nutrients) {
            return <span />;
        }

        return (
            <>
                <button
                    className="el-medium-btn el-link-no-underline-raspberry-btn nutrition-analysis-btn"
                    onClick={this.openModal}
                >
                    Nutritional Analysis
                </button>
                {this.renderNutritionAnalysisModal()}
            </>
        );
    };
}
