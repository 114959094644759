'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import store from 'store';
import indexBy from 'lodash.indexby';

import FavoriteButton from '../../Widgets/FavoriteButton.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';
import PlanMealCounts from '../../Widgets/PlanMealCounts.react';
import ImgResized from '../../Widgets/ImgResized.react';

import './PlanCard.scss';
import '../../Search/Results/RecipeCard.scss'
import '../../Search/Results/PlanCard.scss'

import { fetchDocumentsById, updateCachedDocuments, createNewDocument } from '../../../utils/Content';
import { fetchVirtualPlan, deleteVirtualPlan } from '../../../utils/Plans';
import BoardStore from '../../../stores/BoardStore';
import BoardActions from '../../../actions/BoardActions';
import AuthStore from '../../../stores/AuthStore';

import { getConfig } from '../../../utils/Env';
import mealTypes from '../../../tables/meal-types';

export default class PlanCard extends Component {
    static propTypes = {
        plan: PropTypes.object,
        mealTypes: PropTypes.array,
        virtual: PropTypes.bool,
        showFavorites: PropTypes.bool,
        hideTitle: PropTypes.bool,
        showAdvancedMode: PropTypes.bool,
        shortMode: PropTypes.bool,
        shouldLoadPlan: PropTypes.bool,
        planToRender: PropTypes.bool,
    };

    static defaultProps = {
        virtual: false,
        showFavorites: false,
        hideTitle: false,
        showAdvancedMode: false,
        shortMode: false,
        shouldLoadPlan: false,
        planToRender: false,
    };

    static contextTypes = {
        isPublisher: PropTypes.bool,
        showCustomizer: PropTypes.func,
        confirm: PropTypes.func,
        replaceMealPlan: PropTypes.func,
        onBeforeUnfavorite: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            recommendations: [],
            plan: props.plan,
            loaded: false,
        };
    }

    componentDidMount = () => {
        const {shouldLoadPlan} = this.props;
        // Get a list of boards shared with the users dietitians (there may be more than one).
        const boards = BoardStore.getBoards().filter(board => board.dietitian_recommendation);
        let recommendations = [];

        boards.forEach(board => {
            board.contents.forEach(content => {
                recommendations.push({
                    ...content,
                });
            });
        });

        recommendations = recommendations.sort((a, b) => moment(b.created).diff(a.created));

        // Load the plans
        fetchDocumentsById(recommendations.map(r => r.resource_id)).then(documents => {
            documents = indexBy(documents, 'uuid');

            recommendations.forEach(rec => {
                rec.resource = documents[rec.resource_id];
            });

            // Filter out the ones where we couldn't find the resource.
            recommendations = recommendations.filter(r => r.resource);

            this.setState({recommendations});
        });
        shouldLoadPlan && this.loadPlan();
    }

    //Reset state explicitly on prop change
    componentDidUpdate(prevProps) {
        const { planToRender } = this.props;
        //on component did update if current plan has replaced_by in virtual menus reload plan;
        if (prevProps.plan !== this.props.plan) {
            this.setState({plan: this.props.plan}); 
        }

        if (planToRender) {
            this.checkToUpdateVirtualPlan();
        }
    }

    checkToUpdateVirtualPlan = () => {
        const virtualPlans = store.get('virtual-menus') || {};
        const uuid = this.state.plan?.uuid

        if (virtualPlans[uuid]?.replaced_by) {
            this.loadPlan(virtualPlans[uuid]?.replaced_by)
        }           
    }


    loadPlan = (replaceUuid) => {
        let { plan } = this.props;

        let uuid = replaceUuid || plan.uuid;

        if (plan && plan.replaced_by) {
            uuid = plan.replaced_by;
        }

        fetchDocumentsById([uuid]).then(documents => {
            if (!documents[0]) {
                this.setState({loaded: true});
                return;
            }

            // Make a copy so we don't pollute the cached documents pool (just yet anyway).
            plan = JSON.parse(JSON.stringify(documents[0]));
            this.setState({
                plan,
                loaded: true
            });
        });
    }

    renderPatientOrSize = (merchant) => {
        const { plan } = this.state;
        const { mealTypes = [] } = this.props;

        const mealTypeCounts = plan.items.reduce((count, item) => {
            count[item.meal] = (count[item.meal] || 0) + 1;
            return count;
        }, {});

        const counts = Object.values(mealTypeCounts);

        const days = Math.max(...counts);

        const dinnerOnlyMealTypes = mealTypes.filter((mealType) => mealType.main_dish === "Dinner").map((mealType) => mealType.name);

        const dinnerOnlyMealPlan = dinnerOnlyMealTypes.every(key => mealTypeCounts.hasOwnProperty(key) && mealTypeCounts[key] > 0) &&
           Object.keys(mealTypeCounts).every(key => !dinnerOnlyMealTypes.includes(key) ? mealTypeCounts[key] <= 0 : true);

        return (
            <section className="plan-size-info" data-dinner={!!dinnerOnlyMealPlan}>
                {days}-{dinnerOnlyMealPlan ? 'dinner plan' : 'day plan'}
            </section>
        );
    }

    onClick = (ev) => {
        const { showCustomizer } = this.context;
        const { onClick } = this.props;
        const { plan } = this.state;

        if (onClick && !(ev.button == 1 || ev.ctrlKey)) {
            onClick(ev);
            ev.preventDefault();
            return;
        }

        if (showCustomizer && !(ev.button == 1 || ev.ctrlKey)) {
            showCustomizer(plan);
            ev.preventDefault();
        }
    }

    link = (element) => {
        const { isPublisher } = this.context;
        const { plan } = this.state;

        if (isPublisher) {
            return <Link to={`/publisher/plan/${plan.uuid}`}>{element}</Link>;
        }

        const newLocation = {
            pathname: `/menus/${plan.uuid}`,
        };

        if (!(plan.links && plan.links.self)) {
            newLocation.query = {virtual: 1};
        }

        return <Link to={newLocation} onClick={this.onClick}>{element}</Link>
    }


    renderSearchResultCard = () => {
        const { isPublisher } = this.context;
        const { virtual, children, hideTitle} = this.props;
        const { plan } = this.state;

        if (!plan) {
            return <span />
        }

        return (
            <div className="recipe-card plan-card-search-result">
                {this.link(
                    <ImgResized className="recipe-card-image plan-card-search-result-image" src={plan.image} width={300} height={300} />
                )}

                {this.link(<div className="recipe-card-text plan-card-search-result-text">
                    <div>
                        <span>
                            <h2>Meal Plan</h2>
                            <svg className="hero-tag" width="71" height="39" viewBox="0 0 71 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M60.4624 0.5V39C60.4624 39 70.4624 34.9474 70.4624 31.5702V29.8228V10.5C70.4624 4.97715 65.9852 0.5 60.4624 0.5Z" fill="#A30063"/>
                                <path d="M7.82607 16.7647L1.18925 30.5666C0.870011 31.2305 1.35381 32 2.09047 32L69.5 32C70.0523 32 70.5 31.5577 70.5 31.0054V0.982791C70.5 0.430506 70.0523 0 69.5 0H2.0619C1.33236 0 0.848365 0.755956 1.15369 1.41852L7.83306 15.9128C7.95785 16.1836 7.95529 16.496 7.82607 16.7647Z" fill="#A30063"/>
                            </svg>
                        </span>
                        <h3>{!hideTitle ? plan.title : null}</h3>
                        {children}
                        {this.renderPatientOrSize()}
                    </div>
                </div>)}

                <FavoriteButton onBeforeUnfavorite={this.checkRecommendations} plan={plan} />
            </div>
        );
    }

    checkRecommendations = () => {
        const {recommendations, plan} = this.state;
        const { confirm } = this.context;
        
        const recommendation = recommendations.find((recommendation) => recommendation.resource.uuid == plan.uuid);

        if (recommendation) {
             confirm(
                <p className="t3">This meal plan cannot be removed from favorites since it was recommended by {recommendation.author_name}. Please contact {recommendation.author_name} to request removal.</p>,
                accept => false,
                accept => false,
                {rejectText: null}
            );

            return true;
        }

        return false;
    }

    addPlanToFavorites = (plan) => {
        const lastBoard = BoardStore.getDefaultBoard();
        const newItem = {
            resource_id: plan.uuid,
            resource_type: plan.type,
        };

        if (lastBoard.links) {
            // Board already exists, just add to it.
            BoardActions.addToBoard(lastBoard, [newItem]);
        } else {
            lastBoard.contents = lastBoard.contents || [];
            lastBoard.contents.push(newItem);

            // Board does not exist, create a whole new one
            BoardActions.upsertBoards([lastBoard]);
        }
    }

    savePlan = (plan) => {
        const { uuid, links, type, created, ...rest } = plan;

        return AuthStore.fetch(getConfig('recipe_api') + plan.links.self.href, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; schema=plan/1',
                'Accept': 'application/json; schema=plan/1',
            },
            body: JSON.stringify(rest),
        });
    }

    onSavePlan = () => {
        // Save the plan to the database. If it's already curated, then we just save
        // it. If it's virtual, then we store it and reset our state to the new version.
        const { replaceMealPlan } = this.context;
        const { plan } = this.state;


        // We also need to remove the local edit copy and the virtual copy.
        this.setState({saving: true});

        if (!(plan.links && plan.links.self)) {
            // Create new document in database...
            const { uuid, links, created, type, status, merchant, ...rest } = plan;

            createNewDocument('plan', rest, 'plan/1').then(
                response => {
                    deleteVirtualPlan(plan.uuid, response.uuid);

                    store.remove('menu-edited-' + plan.uuid);

                    // Meal plan is no longer dirty, unset the dirty flag.
                    this.setState({saving: false, plan: response, dirty: false}, this.syncAssets);

                    // If we have a replaceMealPlan context function, let's inform our ancestor of the change
                    replaceMealPlan && replaceMealPlan(plan, response);
                    
                    updateCachedDocuments([response]);
                    this.addPlanToFavorites(response);

                },
                error => this.setState({saving: false, saveError: error.message})
            );
            
            return;
        }

        // Otherwise, save it to it's self-link.
        this.savePlan(plan).then(
            response => {
                // Courtesy update of plan document in cache
                updateCachedDocuments([response]);

                // We're clean now, so remove our locally edited copy.
                store.remove('menu-edited-' + response.uuid);

                this.setState({lastSaved: moment(), saveError: false, saving: false, dirty: false});
                this.resetLastSaved();
                this.addPlanToFavorites(response);
            },
            error => {
                this.setState({lastSaved: null, saveError: error.message, saving: false});
            }
        );

    }


    renderNormalPlanCard = () => {
        const { isPublisher } = this.context;
        let { hideTitle, showFavorites, showAdvancedMode, shortMode, onRemove } = this.props;
        const { plan} = this.state;

        const aspect = !shortMode
                     ? { w: 400, h: 500 }
                     : { w: 600, h: 600 };

        return (
            <div className="plan-card" data-advanced={showAdvancedMode} data-short={shortMode}>
                <div className="plan-card-fan1"></div>
                <div className="plan-card-fan2"></div>
                <div className="plan-card-image-container">
                    {onRemove ?
                        <button className="delete-btn" onClick={onRemove} title="Remove meal plan">
                            <i className="icon-trash-can" />
                        </button>
                    : null}

                    {this.link(<ImgResized className="plan-card-image" src={plan.image} width={aspect.w} height={aspect.h} />)}

                    <div className="plan-card-contents">
                        {this.link(<div className="plan-card-text">
                            <div>
                                <PlanMealCounts plan={plan} />
                                {this.renderPatientOrSize()}
                                <h3>{!hideTitle ? plan.title : null}</h3>
                            </div>
                        </div>)}

                        {isPublisher ? <PublisherSubmenu plan={plan} /> : null}
                        {showFavorites && !isPublisher ? <FavoriteButton onFavorite={this.onSavePlan} onBeforeUnfavorite={this.checkRecommendations} plan={plan} /> : null}
                    </div>
                </div>
            </div>
        );
    }


    render() {
        const { isSearchResult, shouldLoadPlan } = this.props;
        const { plan, loaded} = this.state;

        if (!plan || (!loaded && shouldLoadPlan)) {
            return <span />
        }

        return (
            <>
                {isSearchResult ? this.renderSearchResultCard() : this.renderNormalPlanCard()}
            </>
        );
    }
}
