'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Helmet from 'react-helmet';
import Modal from 'react-modal';
import moment from 'moment';
import uuid from 'uuid';
import indexBy from 'lodash.indexby';
import classNames from 'classnames';

import SchedulingModal from '../../Recipes/SchedulingModal.react';
import AddToCollectionButton from '../../Recipes/AddToCollectionButton.react';
import EditMealServingsModal from '../../Recipes/EditMealServingsModal.react';
import RecipeIngredients from '../../Modules/RecipeIngredients.react';
import RecipeInstructions from '../../Modules/RecipeInstructions.react';
import RecipeEditor from '../../Recipes/Editor.react';
import RecipeToPdfButton from '../../Recipes/RecipeToPdfButton.react';
import FavoriteButton from '../../Widgets/FavoriteButton.react';
import NutritionInfo from '../../Products/NutritionInfo.react';
import HowMuchToEat from '../../Recipes/HowMuchToEat.react';
import Popup from '../../Widgets/Popup.react';
import RecommendModal from '../../../pro/components/Modals/RecommendModal.react';
import ConfirmOverwriteModal from '../../Planner/Modals/ConfirmOverwriteModal.react';
import MealTitle from '../../Meals/MealTitle.react';

import Merchant from '../../Recipes/Merchant.react';
import SharePopup from '../../Widgets/SharePopup.react';

import ImgResized from '../../Widgets/ImgResized.react';

import LoginForm from '../../Widgets/LoginForm.react';
import Footer from '../../Widgets/footer/Footer.react';

import UserStore from '../../../stores/UserStore';
import MealActions from '../../../actions/MealActions';
import MealStore from '../../../stores/MealStore';
import GroceryActions from '../../../actions/GroceryActions';

import { getAssetsForMeals } from '../../../utils/Meals';

import { toHuman } from '../../../utils/Duration';
import { getConfig } from '../../../utils/Env';
import { getNutrientsToDisplay } from '../../../pro/utils/Patients';
import {
    getParticipantsForMeal,
    updateMealLeftovers,
    getDefaultParticipantsForRecipe,
    getPrimaryMeal,
    getBatchInfo as getBatchInfoMeals,
} from '../../../utils/Meals';
import { getBatchInfo as getBatchInfoProMeals } from '../../../utils/proMeals';
import { inquire } from '../../../utils/Enforcer';
import { fetchDocumentsById } from '../../../utils/Content';
import Analytics from '../../../utils/Analytics';
import { isMealInGroceries } from '../../../utils/Grocery';
import { ScreenService } from '../../../utils/ScreenService';

import nutrKeys from '../../../tables/nutrient-order';

import '../../../pages/MealDetails.scss';

export default class RecipeDetails extends Component {
    static propTypes = {
        uuid: PropTypes.string,
        recipe: PropTypes.object,

        editableLeftovers: PropTypes.bool,
        editableParticipants: PropTypes.bool,
        hideScheduleButton: PropTypes.bool,
        hidePrintBtn: PropTypes.bool,

        defaultScaling: PropTypes.number,
        defaultParticipants: PropTypes.array,
        defaultLoggedPortion: PropTypes.number,

        onChangeParticipants: PropTypes.func,
        sourceMetaData: PropTypes.object,
    };

    static defaultProps = {
        editableLeftovers: true,
        editableParticipants: true,
    };

    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
        isMobile: PropTypes.bool,
        isPro: PropTypes.bool,
        showUpgradeForm: PropTypes.func,
        showLoginForm: PropTypes.func,
        addSwapContext: PropTypes.object,
    };

    static childContextTypes = {
        foods: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        const user = UserStore.getUser();
        const profile = this.getProfileFromProps(props, context, user);
        const meals = MealStore.getMeals();

        this.state = {
            loading: false,
            synced: false,
            user,
            profile,
            participants: null,
            scaling: null,
            allMeals: meals,
            dirty: false,

            isEditModalOpen: false,
        };
    }

    getChildContext = () => {
        const { foods = {} } = this.state;

        return {
            foods,
        };
    };

    componentDidMount = () => {
        const { recipe, uuid } = this.props;

        ScreenService.keepAwake();

        if (recipe?.uuid) {
            this.loadRecipe(recipe.uuid);
        } else if (uuid) {
            this.loadRecipe(this.props.uuid);
        }

        UserStore.addChangeListener(this.onUserStoreChange);
    };

    componentWillUnmount = () => {
        ScreenService.allowSleep();
        UserStore.removeChangeListener(this.onUserStoreChange);
    };

    onUserStoreChange = () => {
        this.setState({ user: UserStore.getUser() });
    };

    syncAssets = () => {
        let { recipe, profile, participants, scaling, translated, allMeals } = this.state;
        const { defaultParticipants, defaultScaling } = this.props;

        if (!participants) {
            participants = defaultParticipants || getDefaultParticipantsForRecipe(recipe, profile);

            const needed = participants.reduce((total, member) => total + (member.portion || 1), 0);
            scaling = defaultScaling || Math.ceil(needed / (recipe.servings || 1));
        }

        this.setState({ participants, scaling });

        let uuidsToLoad = [recipe.details];

        if (recipe.merchant) {
            uuidsToLoad.push(recipe.merchant.uuid);
        }

        uuidsToLoad = uuidsToLoad.concat(recipe.subrecipes.map((sr) => sr.recipe));
        uuidsToLoad = uuidsToLoad.concat(recipe.subrecipes.map((sr) => sr.details));
        uuidsToLoad = uuidsToLoad.concat(recipe.foods || []);

        getAssetsForMeals(allMeals).then(({ recipes, foods, details, groceries }) => {
            this.setState({
                allRecipes: recipes,
                allFoods: foods,
                allDetails: details,
                allGroceries: groceries,
                synced: true,
            });
        });

        return fetchDocumentsById(uuidsToLoad).then(async (documents) => {
            let details = documents.find((d) => d.uuid === recipe.details);
            const merchant = documents.filter((d) => d.type === 'merchant')[0];
            const subrecipes = documents.filter((d) => d.type === 'recipe');
            const subdetails = indexBy(
                documents.filter((d) => d.type === 'recipe_details'),
                'uuid'
            );
            const foods = indexBy(
                documents.filter((d) => d.type === 'food'),
                'uuid'
            );

            if (!details) {
                this.setState({ error: 'Details not found', synced: true, loading: false });
                return;
            }

            this.setState({
                recipe,
                details,
                subdetails,
                subrecipes,
                foods,
                merchant,
                translated,
                synced: true,
                loading: false,
            });
        });
    };

    loadRecipe = (uuid) => {
        let { profile } = this.state;

        this.setState({ loading: true });

        // First, inquire to see if we have write and publish permission.
        const inquiries = [
            { action: 'publish', resource: uuid },
            { action: 'write', resource: uuid },
        ];

        inquire(inquiries).then((decisions) => {
            if (!decisions) {
                this.setState({ canWrite: false });
                return;
            }

            let canPublish = decisions.filter((d) => d.action == 'publish')[0].decision === 'permit';
            let canWrite = decisions.filter((d) => d.action == 'write')[0].decision === 'permit';

            this.setState({ canPublish, canWrite });
        });

        // Second, asynchronously load the recipe
        return fetchDocumentsById([uuid]).then(async (recipes) => {
            if (!recipes || (recipes && !recipes[0])) {
                this.setState({ error: 'Recipe not found' });
                return;
            }

            const recipe = recipes[0];

            this.setState({ recipe }, this.syncAssets);

            Analytics.viewRecipe({
                'Recipe UUID': recipe.uuid,
                'Recipe Name': recipe.title,
            });
        });
    };

    getProfileFromProps = (props, context, user) => {
        if (context.profile) {
            return context.profile;
        }

        if (context.addSwapContext && context.addSwapContext.profile) {
            return context.addSwapContext.profile;
        }

        if (!user) {
            return {
                preferences: { diets: [], avoidances: [] },
                conditions: ['General Healthy Diet'],
                prescriptions: [],
                family: [],
                portion: 1,
            };
        }

        let {
            preferences = {diets: [], avoidances: []},
            conditions = ['General Healthy Diet'],
            prescriptions = [],
            family = [],
            portion = 1,
            hide_nutrition = false,
            ...rest
        } = user;

        return {
            preferences,
            conditions,
            prescriptions,
            family,
            portion,
            ...rest,
        };
    };

    openRecipeEditModal = () => {
        this.setState({ isEditModalOpen: true });
    };

    openRecipeDuplicateModal = () => {
        this.setState({ isEditModalOpen: true, saveAsCopy: true });
    };

    closeRecipeEditModal = () => {
        this.setState({ isEditModalOpen: false });
    };

    closeParticipantModal = () => {
        this.setState({ isEditingParticipants: false });
    };

    showEditMealServings = () => {
        const { editableLeftovers } = this.props;
        const { user } = this.state;

        // Don't open the edit dialog if the user is not logged in.
        if (!editableLeftovers || !user) {
            return;
        }

        this.setState({ isEditingParticipants: true });
    };

    onChangeParticipants = (participants, scaling, leftoverDates, totalDays) => {
        const { onChangeParticipants } = this.props;

        this.setState({ participants, scaling, isEditingParticipants: false, dirty: true });

        onChangeParticipants && onChangeParticipants(participants, scaling, leftoverDates, totalDays);
    };

    openScheduleModal = () => {
        this.setState({ isScheduling: true });
    };

    closeScheduleModal = () => {
        this.setState({ isScheduling: false });
    };

    onCompleteSignIn = (user) => {
        const { date, mealType } = this.state;

        this.addToCalendar(date, mealType);
    };

    onCompleteSignUp = (user) => {
        const { date, mealType } = this.state;

        this.addToCalendar(date, mealType);
    };

    getLeftoverDateOverlaps = (profile, recipe, scaling, participants, date, mealType) => {
        const { allMeals } = this.state;
        let overlaps = [],
            clears = [];

        if (!profile.preferences.leftovers_enabled) {
            return { clears: [], overlaps: [] }; // no leftovers enabled, disabled by the users preferences
        }

        let tags = (recipe && recipe.tags) || [];

        // Do we have a no leftovers tag? Don't bother creating leftovers records.
        if (tags.indexOf('No Leftovers') != -1) {
            return { clears: [], overlaps: [] }; // no leftovers, not a good recipe for it.
        }

        const needed = participants.reduce((total, member) => total + member.portion, 0);

        if (!scaling) {
            scaling = Math.ceil(needed / recipe.servings);
        }

        const totalServings = recipe.servings * scaling;
        const totalLeftovers = Math.floor(totalServings / needed) - 1;

        let i = 1;
        while (i <= totalLeftovers && i <= 4) {
            const leftoverDate = moment(date).add(i++, 'day');
            const toOverwrite = allMeals.filter(
                (meal) =>
                    meal.meal_type !== 'leftover' && meal.meal === mealType && leftoverDate.isSame(meal.date, 'day')
            );

            if (toOverwrite.length > 0) {
                overlaps.push(leftoverDate);
            } else {
                clears.push(leftoverDate);
            }
        }

        return { clears, overlaps };
    };

    getOverlaps = (mealsToReschedule, date, mealType) => {
        let overlaps = [],
            clears = [];
        const { user } = this.state;
        const { allMeals, allRecipes, allFoods, recipe } = this.state;
        const { primary } = getPrimaryMeal(mealsToReschedule, allRecipes, allFoods);

        const uuidsToReschedule = mealsToReschedule.map((m) => m.uuid);

        // Are there meals at the destination?
        const destMeals = allMeals.filter((m) => !m.deleted && m.meal === mealType && date.isSame(m.date, 'day')); //&&
        //!uuidsToReschedule.includes(m.uuid) &&
        //!uuidsToReschedule.includes(m.parent_uuid));

        if (destMeals.length) {
            overlaps.push({
                mealType,
                date,
                destMeals,
                sourceMeals: mealsToReschedule,
            });
        } else {
            clears.push({
                mealType,
                date,
                sourceMeals: mealsToReschedule,
            });
        }

        if (primary.meal_type === 'fresh' && recipe) {
            const participants = getParticipantsForMeal(primary, user);
            const leftoverlaps = this.getLeftoverDateOverlaps(
                user,
                recipe,
                primary.scaling,
                participants,
                date,
                mealType
            );

            leftoverlaps.overlaps?.forEach((leftoverDate) => {
                // Find the meals at the destination that would be overwritten.
                const destMeals = allMeals.filter(
                    (m) =>
                        m.meal === mealType &&
                        leftoverDate.isSame(m.date, 'day') &&
                        !uuidsToReschedule.includes(m.uuid) &&
                        !uuidsToReschedule.includes(m.parent_uuid)
                );

                const daysDiff = leftoverDate.diff(date, 'day');
                const leftoverTrueDate = moment(primary.date).add(daysDiff, 'day');

                let sourceMeals = [];

                //create new meal object for each leftover
                sourceMeals.push({
                    ...primary,
                    date: leftoverTrueDate.format('YYYY-MM-DD'),
                    meal_type: 'leftover',
                    meal: mealType,
                    uuid: uuid.v4(),
                    logged_portion: null,
                });

                // If there are already meals at our destination?
                if (destMeals.length) {
                    overlaps.push({
                        date: leftoverDate,
                        mealType,
                        destMeals,
                        sourceMeals,
                    });
                } else {
                    clears.push({
                        mealType,
                        date: leftoverDate,
                        sourceMeals,
                    });
                }
            });

            leftoverlaps.clears.forEach((clearDate) => {
                const daysDiff = clearDate.diff(date, 'day');
                const leftoverTrueDate = moment(primary.date).add(daysDiff, 'day');

                // we're assuming that any other dishes have been scaled to the same settings as the primary.
                let sourceMeals = allMeals.filter(
                    (m) =>
                        m.meal_type === 'leftover' &&
                        leftoverTrueDate.isSame(m.date, 'day') &&
                        uuidsToReschedule.includes(m.parent_uuid)
                );

                clears.push({ mealType, date: clearDate, sourceMeals });
            });
        }

        return { overlaps, clears, mealsToReschedule };
    };

    addToCalendar = (date, mealType = 'Dinner') => {
        const { user, profile, recipe, participants, scaling } = this.state;
        const { location, router, showLoginForm, showUpgradeForm } = this.context;

        if (!recipe) {
            return false;
        }

        if (!user) {
            // Did the user come from an email or google?
            const loginFormOpts = {
                defaultMode:
                    location &&
                    location.query &&
                    (location.query.utm_source == 'rd_recommendation' || location.query.showLogin == '1')
                        ? 'sign-in'
                        : 'sign-up',
                signupLinkSwitchesModes: true,
                onCompleteSignUp: this.onCompleteSignUp,
                onCompleteSignIn: this.onCompleteSignIn,
                signUpProfile: {},
                offModalCta: 'You are one more step away from finding delicious meals just for you.',
            };

            // Store our date for later
            this.setState({ date, mealType });
            showLoginForm && showLoginForm(loginFormOpts);
            return;
        }

        const { capabilities = {} } = user;

        if (recipe.protection !== 'public' && !capabilities.meal_planner) {
            showUpgradeForm({ feature: 'meal_planner' });

            return false;
        }

        let needed = participants.reduce((total, member) => total + member.portion, 0);
        let dateFormatted = date.format('YYYY-MM-DD');

        let meal = {
            uuid: uuid.v4(),
            meal: mealType,
            meal_type: 'fresh',
            logged_amount: profile.portion,
            logged_unit: 'serving',
            logged_grams: (Math.round(recipe.grams_per_serving * 1000) / 1000) * profile.portion,
            logged_milliliters: (Math.round(recipe.milliliters_per_serving * 1000) / 1000) * profile.portion,
            recipe_uuid: recipe.uuid,
            details_uuid: recipe.details,
            recipe_title: recipe.title,
            recipe_image: recipe.image,
            date: dateFormatted,
            created: moment().format(),
            participants: participants.map((p) => p.uuid || '').join(','),
            scaling,
        };

        const query = {};

        const { overlaps, clears } = this.getOverlaps([meal], date, mealType);

        if (!overlaps.length) {
            MealActions.upsertMeals([meal]);
            router.push({ pathname: `/meals/${dateFormatted}/${mealType}` });
        } else {
            this.setState({
                isConfirmOverwriteOpen: true,
                mealsToReschedule: [meal],
                confirmOverlaps: overlaps,
                confirmClears: clears,
                confirmDate: date,
                confirmMealType: mealType,
            });
        }

        return true;
    };

    closeConfirmOverwriteModal = () => {
        this.setState({
            isConfirmOverwriteOpen: false,
            confirmOverlaps: null,
            confirmClears: null,
            confirmDate: null,
            confirmMealType: null,
        });
    };

    renderConfirmOverwriteModal = () => {
        const {
            isConfirmOverwriteOpen,
            mealsToReschedule,
            confirmOverlaps,
            confirmClears,
            confirmDate,
            confirmMealType,
        } = this.state;

        if (!isConfirmOverwriteOpen || !mealsToReschedule) {
            return null;
        }

        return (
            <ConfirmOverwriteModal
                meals={mealsToReschedule}
                onModifyMeals={this.onModifyMeals}
                overlaps={confirmOverlaps}
                isRepeatMeal={false}
                clears={confirmClears}
                date={confirmDate}
                mealType={confirmMealType}
                closeModal={this.closeConfirmOverwriteModal}
                isRecipeSearch={true}
            />
        );
    };

    onModifyMeals = (mealsToUpsert, mealsToDelete = [], leftoverDates = null) => {
        let { synced, allGroceries, allMeals, allRecipes, allDetails, allFoods, confirmDate, confirmMealType } =
            this.state;
        const { router } = this.context;
        const { user } = this.state;

        // Do not do anything if we are not synced yet
        if (!synced) {
            return;
        }

        mealsToDelete = mealsToDelete || [];
        let grocerySyncMeals = [],
            upsertLeftovers = [];

        const upsertIds = mealsToUpsert.map((m) => m.uuid);

        mealsToUpsert.forEach((meal) => {
            delete meal.deleted;

            if (leftoverDates && meal.meal_type === 'fresh') {
                // Next update the leftovers.
                const { leftovers, toRemove } = updateMealLeftovers(
                    meal,
                    allMeals,
                    allRecipes[meal.recipe_uuid],
                    user,
                    leftoverDates
                );

                // Append the updated leftovers, and any leftovers that need to be removed, to our working lists.
                upsertLeftovers = upsertLeftovers.concat(leftovers);
                mealsToDelete = mealsToDelete.concat(toRemove);
            }

            // If this meal is already in the groceries and needs to be synchronized, sync it.
            if (isMealInGroceries(meal, allGroceries) && meal.scaling != meal.scaling_added_to_groceries) {
                grocerySyncMeals.push(meal);
            }
        });

        if (grocerySyncMeals.length > 0) {
            GroceryActions.syncMealsToGroceries(grocerySyncMeals, allRecipes, allDetails, allFoods);
        }

        if (mealsToDelete && mealsToDelete.length > 0) {
            // Signal to the grocery list to remove ingredients too
            GroceryActions.removeMealsFromGroceries(mealsToDelete, allRecipes, allDetails, allFoods);

            // Collect any leftovers for these meals as well.
            const uuidsToDelete = mealsToDelete.map((m) => m.uuid);
            const childrenToDelete = allMeals.filter((m) => uuidsToDelete.includes(m.parent_uuid));
            mealsToDelete = mealsToDelete.concat(childrenToDelete);

            // Mark all meals as deleted
            mealsToDelete.forEach((m) => (m.deleted = true));

            // Signal the Meal Store to remove the fresh meal & the leftovers
            MealActions.deleteMeals(mealsToDelete);
        }

        MealActions.upsertMeals(mealsToUpsert.concat(upsertLeftovers));

        const dateFormatted = confirmDate.format('YYYY-MM-DD');

        router.push({ pathname: `/meals/${dateFormatted}/${confirmMealType}` });
    };

    renderCallToAction = () => {
        const { onSelectRecipe, hideScheduleButton, sourceMetaData } = this.props;
        const { user, recipe, participants, scaling } = this.state;
        const { addSwapContext } = this.context;
        const { capabilities = {} } = user || {};
        const isLogging = addSwapContext && addSwapContext.auto_log ? true : false;

        const content = [];

        if (onSelectRecipe) {
            return (
                <button
                    className="el-toolbar-green-pill-btn"
                    onClick={() => onSelectRecipe(recipe, participants, scaling, null, sourceMetaData)}
                >
                    {isLogging ? 'log meal' : 'select recipe'}
                    {recipe?.protection !== 'public' && !capabilities.meal_planner ? <i className="icon-lock" /> : null}
                </button>
            );
        }

        if (hideScheduleButton) {
            return;
        }

        return (
            <button className="el-toolbar-green-pill-btn" onClick={this.openScheduleModal}>
                schedule it
                {recipe?.protection !== 'public' && !capabilities.meal_planner ? <i className="icon-lock" /> : null}
            </button>
        );
    };

    onStartRecommend = (recipe_uuid) => {
        const { location, router } = this.context;
        const { pathname, hash, query } = location;

        query.recommend = recipe_uuid;

        router.push({ pathname, hash, query });
    };

    closeRecommendModal = (sent) => {
        const { location, router } = this.context;
        const { pathname, hash, query } = location;

        delete query.recommend;

        router.push({ pathname, hash, query });
    };

    renderRecommendModal = () => {
        const { location } = this.context;
        const { recipe } = this.state;

        if (!location.query.recommend) {
            return;
        }

        return <RecommendModal recipe={recipe} closeModal={this.closeRecommendModal} />;
    };

    renderControlBar = () => {
        const { user, recipe, canPublish, scaling } = this.state;
        const { hidePrintBtn } = this.props;
        const { isPro, isMobile } = this.context;

        if (!recipe) {
            return;
        }

        return (
            <div className={classNames('control-bar-container el-toolbar1', !isMobile ? 'el-toolbar1-dark' : null)}>
                {!hidePrintBtn ? (
                    <RecipeToPdfButton
                        scaling={scaling}
                        recipe={recipe}
                        className="el-toolbar-popup"
                        positionClassName="el-popup-top-left"
                        button={
                            <>
                                <i className="feather feather-printer" /> <em>print</em>
                            </>
                        }
                    />
                ) : null}

                <SharePopup className="el-toolbar-popup" recipe={recipe} />
                {isPro ? (
                    <button className="el-toolbar-btn" onClick={() => this.onStartRecommend(recipe.uuid)}>
                        <em>recommend</em>
                    </button>
                ) : null}
                {this.renderCallToAction()}
            </div>
        );
    };

    renderEditMealServingsButton = () => {
        const { user, recipe, participants, scaling } = this.state;
        const { editableLeftovers } = this.props;

        if (!recipe || !participants) {
            return;
        }

        const totalTime = recipe.total_time || 0;

        const servings = recipe.servings || 1;
        let needed = Math.ceil(participants.reduce((total, member) => total + member.portion, 0));
        let yeild = (scaling || 1) * servings;
        const totalParticipants = participants?.length || 1;
        const totalLeftovers = Math.floor(yeild / needed) - 1;

        return (
            <div className="edit-meal-servings" onClick={this.showEditMealServings}>
                <section className="serves">
                    <span>Serves</span>{' '}
                    <span>
                        {totalParticipants} {totalParticipants === 1 ? 'person' : 'people'}
                    </span>
                </section>

                {totalLeftovers > 0 ? (
                    <section className="serves leftovers">
                        <span>Leftovers</span>
                        <span>
                            {totalLeftovers} {totalLeftovers === 1 ? 'day' : 'days'}
                        </span>
                    </section>
                ) : null}

                <section className="timings">
                    {totalTime ? (
                        <p>
                            {toHuman(totalTime, true)}
                            <br />
                            prep/cook
                        </p>
                    ) : null}
                </section>
            </div>
        );
    };

    renderNutritionTabs = () => {
        const { profile, recipe } = this.state;

        const { hide_nutrition = false, rd_override = false } = profile || {};

        if (!(recipe && recipe.nutrients && recipe.nutrients.values)) {
            return null;
        }

        if (hide_nutrition && rd_override !== true) {
            return;
        }

        const toDisplay = getNutrientsToDisplay(profile, (recipe.nutrients && recipe.nutrients.values) || {});

        const nutrNos = Object.keys(toDisplay).sort((a, b) => {
            const aI = nutrKeys.indexOf(a),
                bI = nutrKeys.indexOf(b);

            if (aI > bI) return 1;
            if (aI < bI) return -1;
            return 0;
        });

        return (
            <section className="details-section second">
                <header>
                    <div className="section-title">
                        <p className="t2">Nutrition Information</p>
                    </div>
                </header>

                <div className="section-body">
                    <NutritionInfo nutrients={recipe.nutrients.values} profile={profile} />
                </div>
            </section>
        );
    };

    renderEditMealServingsModal = () => {
        const { recipe, profile, participants, scaling, isEditingParticipants, allMeals, dirty } = this.state;
        const { editableParticipants } = this.props;
        const { addSwapContext, isPro } = this.context;

        if (!recipe || !isEditingParticipants) {
            return;
        }

        let inheritedParticipants = participants;
        let inheritedScaling = scaling;

        if (addSwapContext?.mode === 'replace') {
            if (isPro) {
                const { offset, mealType, plan } = addSwapContext;
                const batchInfoProMeals = getBatchInfoProMeals(offset, mealType, profile, plan);

                if (batchInfoProMeals) {
                    const needed = batchInfoProMeals.participants.reduce((total, member) => total + member.portion, 0);
                    const totalNeeded = needed * batchInfoProMeals.totalDays;
                    inheritedScaling = Math.ceil(totalNeeded / recipe.servings);
                    inheritedParticipants = batchInfoProMeals.participants;
                }
            } else {
                const { date, mealType } = addSwapContext;
                const batchInfoMeals = getBatchInfoMeals(date.format('YYYY-MM-DD'), mealType, profile, allMeals);

                if (batchInfoMeals) {
                    const needed = batchInfoMeals.participants.reduce((total, member) => total + member.portion, 0);
                    const totalNeeded = needed * batchInfoMeals.totalDays;
                    inheritedScaling = Math.ceil(totalNeeded / recipe.servings);
                    inheritedParticipants = batchInfoMeals.participants;
                }
            }
        }

        return (
            <EditMealServingsModal
                closeModal={this.closeParticipantModal}
                content={recipe}
                scaling={dirty ? scaling : inheritedScaling}
                profile={profile}
                participants={dirty ? participants : inheritedParticipants}
                editableParticipants={editableParticipants}
                onChangeParticipants={this.onChangeParticipants}
            />
        );
    };

    renderModalBody = () => {
        const { inhibit_swap = false } = profile || {};
        const {
            recipe,
            loading,
            synced,
            user,
            profile,
            details,
            subdetails,
            merchant,
            participants,
            scaling,
            canPublish,
        } = this.state;
        const { isMobile } = this.context;
        const canEditSingleMeal = user && !inhibit_swap;
        const canDuplicateMeal = user && recipe && user.uuid == recipe.owner;

        if (loading || !synced) {
            return (
                <div className="details-loading el-fonts">
                    <h4>Loading food</h4>
                    <i className="icon-spinner2" />
                </div>
            );
        }

        return (
            <>
                {!isMobile ? (
                    <div className="details-header">
                        {this.renderEditMealServingsButton()}

                        <header>{this.renderControlBar()}</header>
                    </div>
                ) : null}

                <div className="meals-scroll-hero" data-index={0} data-singleton={false}>
                    <div className="hero-item" style={{ transform: 'translateX(30px)' }}>
                        <div className="hero-item-container">
                            <div className="meal-card" title={recipe.title}>
                                <div className="image-container recipe">
                                    <ImgResized
                                        alt={recipe.title}
                                        src={recipe.image}
                                        width={500}
                                        height={500}
                                        className="recipe-image"
                                    />
                                </div>

                                <div className="meal-info">
                                    <footer>
                                        <div className="lj">
                                            <div className="dish-badge">Dish 1</div>
                                        </div>
                                        <FavoriteButton recipe={recipe} />
                                    </footer>
                                    <p>{recipe.title}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="triangle" style={{ left: isMobile ? 65 : 140 }} />
                </div>

                <section className="details-section first">
                    <header>
                        <div className="section-title">
                            <div className="t2 with-merchant">
                                {merchant ? (
                                    <Popup
                                        positionClassName="el-popup-top-left-center"
                                        className="merchant-dropdown"
                                        button={
                                            <>
                                                Recipe by <em>{merchant.name}</em>{' '}
                                                <i className="feather feather-info" />
                                            </>
                                        }
                                    >
                                        <Merchant merchant={merchant} />
                                    </Popup>
                                ) : null}
                            </div>
                        </div>

                        <div className="meal-control-btns">
                            {canPublish ? (
                                <Link to={`/publisher/recipe/${recipe.uuid}`}>
                                    <button className="edit-meal-btn">
                                        <i className="feather feather-box" />
                                        <span>Admin</span>
                                    </button>
                                </Link>
                            ) : null}

                            <AddToCollectionButton recipe={recipe} />

                            <FavoriteButton icon="icon-heart2" iconBoarded="icon-heart" recipe={recipe}>
                                <span>Favorite</span>
                            </FavoriteButton>

                            {canEditSingleMeal && canDuplicateMeal ? (
                                <button className="edit-meal-btn" onClick={this.openRecipeDuplicateModal}>
                                    <i className="feather feather-copy" />
                                    <span>Duplicate</span>
                                </button>
                            ) : null}

                            {canEditSingleMeal ? (
                                <button className="edit-meal-btn" onClick={this.openRecipeEditModal}>
                                    <i className="feather feather-edit" />
                                    <span>Edit</span>
                                </button>
                            ) : null}
                        </div>
                    </header>

                    <div className="section-body">
                        <MealTitle item={recipe} readOnly />

                        <RecipeIngredients
                            recipe={recipe}
                            details={details}
                            subdetails={subdetails}
                            scaling={scaling}
                            profile={profile}
                        />
                    </div>
                </section>

                <section className="details-section second">
                    <header>
                        <div className="section-title">
                            <p className="t2">Dish Instructions</p>
                        </div>
                    </header>

                    <div className="section-body">
                        <RecipeInstructions recipe={recipe} details={details} subdetails={subdetails} user={user} />
                    </div>
                </section>

                {user && recipe.servings ? (
                    <div className="details-section third">
                        <header>
                            <div className="section-title">
                                <p className="t2">How Much to Eat</p>
                            </div>
                        </header>

                        <div className="section-body">
                            <HowMuchToEat
                                profile={profile}
                                recipe={recipe}
                                participants={participants}
                                scaling={scaling}
                                onClick={this.showEditMealServings}
                            />
                        </div>
                    </div>
                ) : null}

                {this.renderNutritionTabs()}
            </>
        );
    };

    renderSchedulingModal = () => {
        const { user, recipe, isScheduling } = this.state;

        if (!isScheduling) {
            return null;
        }

        const { capabilities = {} } = user || {};

        return (
            <SchedulingModal
                content={recipe}
                isLocked={recipe.protection !== 'public' && !capabilities.meal_planner}
                selectDate={this.addToCalendar}
                closeModal={this.closeScheduleModal}
            />
        );
    };

    closeModal = () => {
        const { closeModal } = this.props;
        const { router } = this.context;

        if (closeModal) {
            return closeModal();
        }

        router.push('/');
    };

    onSaveRecipe = (recipe, details) => {
        this.setState({ recipe, details }, this.syncAssets);

        this.closeRecipeEditModal();
    };

    renderRecipeEditModal = () => {
        const { recipe, profile, isEditModalOpen, saveAsCopy } = this.state;

        if (!isEditModalOpen) {
            return null;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.closeRecipeEditModal}
                closeModal={this.closeRecipeEditModal}
                className="el-modal el-modal1"
                overlayClassName="el-modal-overlay"
                contentLabel="Edit Recipe Details"
            >
                <div className="el-modal-container el-modal1-container el-modal1-recipe-editor-container">
                    <header data-has-controls={false}>
                        <button className="el-modal-back-btn" onClick={this.closeRecipeEditModal}>
                            <span>Back</span>
                        </button>

                        <h2>Edit Recipe</h2>
                    </header>

                    <div className="el-modal-body-container el-modal1-body-container el-fonts">
                        <RecipeEditor
                            uuid={recipe.uuid}
                            saveAsCopy={saveAsCopy}
                            profile={profile}
                            closeModal={this.closeRecipeEditModal}
                            onSaveRecipe={this.onSaveRecipe}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { isMobile } = this.context;
        const { profile, recipe } = this.state;

        if (!profile) {
            return (
                <div className="my-account-login">
                    <Helmet title="Please sign-in or register | EatLove" />

                    <LoginForm defaultSignUp={false} />
                    <Footer />
                </div>
            );
        }

        return (
            <>
                {recipe ? (
                    <Helmet
                        title={recipe.title + ' | EatLove'}
                        meta={[
                            { name: 'description', content: recipe.description },
                            { name: 'twitter:title', content: recipe.title },
                            { name: 'twitter:description', content: recipe.description },
                            { name: 'twitter:image', content: recipe.image },
                            { property: 'og:title', content: recipe.title },
                            { property: 'og:type', content: recipe.page_type },
                            {
                                property: 'og:url',
                                content: 'https://' + getConfig('self_host') + '/recipes/' + recipe.uuid,
                            },
                            { property: 'og:image', content: recipe.image },
                            { property: 'og:description', content: recipe.description },
                        ]}
                    />
                ) : null}
                <Modal
                    isOpen={true}
                    onRequestClose={this.closeModal}
                    closeModal={this.closeModal}
                    className="el-modal el-modal1"
                    overlayClassName="el-modal-overlay"
                    contentLabel="Meal Details"
                >
                    <div className="el-modal-container el-modal1-container content-details-modal-container">
                        <header data-has-controls={isMobile}>
                            <button className="el-modal-back-btn" onClick={this.closeModal}>
                                <span>Back</span>
                            </button>

                            <h2>Recipe</h2>

                            {isMobile ? (
                                <>
                                    {this.renderEditMealServingsButton()}

                                    <div className="el-modal-controls el-modal1-controls">
                                        <header>{this.renderControlBar()}</header>
                                    </div>
                                </>
                            ) : null}
                        </header>

                        <div className="el-modal-body-container el-modal1-body-container el-fonts">
                            {this.renderModalBody()}
                        </div>
                    </div>
                </Modal>

                {this.renderEditMealServingsModal()}
                {this.renderSchedulingModal()}
                {this.renderRecipeEditModal()}
                {this.renderRecommendModal()}
                {this.renderConfirmOverwriteModal()}
            </>
        );
    }
}
