'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Library extends Component {
    static propTypes = {
    };

    toggleMyLibrary = () => {
        const { params, onChangeParams } = this.props;

        if (params.filters.library) {
            params.filters.library = false;
        } else {
            params.filters.library = true;
        }

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;

        return (
            <span className="global-search-hideable-filter my-library-toggle">
                <button className="el-checkbox" data-active={params.filters.library === true}
                    onClick={this.toggleMyLibrary}>
                    From My Favorites
                </button>
            </span>
        );
    }
}
