'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class Ingredients extends Component {
    static propTypes = {
    };

    static contextTypes = {
        setParamsToRecipeOnly: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    setServingsFilter = (opt) => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};

        if (params.filters.total_ingredients &&
            params.filters.total_ingredients.lte == opt.max) {
            delete params.filters.total_ingredients;
        } else {
            params.filters.total_ingredients = {gte: opt.min || 0, lte: opt.max};
        }

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;
        const { filters = {} } = params;

        const options = [
            {max:  5, label: '5 or fewer'},
            {min:  6, max:  8, label: '6 to 8'},
            {min:  8, max: 10, label: '8 to 10'},
        ];

        return (
            <div className="global-search-tag-group global-search-sort-filter">
                <ul>
                    {options.map((opt, i) => {
                        const isChecked = (filters.total_ingredients &&  filters.total_ingredients.lte == opt.max) ? true : false;

                        return (
                            <li key={i}>
                                <button className="el-radio-btn" data-active={isChecked}
                                    onClick={() => this.setServingsFilter(opt)}>
                                    {opt.label}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
