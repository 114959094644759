'use strict';
import { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '../../Widgets/Select.react';
import Popup from '../../../../components/Widgets/Popup.react';

import UserStore from '../../../../stores/UserStore';
import TelephoneInput from 'react-telephone-input';

import './AboutYourPatient.scss';
import './PatientForm.scss';

export default class BasicInfo extends Component {

    static defaultProps = {
        patient: {},
    };

    constructor(props) {
        super(props);

        const { patient } = this.props;

        const user = UserStore.getUser();
        this.state = {
            user,
            first_name: patient.first_name || '',
            last_name: patient.last_name || '',
            email: patient.email || '',
            email2: patient.email || '',
            mobile_number: patient.mobile_number || '',
            practice_type: patient.practice_type || user.practice_type || 'dietetics',
            hide_nutrition: !!patient.preferences.hide_nutrition,
            inhibit_autopopulate: !!patient.preferences.inhibit_autopopulate,
            inhibit_nutrition_info_email: patient.notifications.nutrition_info.inhibit_email,
            dirty: false,
        };
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        const user = UserStore.getUser();

        this.setState({user});
    }


    isDirty = () => {
        return this.state.dirty;
    }

    mutate = (patient, finished = false) => {
        if (this.validate(finished) !== true) {
            return patient;
        }

        const { first_name, last_name, email, practice_type, mobile_number, 
            hide_nutrition, inhibit_autopopulate, inhibit_nutrition_info_email } = this.state;

        patient.preferences = patient.preferences || {};

        patient.first_name = first_name;
        patient.last_name = last_name;
        patient.email = email.trim();
        patient.mobile_number = mobile_number;
        patient.practice_type = practice_type;
        patient.completed = patient.completed || [];
        patient.preferences.hide_nutrition = hide_nutrition;
        patient.preferences.inhibit_autopopulate = inhibit_autopopulate;
        patient.notifications.nutrition_info.inhibit_email = inhibit_nutrition_info_email;

        return patient;
    }

    validate = (finished = false) => {
        const { first_name, last_name, email, email2, practice_type, mobile_number } = this.state;

        if (!first_name) {
            this.setState({error: 'first_name'});

            return 'First name is required';
        }

        if (!last_name) {
            this.setState({error: 'last_name'});

            return 'Last name is required';
        }

        if (!email) {
            this.setState({error: 'email'});

            return 'Email is required';
        }

        if (email.trim() != email2.trim()) {
            this.setState({error: 'email2'});

            return 'Emails don\'t match';
        }

        if (!mobile_number) {
            this.setState({error: 'mobile_number'});

            return 'Mobile number is required';
        }

        this.setState({error: null});

        return true;
    }


    focused = null;

    autofocus = (el) => {
        if (el && !this.focused) {
            this.focused = el;
            this.focused.focus();
        }
    }
    featureNeedsAMembershipUpgrade = (capability) => {
        const { user } = this.state;
        const { capabilities = {} } = user;
        return !capabilities[capability];
    }

    onClickNutritionInfo = () => {
        const { inhibit_nutrition_info_email } = this.state;
        const { showUpgradeForm } = this.props;

        if (this.featureNeedsAMembershipUpgrade("nutrition_info")) {
            return showUpgradeForm({ feature: "nutrition_info" });
        }

        this.setState({ inhibit_nutrition_info_email: !inhibit_nutrition_info_email }, this.savePatient);
    }


    onClickSafeMode = () => {
        const { hide_nutrition } = this.state;
        const { showUpgradeForm } = this.props;

        if (this.featureNeedsAMembershipUpgrade("safe_mode")) {
            return showUpgradeForm({ feature: "safe_mode" });
        }

        this.setState({ hide_nutrition: !hide_nutrition }, this.savePatient);
    }

    onClickInhibitAutoPopulate = () => {
        const { inhibit_autopopulate } = this.state;
        const { showUpgradeForm } = this.props;

        if (this.featureNeedsAMembershipUpgrade("safe_mode")) {
            return showUpgradeForm({ feature: "safe_mode" });
        }

        this.setState({ inhibit_autopopulate: !inhibit_autopopulate }, this.savePatient)
    }

    onChangePracticeType = (practice_type) => {
        const { patient, onChangePatient } = this.props;

        patient.practice_type = practice_type;
        onChangePatient && onChangePatient(patient);

        this.setState({practice_type, dirty: true});
    }

    render() {
        const { patient } = this.props;
        const { user, first_name, last_name, email, email2,  error, practice_type, mobile_number, 
            hide_nutrition, inhibit_autopopulate, inhibit_nutrition_info_email} = this.state;

        const practiceTypeOpts = [
            {label: 'Dietetics', value: 'dietetics'},
            {label: 'Personal Trainer', value: 'fitness'},
        ];
        let tabIndex = 1;

        let hasAcceptedInvite = patient.invite_accepted ? true : false;
        const canChangePracticeType = user.practice && user.practice.can_change_practice_type;

        return (
            <div className="about-your-patient patient-form">
                <div className="with-label first-name">
                    <label>First Name</label>
                    <input type="text" value={first_name} maxLength="255" tabIndex={tabIndex++}
                        data-error={error === 'first_name'}
                        ref={this.autofocus} disabled={hasAcceptedInvite}
                        onChange={ev => this.setState({first_name: ev.target.value, dirty: true})} />
                </div>

                <div className="with-label last-name">
                    <label>Last Name</label>
                    <input type="text" value={last_name} maxLength="255" tabIndex={tabIndex++}
                        data-error={error === 'last_name'} disabled={hasAcceptedInvite}
                        onChange={ev => this.setState({last_name: ev.target.value, dirty: true})} />
                </div>

                <div className="with-label email1">
                    <label>Email Address</label>
                    <input type="text" value={email} maxLength="255" tabIndex={tabIndex++}
                        data-error={error === 'email'} disabled={hasAcceptedInvite}
                        onChange={ev => this.setState({email: ev.target.value, dirty: true})} />
                </div>

                <div className="with-label email2">
                    <label>Confirm Email Address</label>
                    <input type="text" value={email2} maxLength="255" tabIndex={tabIndex++}
                        data-error={error === 'email2'} disabled={hasAcceptedInvite}
                        onChange={ev => this.setState({email2: ev.target.value, dirty: true})} />
                </div>
                <div className="with-label phone-number" data-error={error === 'mobile_number'} >
                    <label>Mobile Number</label>
                    <TelephoneInput value={mobile_number} inputProps={{maxLength: 255}}
                        preferredCountries={['us', 'ca']}
                        defaultCountry="us"
                        tabIndex={tabIndex++}
                        flagsImagePath="https://static.chewba.info/images/country-flags.png"
                        onChange={(num, country) => this.setState({mobile_number: num, dirty: true})} />
                </div>
                {user.dietitian && user.dietitian.practice_role === 'admin' && canChangePracticeType ?
                    <div className="with-label practice-type">
                        <label>Practice Type</label>
                        <Select value={practice_type}
                            onChange={this.onChangePracticeType}
                            options={practiceTypeOpts} />
                    </div>
                    : null}
                <span class="el-nutrition-info-notifications">
                    <button
                        className="el-pro-checkbox el-fonts"
                        data-locked={this.featureNeedsAMembershipUpgrade("nutrition_info")}
                        data-checked={!inhibit_nutrition_info_email}
                        onClick={this.onClickNutritionInfo}
                        id="nutrition-info-notification"
                    >
                    <div className='nutrition-info'>
                        <label htmlFor="nutrition-info-notification"> ENABLE 23-WEEK NUTRITION PROGRAM </label>
                        <div className = 'popup-wrapper' onClick={(e) => e.stopPropagation()}>
                            <Popup className="el-popup-dark el-popup-tooltip"
                                positionClassName="el-popup-right-bottom"
                                button={<i className="icon-info"/>}
                                buttonTitle="">
                                <p className="t6">By checking this box, your {user.practice_type == 'dietetics' ? "patient" : "client"} will receive 23 weeks of educational content via email,
                                    empowering them with nutrition basics and behavior change insights.
                                    <a target="_blank" href="/download?key=23-week-nutrition-education-program"> Preview Program Content </a> by visiting the PRO Toolkit.</p>
                            </Popup>
                        </div>
                    </div>
                        <p className='info-icon-paragraph'>Patient will receive a weekly automated email reviewing general nutrition topics</p>
                    </button>
                </span>
                <button
                    className="el-pro-checkbox el-fonts"
                    data-locked={this.featureNeedsAMembershipUpgrade("safe_mode")}
                    data-checked={hide_nutrition}
                    onClick={this.onClickSafeMode}
                    id="safe-mode"
                >
                    <label htmlFor='safe-mode'>{ user.practice_type==="fitness" ? "HIDE NUTRITION INFO" : "SAFE MODE: HIDE NUTRITION INFO" }</label>
                    <p>{`Hide calorie, macro, micro, and anthropometrics data for ${user.practice_type == 'dietetics' ? "patient" : "client"}`}</p>
                </button>
                <button
                    className="el-pro-checkbox el-fonts"
                    data-locked={this.featureNeedsAMembershipUpgrade("safe_mode")}
                    data-checked={inhibit_autopopulate}
                    onClick={this.onClickInhibitAutoPopulate}
                    id="auto-populate"
                >
                    <label htmlFor='auto-populate'>DO NOT AUTO-POPULATE MEAL SCHEDULE </label>
                    <p>Ideal for tracking intake via food log before starting meal recommendations</p>
                </button>
            </div>
        );
    }
}
