'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import LocalStorage from 'store';
import BasicInfo from '../BasicInfo.react';
import AboutYourPatient from '../AboutYourPatient.react';
import DietPreferences from '../DietPreferences.react';
import MealPreferences from '../MealPreferences.react';
import Family from '../Family.react';
import EnergyCalc from '../EnergyCalc.react';
import Conditions from '../Conditions.react';
import Prescription from '../Prescription.react';
import Working from '../Working.react';
import Agreement from '../../../Licenses/Agreement.react.js';
import AuthStore from '../../../../../stores/AuthStore';
import UserStore from '../../../../../stores/UserStore';
import { getConfig } from '../../../../../utils/Env';
import { getParamsForProfile } from '../../../../utils/Patients';
import { processVirtualPlans } from '../../../../../utils/Plans';
import Analytics from '../../../../../utils/Analytics';
import modalStyles from '../../../../../jsx-styles/modals';
import MealDetails from '../MealDetails.react.js';
import NutritionPatternActions from '../../../../../actions/NutritionPatternActions';
import './CreatePatientModal.scss';
import '../../../Modals/Modals.scss';

export default class CreatePatientModal extends Component {
    static propTypes = {
        totalPatients: PropTypes.number,
        patientId: PropTypes.string,
        onSavePatient: PropTypes.func,
        onFinishPatient: PropTypes.func,
        closeModal: PropTypes.func,
    };

    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
        confirm: PropTypes.func,
        showUpgradeForm: PropTypes.func,
        sendPatientInvite: PropTypes.func
    };

    static childContextTypes = {
        nutritionPatterns: PropTypes.array
    }

    constructor(props, context) {
        super(props, context);

        const user = UserStore.getUser();

        const { capabilities } = user;

        let inhibit_jabber = !capabilities.messaging;
        let inhibit_nutrition_info = !capabilities.nutrition_info;
        let skill_level = 'Beginner';
        let inhibit_change_nutrition_profile = false;

        if (user.practice_type === 'dietetics') {
            // Always inhibit messaging and nutrition info by default in dietetics mode
            inhibit_jabber = true;
            inhibit_nutrition_info = true;
            skill_level = null;
        }

        const patient = {
            language: user.language || 'en', // always inherit from the current logged in user
            energy_method: 'mifflin',
            units_mode: user.units_mode,
            practice_type: user.practice_type,
            conditions: [],
            shopping_freq: 3,
            start_date: null,
            preferences: {
                diets: [],
                avoidances: [],
                daily_totals: {},
                exclude_foods: [],
                equipment: ['Oven', 'Stovetop', 'Microwave', 'Blender'],
                meal_types: [],
                prefer_milks: [],
                prefer_rices: [],
                skill_level,
                leftovers_enabled: true,
                max_leftover_days: 2,
                meal_kit_providers: [],
                hide_nutrition: false,
                inhibit_autopopulate: false,
            },
            family: [],
            daily_newsletter: true,
            daily_newsletter_time: '06:00:00',
            weekly_newsletter: true,
            following: true,
            inhibit_jabber,
            inhibit_change_avoidances: false,
            inhibit_change_nutrition_profile,
            notifications: {
                nutrition_info: {inhibit_email: inhibit_nutrition_info},
            }
        };

        this.state = {
            user,
            patient,
            pattern: null,

            generating: false,
            working: false,

            index: parseInt(context.location.query.page) || 0,
            customizations: {},
            disableSave: false,
        };
    }

    componentDidMount = () => {
        const { location } = this.context;
        const { patient } = this.state;

        if (location.query.patientId && patient.uuid !== location.query.patientId) {
            // Load patient from db.
            this.loadPatient(location.query.patientId);
        } else {
            Analytics.startCreatePatient();
            this.loadCustomizations();
        }

        NutritionPatternActions.load();
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        // Did the user click the back button? If the page query param exists
        // and doesn't match our index, update our index to match.

        let { page } = nextContext.location.query

        page = parseInt(page) || 0;

        if (this.state.index != page) {
            this.setState({index: page});
        }
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    loadCustomizations = () => {
        const { user } = this.state;
        this.setState({ disableSave: true });

        if (!user) {
            return;
        }

        const keys = [
            'preferences::recommendation-mode-source', 'preferences::inhibit-add-swap-modes',
            'preferences::default-add-swap-mode', 'preferences::breakfast::max-cost-per-serving',
            'preferences::lunch::max-cost-per-serving', 'preferences::dinner::max-cost-per-serving',
            'preferences::snack::max-cost-per-serving', 'preferences::energy-method', 'preferences::equipment',
            'preferences::prefer-milks', 'preferences::prefer-rices', 'preferences::leftovers-enabled',
            'preferences::max-leftover-days', 'preferences::inhibit-change-avoidances',
            'preferences::inhibit-change-nutrition-profile', 'preferences::inhibit-plan-generator',
            'preferences::portion-resolution', 'preferences::hide-ewg'
        ];

        AuthStore.fetch(getConfig('users_api') + '/customizations', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=custom/keys/1'},
            body: JSON.stringify({keys}),
        }).then(
            response => {
                const customizations = {};

                response.elements.forEach(custom => {
                    if (custom.value) {
                        let  sanitisedKey = custom.key.split("::");
                        sanitisedKey.splice(0, 1);
                        sanitisedKey = sanitisedKey.join('_').replaceAll('-', '_');
                        customizations[sanitisedKey] = custom.value;
                    }
                });
                this.setState({ customizations });
                this.updatePatientWithCustomizations(customizations);
            },
            error => false, // if it failed nothing we can do. Let it go.
            ).finally(() => {
                this.setState({ disableSave: false });
        })
    }

    translateTruthy(value) {
        const lowerValue = String(value).toLowerCase();
        const trueValues = ['y', 'yes', 'true', '1', 'x', 'on'];
        const falseValues = ['n', 'no', 'false', '0', '-', 'off'];

        if (trueValues.includes(lowerValue)) {
            return true;
        } else if (falseValues.includes(lowerValue)) {
            return false;
        }

        return Boolean(value);
    }


    updatePatientWithCustomizations = (customizations) => {
        const { patient } = this.state;

        const customizedPreferences = {};

        if (customizations['recommendation_mode_source']) {
            try {
                patient.recommendation_mode_source = JSON.parse(customizations['recommendation_mode_source']);
            } catch (exp) {
                // in case the JSON is bad
            }
        }

        if (customizations['energy_method']) {
            patient.energy_method = customizations.energy_method;
        }

        if (customizations.default_swap_mode) {
            patient.default_swap_mode = customizations['default_add_swap_mode'];
        }

        ['breakfast_max_cost_per_serving',
         'max_leftover_days',
         'lunch_max_cost_per_serving',
         'snack_max_cost_per_serving',
         'dinner_max_cost_per_serving'].forEach((key) => {
            if (!customizations[key]) {
                return;
            }

            patient.preferences[key] = parseFloat(customizations[key]);
        });

        ['portion_resolution'].forEach((key) => {
            if (!customizations[key]) {
                return;
            }

            patient[key] = parseFloat(customizations[key]);
        });

        ['equipment',
         'prefer_milks',
         'prefer_rices'].forEach((key) => {
            if (!customizations[key]) {
                return;
            }

            patient.preferences[key] = customizations[key].split(",");
        });

        ['inhibit_add_swap_modes'].forEach((key) => {
            if (!customizations[key]) {
                return;
            }

            patient[key] = customizations[key].split(",");
        });

        ['leftovers_enabled'].forEach((key) => {
            if (!customizations[key]) {
                return;
            }

            patient.preferences[key] = this.translateTruthy(customizations[key]);
        });

        ['hide_ewg',
         'inhibit_change_avoidances',
         'inhibit_change_nutrition_profile',
         'inhibit_plan_generator'].forEach((key) => {
            if (!customizations[key]) {
                return;
            }

            patient[key] = this.translateTruthy(customizations[key]);
        });

        this.setState({patient});
    }

    onUserStoreChange = () => {
        this.setState({user: UserStore.getUser()});
    }

    loadPatient = (patientId) => {
        this.setState({loading: true});

        AuthStore.fetch(UserStore.getLinks().patients + '/' + patientId + '?embed=notifications').then(
            response => {
                this.setState({patient: response, loading: false});
            },
            error => {
                this.setState({patientNotFound: true, loading: false});
            }
        );
    }

    savePatient = (patient) => {
        const mylinks = UserStore.getLinks();

        const url = (patient.links && patient.links.self)
                  ? (getConfig('users_api') + patient.links.self.href)
                  : mylinks.patients;

        // Omit the stuff from the patient record that's read-only. E.g.: we can't change the uuid
        const {
            uuid, links, created, invite_sent, invite_accepted,
            recommendations, active, activated_date, deactivated_date,
            ...rest
        } = patient;

        const postBody = {...rest};

        return new Promise((accept, reject) => {
            AuthStore.fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; schema=patient/1',
                },
                body: JSON.stringify(postBody),
            }).then(
                response => {
                    accept(response);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    setStoredPlans = (patient, virtual, lastGenParams) => {
        const user = UserStore.getUser();
        const expires = moment().add(7, 'day').format();
        const key = ['patient-recommended-plans', patient.uuid, user.uuid].join('-');

        virtual = virtual.map(p => p.uuid);

        LocalStorage.set(key, {virtual, expires, lastGenParams});
    }

    populatePatientPlans = (patient) => {

        const genParams = getParamsForProfile(patient);

        // Stringify before we start mucking with the parameters
        const lastGenParams = JSON.stringify(genParams);

        return new Promise((accept, reject) => {
            AuthStore.fetch(getConfig('recipe_api') + '/plan-generator', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=vplan/parameters/2'},
                body: JSON.stringify(genParams)
            }).then(
                results => {
                    // No results back?
                    if (!(results && results.elements && results.elements.length)) {
                        return reject('Unable to build a meal plan that fits these nutrition parameters');
                    }

                    let virtual = processVirtualPlans(results.elements);

                    this.setStoredPlans(patient, virtual, lastGenParams);

                    accept(virtual);
                },
                error => reject(error)
            );
        });
    }

    navigateToPatient = (patient) => {
        const { router } = this.context;
        router.push({pathname: `/clients/${patient.uuid}`})
    }

    reactivatePatient = (patient) => {
        const { confirm } = this.context;
        let { user } = this.state;

        AuthStore.fetch(getConfig('users_api') + `/practices/${user.practice.uuid}/patients/${patient.uuid}/activate`, {method: 'POST'})
                 .then(
            success => {
                this.navigateToPatient(patient);
            },
            error => {
                if (error && error.message === 'Patient limit reached') {
                    const { showUpgradeForm } = this.context;

                    showUpgradeForm({feature: 'create_patient'});
                    return;
                }

                confirm(
                    <p>Could not reactivate: {error.message}</p>,
                    () => this.setState({working: false}),
                    () => false,
                    {rejectText: ''}
                );
            }
        );
    }

    nextPage = async (page, index) => {
        const { onSavePatient } = this.props
        let { patient } = this.state;

        if (!page.ref) {
            return;
        }
        const ref = this.refs[page.ref];

        // Validate of the page passed, mutation of patient is complete, go to the next page.
        const error = (ref.validate && ref.validate(false)) || true;

        if (error !== true) {
            this.setState({error});

            return;
        }

        this.setState({working: true});

        // Mutate the patient object with the values from the form.
        patient = await ref.mutate(patient, false);

        // Do we need to skip saving the patient?
        if (page.dont_save) {
            const { router, location } = this.context;
            const { pathname, query, hash } = location;
            query.page = index + 1;

            router.push({pathname, query, hash});
            Analytics.createPatient(patient.uuid, page.stage);
            this.setState({patient, index: index + 1, error: null, working: false, dirty: ref.isDirty && ref.isDirty()});
            return;
        }

        try{
            const response = await this.savePatient(patient)
            const { router, location } = this.context;
            const { pathname, query, hash } = location;
            query.patientId = response.uuid;
            query.page = index + 1;

            router.push({pathname, query, hash});

            Analytics.createPatient(response.uuid, page.stage);
            onSavePatient && onSavePatient(response);
            this.setState({patient: response, working: false, index: index + 1, error: null, dirty: false});

            if (this.scrollable) {
                this.scrollable.scrollTop = 0;
            }
        } catch(error){
            ref.setFieldError && error.field && ref.setFieldError(error.field);
            this.setState({working: false, error: (error && error.message) || error || 'unknown error'});
        }
    }

    prevPage = (page, index) => {
        index--;

        if (index < 0) {
            index = 0;
        }

        const { router, location } = this.context;
        const { pathname, query, hash } = location;
        query.page = index;

        router.push({pathname, query, hash});

        this.setState({index, error: null});

        if (this.scrollable) {
            this.scrollable.scrollTop = 0;
        }
    }

    finish = async (page, index, invite = true) => {
        const { onFinishPatient, onSavePatient } = this.props;
        let { patient } = this.state;
        const { confirm } = this.context;

        if (page.ref) {
            const ref = this.refs[page.ref];

            const error = (ref.validate && ref.validate(true)) || true;

            if (error !== true) {
                this.setState({error});

                return;
            }

            // Mutate the patient object with the values from the form.
            patient = await ref.mutate(patient, true);
        }

        Analytics.createPatient(patient.uuid, 'Finish');

        try {
           this.setState({working: true});
           const response = await this.savePatient(patient);
           onSavePatient && onSavePatient(response);
           if (onFinishPatient) {
               this.setState({generating: true});
               await onFinishPatient(response, this.populatePatientPlans, invite);
           }
        } catch (error) {
           this.setState({generating: false, working: false, error: (error && error.message) || error || 'unknown error'});

           if (error.key === "existing_patient") {
                const existingPatient = error.data;
                const isActive = parseInt(existingPatient.active);

                let confirmationText = `${existingPatient.email} is currently deactivated. Reactivate this ${existingPatient.practice_type === 'dietetics' ? "patient" : "client"} record to use this email address or use a different email address.`;
                let acceptText = 'YES, REACTIVATE';
                let rejectText = 'NO, USE A DIFFERENT EMAIL';

                if (isActive) {
                    confirmationText = `${existingPatient.email} is already associated with an active ${existingPatient.practice_type === 'dietetics' ? "patient" : "client"} record.`
                    acceptText = `VIEW THIS ${existingPatient.practice_type === 'dietetics' ? "PATIENT" : "CLIENT"} RECORD`;
                    rejectText = 'USE A DIFFERENT EMAIL';
                }

                confirm(
                    <p>{confirmationText}</p>,
                    accept => isActive ? this.navigateToPatient(existingPatient) : this.reactivatePatient(existingPatient),
                    reject => false,
                    {acceptText, rejectText}
                );

           }
        }
    }

    getPages = (patient) => {

        return [
            {
                title: <span>Basic Info</span>,
                component: <BasicInfo ref="basic" patient={patient} onChangePatient={this.onChangePatient} showUpgradeForm={this.context.showUpgradeForm}/>,
                skippable: false,
                ref: 'basic',
                stage: 'Basic Info',
            },
            {
                title: (patient?.practice_type === 'dietetics' ? 'About Your Patient' : 'About Your Client'),
                component: <AboutYourPatient showBasicInfo={false} ref="about" patient={patient} onChangePatient={this.onChangePatient} />,
                skippable: false,
                ref: 'about',
                stage: patient?.practice_type === 'dietetics' ? 'About Your Patient' : 'About your Client',
            },
            {
                title: <span>{patient.first_name}&apos;s Family</span>,
                component: <Family ref="family" patient={patient} />,
                ref: "family",
                skippable: true,
                stage: 'Family',
            },
            {
                title: <span>Calculate {patient.first_name}&apos;s Energy Needs</span>,
                component: <EnergyCalc ref="ecalc" patient={patient} />,
                ref: 'ecalc',
                skippable: false,
                stage: 'Energy Needs',
            },
            {
                title: <span>Cooking & Shopping</span>,
                component: <MealPreferences ref="meals" patient={patient} />,
                ref: 'meals',
                skippable: false,
                stage: 'Cooking & Shopping',
            },
            {
                title: patient.practice_type === 'dietetics'
                       ? <span>{patient.first_name}&apos;s Health Condition</span>
                       : <span>{patient.first_name}&apos;s Lifestyle Goal</span>,
                component: <Conditions ref="conditions" patient={patient} originalPatient={patient} />,
                ref: 'conditions',
                skippable: false,
                dont_save: true,
                stage: patient?.practice_type === 'dietetics' ? 'Health Conditions' : 'Lifestyle Goal',
            },
            {
                title: <span>{patient.first_name}&apos;s Diet Preferences <em>(optional)</em></span>,
                component: <DietPreferences ref="diets" patient={patient} />,
                ref: 'diets',
                skippable: true,
                stage: 'Diet Preferences',
            },
            {
                title: <span>{patient.first_name}&apos;s Meal Schedule <em>(optional)</em></span>,
                component: <MealDetails ref="meal-details" patient={patient} />,
                ref: 'meal-details',
                skippable: true,
                stage: 'Meal Details',
            },
            {
                title: patient?.practice_type === 'dietetics'
                       ? <span>{patient?.first_name}&apos;s Nutrition Prescription</span>
                       : <span>{patient?.first_name}&apos;s Nutrient Profile</span>,
                component: <Prescription ref="prescription" patient={patient} />,
                ref: 'prescription',
                skippable: false,
                stage: patient?.practice_type === 'dietetics' ? 'Nutrition Prescription' : 'Nutrient Profile',
            },
        ];
    }

    closeModal = (page) => {
        const { dirty, patient } = this.state;
        const { closeModal } = this.props;

        if (dirty || (page && page.ref && this.refs && this.refs[page.ref] && this.refs[page.ref].isDirty && this.refs[page.ref].isDirty())) {
            this.context.confirm(
                <div className="confirm-discard-changes">
                    <h6>Discard changes?</h6>
                    <p>You&apos;ve made changes to this {patient.practice_type === 'dietetics' ? 'patient' : 'client'}, are you sure you want to discard those changes?</p>
                </div>,
                closeModal,
                () => false,
                {
                    acceptText: 'Discard Changes'
                }
            );

            return;
        }

        closeModal && closeModal();
    }

    onChangePatient = (patient) => {
        this.setState({patient});
    }

    renderModalContent = (pages) => {
        const { user, patient, index } = this.state;
        let { error } = this.state;

        if (error === 'That email is not eligible to be added as a client. Please contact support.') {
            error = (<p>
                <em>{patient.email}</em> is already associated with an existing <em>EatLove PRO</em> account, which
                cannot be used for patient access. Please use a different email address or&nbsp;
                <a href={`mailto:support@eatlove.is?subject=Help With Existing EatLove PRO Account&body=Hello EatLove support, I am having trouble using ${patient.email} to create a client account.`}>contact support</a>&nbsp;
                for assistance.
            </p>);
        }

        if (user.practice_type === 'dietetics' && user.agreement_version != '10.2017') {
            return (
                <Agreement onCancel={this.closeModal} onAccept={() => false} />
            );
        }
        const disableButtons = this.state.disableSave;

        return (
            <div>
                {pages.map((page, i) => {
                    return (
                        <div key={i}>
                            <div className="editor-scrollable" data-active={i === index} ref={el => this.scrollable = el}>
                                <section className="wizard-page" data-active={i === index}>
                                    {i === index ? page.component : null}
                                </section>
                            </div>
                           {i === index ?
                               <footer  className="modal-footer">
                                    <div className="error-msg" data-active={error ? true : false}>{error || '.'}</div>

                                    {i === 0
                                        ? <button className="prev" onClick={() => this.closeModal(page)}>Cancel</button>
                                        : <button className={page.skippable ? "prev-with-skip" : "prev"} onClick={() => this.prevPage(page, i)}>Back</button>
                                    }

                                    {patient && i < (pages.length - 1) ?
                                        <button className="skip" onClick={() => this.finish(page, i)}>Save & Invite</button>
                                        : <button className="skip" onClick={() => this.finish(page, i)}>Finish & Invite</button>}

                                    {i < (pages.length - 1)
                                        ? <button className="next" disabled={disableButtons} onClick={() => this.nextPage(page, i)}>Next</button>
                                        : <button className="next" disabled={disableButtons} onClick={() => this.finish(page, i, false)}>Finish</button>
                                    }

                                    <div className="page-dots">
                                        {Object.keys(Array.apply(null, {length: pages.length})).map(j => {
                                            return <i className="icon-bullet" key={j} data-active={i == j} />
                                        })}
                                    </div>
                                </footer>
                            : null}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderModalHeader = (pages) => {
        const { user, index } = this.state;
        const { capabilities } = user;

        const shouldDisplayTitle = (user.agreement_version === '10.2017' || user.practice_type !== 'dietetics') &&
                                    capabilities?.create_patient;

        return (
            <header className="modal-header">
                { shouldDisplayTitle ? <h2>{pages[index].title}</h2> : null }
                <button className="close-btn" onClick={() => this.closeModal(pages[index])}>
                    <i className="icon-close-x" />
                    <span className="assistive-text">Close Modal</span>
                </button>
            </header>
        );
    }


    render() {
        const { loading, patientNotFound, working, generating, patient, index } = this.state;

        // @todo - render a proper loading screen here
        if (loading || patientNotFound) {
            return <span />
        }

        const pages = this.getPages(patient);

        const nutrRxName = patient.practice_type === 'dietetics' ? 'nutrition prescription' : 'nutrient profile';

        return (
            <Modal isOpen={true}
                onRequestClose={() => this.closeModal(pages[index])}
                closeModal={() => this.closeModal(pages[index])}
                className="create-patient-modal editor-modal"
                contentLabel={patient.practice_type === 'dietetics' ? "Create Patient" : 'Create Client'}
                style={modalStyles.largeSquareModal}
                overlayClassName="editor-modal-overlay"
                closeTimeoutMS={250}>

                <div className="create-patient-container editor-modal-container" data-working={working || generating}>
                    {this.renderModalHeader(pages)}
                    {this.renderModalContent(pages)}

                    {working ?
                        <Working title={`Saving ${patient.first_name}`}
                            message={`Saving ${patient.first_name}’s preferences and nutrition prescription`} />
                    : null}

                    {generating ?
                        <Working title={`Just for ${patient.first_name}`}
                            message={`Generating meal plans based on ${patient.first_name}’s preferences and ${nutrRxName}`} />
                    : null}
                </div>
            </Modal>
        );
    }
}
