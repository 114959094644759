'use strict';

import diets from './diets';

export default {
    mealTypes: {
        title: 'Meal Types',
        tags: [
            'Breakfast',
            'Breakfast Side Dish',
            'Lunch',
            'Lunch Side Dish',
            'Dinner',
            'Side Dish',
            'Snack',
            'Appetizer',
            'Soup',
            'Salad',
            'Condiment/Sauce',
            'Bread',
            'Dessert',
            'Beverage',
            'Sandwich',
            'Baked Goods',
            'Baby Food',
            'Toddler',
            'No Leftovers',
            'Kid Friendly',
            'No Cook',
            'Grab & Go',
            'Ready Made Meal',
            'Frequently Used',
            'Breakfast Meal Kit Side',
            'Breakfast Ready Made Side',
            'Lunch Meal Kit Side',
            'Lunch Ready Made Side',
            'Dinner Meal Kit Side',
            'Dinner Ready Made Side',
        ],
    },
    equipment: {
        title: 'Equipment',
        tags: [
            'Oven',
            'Stovetop',
            'Grill',
            'Slow Cooker',
            'Microwave',
            'Blender',
            'Dehydrator',
            'Mixer',
            'Food Processor',
            'Spiralizer',
            'Coffee Grinder',
            'Instant Pot',
            // 'No Cook',
            'Air Fryer',
        ]
    },
    skill: {
        title: 'Skill Level',
        tags: [
            'Beginner',
            'Intermediate',
            'Advanced',
        ],
    },
    cuisine: {
        title: 'Cuisine',
        tags: [
            'American',
            'Chinese',
            'French',
            'Greek',
            'Indian',
            'Italian',
            'Japanese',
            'Korean',
            'Latin',
            'Mexican',
            'Lebanese',
            'Middle Eastern',
            'Morrocan',
            'Russian',
            'Spanish',
            'Thai',
            'Vietnamese',
            'Jewish',
            'Filipino',
            'Caribbean',
            'Southern USA',
            'Asian',
            'Mediterranean',
        ],
    },

    diets: {
        title: 'Diets',
        tags: diets.filter(d => !d.disabled).map(diet => diet.name),
    },

    season: {
        title: 'Season',
        tags: [
            'Spring',
            'Summer',
            'Fall',
            'Winter',
            'Thanksgiving',
            'Christmas',
            'Easter',
        ],
    },
    misc: {
        title: 'Miscellaneous',
        tags: [
            'No Cook',
            'Freezes Well',
            'One Pot Meal',
            'Quick & Easy',
            'Budget Friendly',
            'Authentic Cuisine',
            'Healthy Choice',
            'Thanksgiving',
            'Foundational',
            'Contains Foundational',
            'Cook Once Eat Twice',
            '3+ Meals 1 Grocery Bag',
        ],
    },

    recipeMisc: {
        title: 'Miscellaneous',
        tags: [
            'Low FODMAP',
            'Kid Friendly',
            'Freezes Well',
            'One Pot Meal',
            'Quick & Easy',
            'Pantry Friendly',
            'Supplement',
            'Trader Joe\'s',
            'Starter Cook',
            'Bariatric Friendly',
            'Low Histamines'
            // 'Basic',
            // 'Exclude from Virtual',
            // 'Exclude from Weekly',

            // 'Bariatric Stage 4',
            // 'Bariatric Stage 5',

            // 'Do Not Publish',
        ],
    },

    planMisc: {
        title: 'Themes',
        tags: [
            //'Cook Once Eat Twice',
            //'3+ Meals 1 Grocery Bag',
            'Kid Friendly',
            'Quick & Easy',
            'Budget Friendly',
            'Authentic Cuisine',
            'Cooking for a Crowd',
        ],
    },

    foodMisc: {
        title: 'Food Tags',
        tags: [
            'NOPREG',
            'BUYORG',

            'Gluten Free Product',
            'Milk Alternative',
            'Pb Alternative',
            'Yogurt Alternative',
            'Low FODMAP ingredient',
            'Keto ingredient',
            'Polarizing Ingredient',
            'Cow (Whole)',
            'Cow (Low Fat)',
            'Cow (Skim)',
            'Unavoidable',
            'Almond Milk',
            'Rice Milk',
            'Coconut Milk',
            'Soy Milk',
            'Oat Milk',
            'Hemp Milk',
            'Cashew Milk',
            'A2 Milk',
            'White Rice',
            'Brown Rice',
            'Basmati Rice',
            'Wild Rice',
            'Arborio Rice',
            'Jasmine Rice',
            'Black Rice',
            'Refined Grains',
            'Primary Starch',
            'Supplement',
            'Liquid',
            'Do Not Publish',
            'Needs Review',
            'Rejected',
            'Duplicate',
            'Missing Food Label',
            'Missing Packaging',
            'Trader Joe\'s',
            'Lock Taxonomy',
            'Live Ingredient',
            'Approved Live Ingredient',
            'Permutation',
            'Not a Smart Choice',

            // 'AI Scanned Product Title',
            // 'AI Scanned Brand Name',
            'AI Scanned Ingredients',
            'AI Scanned Serving Size',
            'AI Scanned Nutrition',
            'AI Scanned Package',
            'AI Estimated Exchanges',
            'AI Pretty Name'
        ],
    },

    foodReadyState: {
        title: 'Food Ready State',
        tags: [
            'Missing Barcode',
            'Missing Brand',
            'Missing Calories',
            'Missing Added Sugar',
            'Missing Default Unit',
            'Missing FODMAPs',
            'Missing Grams/serving',
            'Missing Grocery Category',
            'Missing Ingredients List',
            'Missing Ml/serving',
            'Missing Packaging',
            'Missing Price',
            'Missing Serving Description',
            'Missing Serving Unit',
            'Missing Units',
            'Missing Density',
            'Missing Label Nutrients',

            'Missing Fruit Servings',
            'Missing Vegetable Servings',
            'Missing Whole Grains Servings',
            'Missing Healthy Fats Servings',
            'Missing Healthy Proteins Servings',
            'Missing Dairy Servings',
            'Missing Leafy Greens Servings',
            'Missing Berries Servings',
            'Missing Nuts/Seeds Servings',
        ],
    },

    article: {
        title: 'Article Tags',
        tags: [
            'Entrepreneurship',
            'Fitness Motivation',
            'Fitness Tips',
            'Healthy Lifestyle',
            'Inspiration',
            'Life Lessons',
            'Motivation',
            'Nutrition',
            'Self Improvement',
            'Startup',
            'Success Story',
            'Writing',
        ],
    }
};
