'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import AuthStore from '@stores/AuthStore';
import { getConfig } from '@utils/Env';
import MacrosPieChartV2 from '@components/Widgets/MacrosPieChartV2.react';
import { getPrescriptionsFromProfile } from '@pro/utils/Patients';
import { isEmpty } from 'lodash';
import NutritionPatternStore from '@stores/NutritionPatternStore';
import allNutrients from '@tables/nutrients';
import { roundForHumans } from '@utils/Math';
import './Coachmark.scss';
import '../../FeedModals.scss';

export default class Coachmark extends Component {
    static propTypes = {
    };

    static contextTypes = {
        isMobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        let pattern = {};
        const { conditions } = props.user;
        const nutritionPatterns = NutritionPatternStore.getNutritionPatterns();

        if (!isEmpty(conditions) && !isEmpty(nutritionPatterns)) {
            pattern = nutritionPatterns.find((pattern) => pattern.uuid === conditions[0].uuid);
        }

        this.state = {
            drawer: false,
            quickTour: false,
            loading: false,
            pattern,
        };
    }

    componentDidMount = () => {
        this.setState({drawer: true});
        this.loadPattern();
    }

    loadPattern = async () => {
        const { pattern } = this.state;
        const { user: { conditions }} = this.props;
        let nutritionPattern = {};

        if (isEmpty(pattern) && !isEmpty(conditions)) {
            this.setState({loading: true});

            nutritionPattern = await AuthStore.fetch({
                url: getConfig('users_api') + '/nutrition-patterns'+ `/${conditions[0].uuid}`
            });
            
            this.setState({pattern: nutritionPattern, loading: false});
        }
    }

    closeModal = () => {
        this.setState({drawer: false}, () => setTimeout(this.props.closeModal, 333));
    }

    openQuickTour = () => {
        this.setState({quickTour: true});
    }

    closeQuickTour = () => {
        this.setState({quickTour: false});
    }

    renderContent = () => {
        const { pattern } = this.state;
        const { user } = this.props;
        const { isMobile } = this.context;
        const { conditions, hide_nutrition } = user;
        const { macros } = getPrescriptionsFromProfile(user);

        const focusedNutrients = pattern.focused_nutrients.map((nutrNo) => {
            const name = allNutrients[nutrNo].NutrDesc
            const value = user.preferences?.daily_totals[nutrNo]?.min || user.preferences?.daily_totals[nutrNo]?.max;
            const unit = allNutrients[nutrNo].Units;
            return {nutrNo, name, value, unit};
        });

        return (
            <>
                <div className="separator">
                    <div className="your-prescription">YOUR PRESCRIPTION</div>
                </div>

                <div className="description">
                    <div>
                        <h3>{conditions[0].name}</h3>
                        {pattern && pattern.description ? <p>{pattern.description}</p> : null}
                    </div>
                    {!isMobile ?
                        <button className="el-btn el-medium-btn el-orange-btn el-wide-btn" onClick={this.closeModal}>Start Your Plan</button>
                        : null
                    }
                </div>

                <div className="separator"></div>

                {!hide_nutrition && !isEmpty(macros) && !isEmpty(focusedNutrients) ?
                    <>
                        <h5>Your focus</h5>
                        <div className="nutrients">
                            <MacrosPieChartV2 nutrients={macros} focusedNutrients={focusedNutrients.filter((n) => n.value)} />
                        </div>
                        <div className="separator"></div>
                    </>
                : null}

                {pattern && pattern.guidelines ? <div className="what-to-know">
                    <h5>WHAT TO KNOW</h5>
                    <ReactMarkdown source={pattern.guidelines} />
                </div> : null}
            </>
        )
    }

    renderWelcomeScreen = () => {
        const { drawer, pattern, quickTour } = this.state;
        const { user } = this.props;
        const { isMobile } = this.context;
        const { first_name } = user;

        return (
            <Modal isOpen={!quickTour}
                closeModal={() => false}
                onRequestClose={() => false}
                className={drawer ? "feed-coachmark-modal open" : "feed-coachmark-modal"}
                overlayClassName="feed-modal-overlay"
                contentLabel="EatLove coachmark"
                closeTimeoutMS={250}>
                <div className="feed-coachmark-modal-container">
                    <h1><em>{first_name}</em>, Welcome to <img src="https://static.chewba.info/images/header-logo-white-2017-07-03.png" /></h1>
                    <div className="feed-coachmark feed-coachmark-styles" onClick={ev => ev.stopPropagation()}>
                        {isEmpty(pattern) ? <span className="coachmark-loader"><i className="icon-spinner2" /></span> : this.renderContent()}
                    </div>
                    {!isEmpty(pattern) ?
                        <div className="iframe-container" onClick={this.openQuickTour}>
                            <div className="title">Take a quick tour</div>
                            <div class="iframe-overlay"></div>
                            <iframe src="https://fast.wistia.net/embed/iframe/umjzbju9zv?seo=false&videoFoam=true" frameBorder="0"></iframe>
                        </div> 
                    : null}
                    {isMobile && !isEmpty(pattern) ?
                        <footer>
                            <button className="el-btn el-large-btn el-raspberry-btn el-btn-icon-left" onClick={this.openQuickTour}>
                                <i className="icon-right-arrow" />
                                TOUR
                            </button>
                            <button className="el-btn el-large-btn el-orange-btn el-wide-btn" onClick={this.closeModal}>Start Your Plan</button>
                        </footer>
                        : null
                    }
                </div>
            </Modal>
        );
    }

    renderQuickTour = () => {

        const { quickTour } = this.state;
        const { isMobile } = this.context;

        if (!quickTour) {
            return;
        }

        return (
            <Modal isOpen={true}
                closeModal={this.closeQuickTour}
                onRequestClose={this.closeQuickTour}
                className={"feed-coachmark-modal"}
                overlayClassName="feed-modal-overlay"
                contentLabel="EatLove coachmark"
                closeTimeoutMS={250}>
                <div className="feed-coachmark-modal-container quick-tour">
                    <header>
                        <h1>Take a Quick Tour</h1>
                        <button className="close-btn" onClick={this.closeQuickTour}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>
                    
                    <div className="video-container">
                        <iframe src="https://fast.wistia.net/embed/iframe/umjzbju9zv?seo=false&videoFoam=true" frameBorder="0" allowFullScreen allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>

                    <footer>
                        <button className="el-btn el-large-btn el-orange-btn el-wide-btn" onClick={this.closeModal}>Start Your Plan</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    render = () => {
        const { pattern } = this.state;

        return (
            <>
                {this.renderWelcomeScreen()}
                {this.renderQuickTour()}
            </>
        )
    }

}
