'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import UserStore from '../../../../../stores/UserStore';
import NutrientFilters from '@components/Search/Filters/NutrientFilters.react';

import './FiltersModal.scss';

export default class FiltersModal extends Component {
    static propTypes = {
        params: PropTypes.object.isRequired,
        onChangeParams: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            // Make a deep copy of the original parameters
            initialParams: JSON.parse(JSON.stringify(props.params)),
        };
    }

    revertAndClose = () => {
        const { onChangeParams, closeModal } = this.props;
        const { initialParams } = this.state;

        onChangeParams(initialParams);
        closeModal();
    };

    toggleRadio = (tag, tagGroup) => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};
        params.filters.tags = params.filters.tags || [];

        // Remove all tags from tagGroup
        let tagGroupTags = tagGroup.map(({ tag } ) => tag);
        params.filters.tags = params.filters.tags.filter(t => !tagGroupTags.includes(t));

        // Push the new tag back on it.
        if (params.filters.tags.includes(tag)) {
            params.filters.tags = params.filters.tags.filter(t => t !== tag);
        } else {
            params.filters.tags.push(tag);
        }

        onChangeParams(params);
    };

    toggleTag = (tag) => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};
        params.filters.tags = params.filters.tags || [];

        if (params.filters.tags.includes(tag)) {
            params.filters.tags.splice(params.filters.tags.indexOf(tag), 1);
        } else {
            params.filters.tags.push(tag);
        }
        onChangeParams(params);
    }

    render() {
        const { params, onChangeParams, closeModal } = this.props;
        const { hide_nutrition = false } = UserStore.getUser();

        let tags = (params && params.filters && params.filters.tags) || [];

        const mealTypes = [
            { tag: 'Breakfast' },
            { tag: 'Lunch' },
            { tag: 'Main Dish', label: 'Dinner' },
            { tag: 'Snack' },
        ];

        const dishTypes = [
            {tag: 'Appetizer'},
            {tag: 'Beverage'},
            {tag: 'Bread'},
            {tag: 'Condiment/Sauce'},
            {tag: 'Dessert'},
            {tag: 'Salad'},
            {tag: 'Sandwich'},
            {tag: 'Soup'},
        ];

        return (
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                contentLabel="Add/Swap Recipe"
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal2-container restaurant-menu-filters-modal">
                    <header>
                        <h2>Filter Results</h2>

                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal2-body-container">
                        <div className="with-label">
                            <label>Meal</label>

                            <ul className="toggle-group">
                                {mealTypes.map(({ tag, label }, i) => (
                                    <li key={i}>
                                        <button
                                            className="el-radio-btn"
                                            data-active={tags.includes(tag)}
                                            onClick={() => this.toggleRadio(tag, mealTypes)}
                                        >
                                            {label || tag}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="with-label">
                            <label>Dish Type</label>

                            <ul className="toggle-group">
                                {dishTypes.map(({ tag, label }, i) => (
                                    <li key={i}>
                                        <button
                                            className="el-checkbox"
                                            data-active={tags.includes(tag)}
                                            onClick={() => this.toggleTag(tag, dishTypes)}
                                        >
                                            {label || tag}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {!hide_nutrition ? (
                            <NutrientFilters
                                params={params}
                                onChangeParams={onChangeParams}
                                nutrientWhitelist={['208', '203', '204', '205', '307']}
                            />
                        ) : null}
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.revertAndClose}>
                            Cancel
                        </button>
                        <button className="el-modal-ok-btn" onClick={closeModal}>
                            Apply
                        </button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
