'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import CollectionViewer from '../../Collections/Viewer.react';

import '../../FeedModals.scss';
import './CollectionDetailsModal.scss';

export default class CollectionDetailsModal extends Component {

    static propTypes = {
        modalTitle: PropTypes.string,
    };

    static defaultProps = {
        modalTitle: 'Collection',
    };

    static contextTypes = {
        isMobile: PropTypes.bool,
    };

    render() {
        const { modalTitle, closeModal } = this.props;
        const { isMobile } = this.context;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Search for an ingredient"
                className="search-foods-modal"
                overlayClassName="feed-modal-overlay search-foods-modal-overlay"
                closeTimeoutMS={250}>

                <div className="feed-modal-container" onClick={closeModal}>
                    <header onClick={(ev) => ev.stopPropagation()}>
                        <div className="top-half-circle">&nbsp;</div>
                        <div className="header-container">
                            <button className="close-modal-btn" onClick={closeModal}>
                                <i className="icon-chevron-left" />
                                {!isMobile ? <span>{modalTitle}</span> : null}
                            </button>

                            {isMobile ? <h1>{modalTitle}</h1> : null}
                        </div>
                    </header>

                    <div className="modal-scroll-container" onClick={(ev) => ev.stopPropagation()}>
                        <section className="inner-slider">
                            <CollectionViewer {...this.props} />
                        </section>
                    </div>
                </div>
            </Modal>
        );
    }
}
