'use strict';

import React from 'react';
import { Component } from 'react';
import './Tabs.scss';

export default class Tabs extends Component {
    static propTypes = {
    };

    static defaultProps = {
        className: 'generic-tabs',
    };

    constructor(props) {
        super(props);

        const tab = props.defaultTab || this.getFirstTabName(props.children)

        this.state = {
            tab,
        };
    }

    getFirstTabName(children) {
        const tabTitles = children.map((child, key) => {
            if (!(child && child.props['data-title'])) {
                return null;
            }

            return child.props['data-title'];
        }).filter(v => v);

        return tabTitles[0];
    }

    setTab = (tab) => {
        this.setState({tab});
    }

    render() {
        const { tab } = this.state;
        const { children, className, tabsTitle, onClickAddTab, buttonAddTab } = this.props;

        const normalizeChild = (child) => {
            if (child?.type !== 'section') {
                return child;
            }

            const { 'data-title': dataTitle, ...otherProps } = child.props;
            return React.cloneElement(child, {
                key: dataTitle,
                'data-title': dataTitle,
                ...otherProps,
            });
        };

        const tabsArray = React.Children.map(children, normalizeChild);

        return (
            <div className={className}>
                <header>
                    <section className="tabs" data-tab-state={tab} data-has-title={tabsTitle ? true : false}>
                        {tabsTitle ? <h2>{tabsTitle}</h2> : null}
                        {tabsArray.map((child, key) => {
                            if (!(child && child.props['data-title'])) {
                                return null;
                            }

                            const tabTitle = child.props['data-title'];
                            const isActiveTab = (tabTitle === tab);

                            return (
                                <div key={key} className={isActiveTab ? "tab-btn active-tab-btn" : "tab-btn"} data-tab={tabTitle}
                                    onClick={() => this.setState({tab: tabTitle})}>
                                    {tabTitle}
                                    {child.props['onClickRemove'] && isActiveTab ?
                                        <button className="remove-tab-btn" onClick={(ev) => {ev.stopPropagation(); child.props['onClickRemove'](ev);}}>
                                            <i className="icon-close-x" />
                                        </button>
                                    : null}
                                </div>
                            );
                        })}

                        {!!buttonAddTab ? buttonAddTab : null}

                        {!buttonAddTab && onClickAddTab ?
                            <button className="add-tab-btn" onClick={onClickAddTab}><i className="icon-plus-thick" /></button>
                        : null}
                    </section>
                </header>

                <section className="tab-contents" data-tab-state={tab}>
                    {tabsArray.map((child, key) => {
                        if (!(child && child.props['data-title'])) {
                            return null;
                        }

                        const tabTitle = child.props['data-title'];

                        return (
                            <div key={key} className={tabTitle === tab ? 'active' : 'inactive'}>
                                {tabTitle === tab || child.props['data-persistent'] ? child : null}
                            </div>
                        );
                    })}
                </section>
            </div>
        );
    }
}
