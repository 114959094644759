'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import store from 'store';
import debounce from 'lodash.debounce';
import UserActions from '../../../actions/UserActions';
import { profileKeyComparator } from '../../../pro/utils/Patients';
import Analytics from '../../../utils/Analytics';
import MealActions from '../../../actions/MealActions';
import moment from 'moment';
import '../../Widgets/header/HeaderDropdown.scss';
import './PrefsDropdown.scss';

const LOCALSTORE_KEY = 'inhibit_profile_analysis';

export default class PrefsDropdown extends Component {
    static propTypes = {
        onRefreshMealFeed: PropTypes.func
    };

    static defaultProps = {
        defaultClassName: 'pro-dropdown-container',
    };

    static contextTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        meals: PropTypes.array,
    };

    constructor(props, context) {
        super(props, context);

        const { user: { inhibit_autopopulate } } = this.context;

        this.state = {
            isModalOpen: false,
            isProfileChanged: false,
            inhibitAutoPopulate: inhibit_autopopulate
        };

        this.debounceSyncAssets = debounce(this.syncAssets, 750);

        // Don't re-run analysis for a half second after inhibited
        this.resetInhibitAnalysis = debounce(this.resetInhibitAnalysis, 500);
    }

    componentDidMount = () => {
        this.debounceSyncAssets();
    }

    inhibitAnalysis = false

    resetInhibitAnalysis = () => {
        this.inhibitAnalysis = false;
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const { inhibitAutoPopulate } = this.state;
        const nextValue = nextContext.user.inhibit_autopopulate;

        if (inhibitAutoPopulate !== nextValue) {
            this.setState({inhibitAutoPopulate: nextValue});

            if (nextValue) {
                this.setState({showDisableAutoRecommendModal: true});
            } else {
                this.setState({reasons: 'Enable recommend meals'}, this.onRefreshMealFeed);
            }
        }

        this.debounceSyncAssets();
    }

    syncAssets = () => {
        const { user, location } = this.context;
        const { capabilities = {} } = user || {};

        if (location.pathname !== '/meals') {
            this.setState({isModalOpen: false});
            return;
        }

        if (!user || this.inhibitAnalysis || store.get(LOCALSTORE_KEY)) {
            return;
        }

        if (user.inhibit_autopopulate || !capabilities.meal_planner) {
            return;
        }

        const { profile_key = {} } = user;

        const { isProfileChanged, reasons } = profileKeyComparator(user, profile_key);
        const isModalOpen = isProfileChanged;

        if (isModalOpen && JSON.stringify(this.state.reasons) !== JSON.stringify(reasons)) {
            this.openModal(reasons);
            Analytics.promptToRegenerateMealFeed({Reasons: reasons});
        }
    }

    closingTimeout = null;

    closeModal = (reasons) => {
        const { onHideDropdown } = this.props;

        this.setState({isModalOpen: false, reasons});
        onHideDropdown && onHideDropdown();
    }

    openModal = (reasons) => {
        const { onShowDropdown } = this.props;


        this.setState({isModalOpen: true, reasons});
        onShowDropdown && onShowDropdown();
    }

    onRefreshMealFeed = async () => {
        const { onRefreshMealFeed } = this.props;
        const { reasons } = this.state;
        this.inhibitAnalysis = true;

        this.closeModal();
        Analytics.changeMeals({Reasons: reasons});

        try {
            await onRefreshMealFeed();
            Analytics.changeMealsCompleted({Reasons: reasons});
        } catch (error) {
            Analytics.changeMealsError({Reasons: reasons, Error: error?.message || error});
        }

        this.resetInhibitAnalysis();
    }

    onClickCancel = () => {
        const { reasons } = this.state;
        this.inhibitAnalysis = true;
        this.closeModal();

        // Update the users profile key to their current profile and reset our inhibitor flag
        UserActions.syncProfileKey().then(this.resetInhibitAnalysis);
        Analytics.keepMeals({Reasons: reasons});
    }

    onKeepRecommendedMeals = () => {
        this.setState({reasons: 'disable auto populate and keep recommended meals', showDisableAutoRecommendModal: false}, this.onRefreshMealFeed);
    }

    onRemoveRecommendedMeals = () => {
        const { meals } = this.context;
        const mealsToDelete = meals.filter((meal) => meal.source === 'recommendations' && moment(meal.date) > moment());
        MealActions.deleteMeals(mealsToDelete);
        this.setState({reasons: 'disable auto populate and remove recommended meals', showDisableAutoRecommendModal: false}, this.onRefreshMealFeed);
    }

    renderDisableAutoRecommendModal = () => {
        const { showDisableAutoRecommendModal } = this.state;

        if (!showDisableAutoRecommendModal) {
            return;
        }

        return (
            <Modal
                isOpen={showDisableAutoRecommendModal}
                onRequestClose={() => false}
                closeModal={() => false}
                contentLabel="Confirm Disable Auto Recommend"
                className="el-modal el-modal3 el-modal3-centered disable-auto-recommend"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}
            >
                <section className="el-modal-container el-modal3-container">
                    <div className="el-modal-body-container el-modal3-body-container el-fonts">
                        <p>Auto-recommendations are now off.</p>
                        <h3>Would you like to keep your existing suggested meals?</h3>
                        <p>If you clear the meals, you'll lose your current suggestions. If you've already bought groceries, <em>this cannot be undone</em>.</p>
                    </div>
                    <footer>
                        <button className="el-modal-ok-btn" onClick={this.onKeepRecommendedMeals}>
                            Yes, Keep My Meals
                        </button>
                        <button className="el-modal-cancel-btn" onClick={this.onRemoveRecommendedMeals}>
                            No, Clear My Meals
                        </button>
                    </footer>
                </section>
            </Modal>
        )
    }

    render = () => {
        const { isModalOpen } = this.state;
        const { onClick } = this.props;

        return (
            <>
                <button className="preferences-btn el-toolbar-btn" onClick={onClick}>
                    <i className="icon-settings" />
                    <span>Preferences</span>
                </button>

                <Modal isOpen={isModalOpen}
                    onRequestClose={this.onClickCancel}
                    closeModal={this.onClickCancel}
                    contentLabel="Profile doesn't match Meal Plan"
                    className="preferences-mismatch-modal"
                    overlayClassName="feed-modal-overlay"
                    closeTimeoutMS={250}>

                    <div className="preferences-mismatch-modal-container">
                        <h1>Your preferences have been changed.</h1>
                        <h2>You're about to change the meals you have already planned.</h2>
                        <p>Do you want to change your meal selections to reflect your new preferences or keep them as planned? You can't undo this action.</p>

                        <footer className="prefs-dropdown-footer">
                            <button className="ok-btn" onClick={this.onRefreshMealFeed}>CHANGE MY MEALS</button>
                            <button className="cancel-btn" onClick={this.onClickCancel}>KEEP MY MEALS AS PLANNED</button>
                        </footer>
                    </div>
                </Modal>

                {this.renderDisableAutoRecommendModal()}
            </>
        );
    }
}
