'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import indexBy from 'lodash.indexby';
import { Link } from 'react-router';
import FoodUnitsSelector from '../../Foods/FoodUnitsSelector.react';
import FoodEditorModal from '../../Foods/Modals/FoodEditorModal';
import RecipeEditor from '../../Recipes/Editor.react';
import { roundForHumans } from '../../../utils/Math';
import { getNutrientsForMeals, nutrNoSortCmp } from '../../../utils/Nutrition';
import { getPrimaryMeal } from '../../../utils/Meals';
import { getDefaultPortionsForContents } from '../../../utils/Content';
import { getNutrientsToDisplay } from '../../../pro/utils/Patients';
import allNutrients from '../../../tables/nutrients';
import './LogPortionsModal.scss';
import RecipeEditorModal from '../../Recipes/Modals/RecipeEditorModal.react';

export default class LogPortionsModal extends Component {
    static propTypes = {
        contents: PropTypes.array,
        profile: PropTypes.object,

        defaultPortions: PropTypes.array,

        requirePrecise: PropTypes.bool,

        modalTitle: PropTypes.node,
        ctaText: PropTypes.node,

        closeModal: PropTypes.func.isRequired,
        onSelectPortions: PropTypes.func.isRequired,
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    static defaultProps = {
        requirePrecise: true,
        modalTitle: 'How much did you eat?',
    };

    constructor(props) {
        super(props);

        this.state = {
            portions: getDefaultPortionsForContents(props.contents, props.profile, props.defaultPortions),
            nutrients: {},
            titles: [],
            disableSaveBtn: null,
        };
    }

    componentDidMount = () => {
        this.syncAssets();
    };

    openFoodEditorModal = () => {
        this.setState({ openFoodEditor: true });
    };

    closeFoodEditorModal = () => {
        this.setState({ openFoodEditor: false });
    };

    openRecipeEditorModal = () => {
        this.setState({ openRecipeEditor: true });
    };

    closeRecipeEditorModal = () => {
        this.setState({ openRecipeEditor: false });
    };

    syncAssets = () => {
        const { portions } = this.state;
        const { profile } = this.props;

        const contents = indexBy(this.props.contents, 'uuid');
        const meals = Object.values(portions);

        const { primary, content, titles } = getPrimaryMeal(meals, contents, contents);

        let nutrients = getNutrientsForMeals(meals, contents, profile?.portion || 1, null);

        this.setState({ nutrients, primary, content, titles });
    };

    logPortions = () => {
        const { contents, onSelectPortions } = this.props;
        const { portions } = this.state;

        let invalid = false;
        contents.forEach((content) => {
            if (portions[content.uuid]?.logged_amount === 0) {
                this.setState({ error: 'Please enter valid amount.' });
                invalid = true;
                return;
            }
        });

        if (invalid) {
            return;
        }

        onSelectPortions(contents, portions);
    };

    disableSaveBtn = (value) => {
        this.setState({ disableSaveBtn: value });
    };

    renderPortionForContent = (content, i) => {
        const { portions } = this.state;
        const { requirePrecise } = this.props;
        const portion = portions[content.uuid] || {};

        const onChangeAmount = (logged_portion, logged_unit, logged_amount, logged_grams, logged_milliliters, unit) => {
            portions[content.uuid] = portions[content.uuid] || {};
            Object.assign(portions[content.uuid], {
                logged_portion,
                logged_unit,
                logged_amount,
                logged_grams,
                logged_milliliters,
                unit,
            });

            this.setState({ portions }, this.syncAssets);
        };

        // Is this something that can't be used?
        let isUsable = true;

        if (requirePrecise && !(content.grams_per_serving || content.milliliters_per_serving)) {
            isUsable = false;
        }

        return (
            <li key={i}>
                {i > 0 ? (
                    <p>
                        {content.brand_name ? <span>{content.brand_name} </span> : null}
                        {content.title || content.pretty_name || content.name}
                    </p>
                ) : null}

                <FoodUnitsSelector
                    food={content}
                    showAbove
                    meal={portion}
                    onChangeAmount={onChangeAmount}
                    disableSaveBtn={this.disableSaveBtn}
                />

                {!isUsable ? (
                    <div className="not-usable">
                        <p>Very sorry but this ingredient doesn't have enough information to be used in a recipe.</p>
                        <p>Once the information is entered from the food label, then this ingredient can be used.</p>
                    </div>
                ) : null}
            </li>
        );
    };

    onSaveFood = (food) => {
        this.setState({ content: food, nutrients: food.nutrients.values, titles: [food.name] });
        this.closeFoodEditorModal();
    };

    onSaveRecipe = (recipe) => {
        this.setState({ content: recipe, nutrients: recipe.nutrients.values, titles: [recipe.title] });
        this.closeRecipeEditorModal();
    };

    renderFoodEditorModal = () => {
        const { content, openFoodEditor } = this.state;
        const { profile } = this.props;

        if (!openFoodEditor) {
            return;
        }

        return (
            <FoodEditorModal
                modalTitle="Edit Custom Food"
                contentLabel="Edit Recipe Details"
                food={content}
                profile={profile}
                ref="editor"
                closeModal={this.closeFoodEditorModal}
                onSaveFood={this.onSaveFood}
            />
        );
    };

    renderRecipeEditorModal = () => {
        const { content, openRecipeEditor } = this.state;
        const { profile } = this.props;

        if (!openRecipeEditor) {
            return;
        }

        return (
            <RecipeEditorModal
                profile={profile}
                title="Edit Recipe"
                uuid={content.uuid}
                onClose={this.closeRecipeEditorModal}
                onSave={this.onSaveRecipe}
            />
        );
    };

    render() {
        const { profile, contents, closeModal, modalTitle, ctaText, requirePrecise } = this.props;
        const { nutrients, content, portions, titles, disableSaveBtn } = this.state;

        // Override portion size to 1. We want the absolute nutrients, not factoried by the profile's portion size.
        const toDisplay = getNutrientsToDisplay({ ...profile, portion: 1 }, nutrients, null);
        const entered = Object.keys(toDisplay).filter((nutrNo) => typeof toDisplay[nutrNo].v === 'number');

        const { hide_nutrition = false, rd_override = false } = profile || {};

        const excludeNutrNos = ['208'];

        // Are we missing any selections? Don't enable the primary CTA until we're fully selected.
        const isComplete =
            requirePrecise &&
            contents.reduce((carry, content) => {
                return (
                    carry &&
                    portions[content.uuid] &&
                    (portions[content.uuid].logged_grams || portions[content.uuid].logged_milliliters)
                );
            }, true);

        let disabled = false;
        if (requirePrecise && !isComplete) {
            disabled = true;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Log your Portions"
                className="log-portions-modal"
                overlayClassName="feed-modal-overlay log-portions-overlay"
                closeTimeoutMS={250}
            >
                <div className="log-portions-modal-container">
                    <header>
                        <button className="close-modal-btn" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <section className="log-portions-body">
                        <h3>
                            {content && content.brand_name ? <em>{content.brand_name}&nbsp;</em> : null}
                            {titles.join(' + ')}
                        </h3>

                        {(!hide_nutrition || rd_override === true) && entered.length == 0 ? (
                            <div className="nutrients-in-portions">
                                <ul className="rest">
                                    <li>This food's nutritional details have not been provided.</li>
                                </ul>
                            </div>
                        ) : null}

                        {(!hide_nutrition || rd_override === true) && entered.length > 0 ? (
                            <div className="nutrients-in-portions">
                                <div className="cal" data-testid="calories">
                                    <h4>
                                        {typeof nutrients[208] === 'number' &&
                                        nutrients[208] !== Infinity &&
                                        !Number.isNaN(nutrients[208]) ? (
                                            roundForHumans(nutrients[208])
                                        ) : (
                                            <span>&mdash;</span>
                                        )}
                                    </h4>
                                    <h5>calories</h5>
                                </div>

                                <ul className="rest">
                                    {Object.keys(toDisplay)
                                        .filter((nutrNo) => !excludeNutrNos.includes(nutrNo))
                                        .sort(nutrNoSortCmp)
                                        .map((nutrNo, i) => {
                                            const nutr = toDisplay[nutrNo];

                                            return (
                                                <li key={i} data-nutrno={nutrNo} data-testid={`nutrient${nutrNo}`}>
                                                    <em>{allNutrients[nutrNo].NutrDesc}</em>
                                                    <span>
                                                        {typeof nutr.v === 'number' &&
                                                        nutr.v !== Infinity &&
                                                        !Number.isNaN(nutr.v) ? (
                                                            roundForHumans(nutr.v)
                                                        ) : (
                                                            <span>&mdash;</span>
                                                        )}{' '}
                                                        {allNutrients[nutrNo].Units}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        ) : null}
                        <p className="data-source">
                            {content && content.USDA && content.USDA.FDC_Id ? (
                                <a
                                    href={`https://fdc.nal.usda.gov/fdc-app.html#/food-details/${content.USDA.FDC_Id}/nutrients`}
                                    target="_blank"
                                >
                                    source
                                </a>
                            ) : null}

                            {profile?.role == 'admin' && content?.type === 'food' ? (
                                <Link to={`/admin/foods/${content.uuid}`}>admin edit</Link>
                            ) : null}

                            {profile?.role == 'admin' && content?.type === 'recipe' ? (
                                <Link to={`/publisher/recipe/${content.uuid}`}>admin edit</Link>
                            ) : null}

                            {content?.owner === profile?.uuid && content?.type === 'food' ? (
                                <button
                                    className="el-medium-btn el-link-raspberry-btn"
                                    onClick={this.openFoodEditorModal}
                                >
                                    edit nutrition
                                </button>
                            ) : null}

                            {content?.owner === profile?.uuid && content?.type === 'recipe' ? (
                                <button
                                    className="el-medium-btn el-link-raspberry-btn"
                                    onClick={this.openRecipeEditorModal}
                                >
                                    edit nutrition
                                </button>
                            ) : null}
                        </p>
                    </section>

                    <footer>
                        <h2>{modalTitle}</h2>

                        <ul className="unit-selector-list">{contents.map(this.renderPortionForContent)}</ul>
                        <button
                            disabled={disabled || (!disabled && disableSaveBtn)}
                            className="ok-btn"
                            onClick={this.logPortions}
                        >
                            {ctaText ? ctaText : <span>log {contents.length == 1 ? 'meal' : 'meals'}</span>}
                        </button>
                    </footer>
                </div>
                {this.renderFoodEditorModal()}
                {this.renderRecipeEditorModal()}
            </Modal>
        );
    }
}
