'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Expander from '../Expander.react';
import PrepTimeFilter from '../Filters/PrepTime.react';

export default class PrepTime extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
    };

    static defaultProps = {
        isExpander: false,
    };

    render() {
        const { params, onChangeParams, isExpander } = this.props;

        let badge = <em data-active={false} />;

        if (params && params.filters && params.filters.hands_on_time) {
            badge = <em data-active={true}>1</em>
        }

        const button = (
            <span className="global-search-filter-btn">
                Prep Time {badge}
            </span>
        );

        // If we're not filtering for plans, then the plan size filter is not active
        const isFilterAvailable = params.types.includes('recipe');

        if (!isFilterAvailable) {
            return <span />
        }

        return (
            <span className="global-search-hideable-filter" data-active={true}>
                <Expander button={button}>
                    <PrepTimeFilter params={params} onChangeParams={onChangeParams} />
                </Expander>
            </span>
        );
    }
}
