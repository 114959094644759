'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Modal from 'react-modal';

import Header from '../components/HomePage/Header.react';
import ConditionsScroller from '../components/HomePage/ConditionsScroller.react';
import AppHomeScreen from '../components/HomePage/AppHomeScreen.react';
import Hero from '../components/HomePage/Hero.react';
import UserStore from '../stores/UserStore';
import Footer from '../components/Widgets/footer/Footer.react';
import HowItWorks from '../components/HomePage/HowItWorks.react';
import Testimonials from '../components/HomePage/Testimonials.react';
import BestOfBest from '../components/HomePage/BestOfBest.react';
import PlanningTool from '../pro/components/HomePage/PlanningTool.react';
import CpeAccredited from '../components/HomePage/CpeAccredited.react';

import { getConfig } from '../utils/Env';
import { conditionSortCompare } from '../utils/Conditions';
import allConditions from '../tables/conditions';
import allProviders from '../tables/fulfillment-providers';

import Analytics from '../utils/Analytics';

import './HomePage.scss';

const healthConditions    = allConditions.filter(c => c.on_consumer && !c.lifestyle).sort(conditionSortCompare),
      lifestyleConditions = allConditions.filter(c => c.on_consumer && c.lifestyle).sort(conditionSortCompare);


export default class HomePage extends Component {

    static contextTypes = {
        router: PropTypes.object,
        showLoginForm: PropTypes.func,
        isMobile: PropTypes.bool,

        isCordova: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            user: UserStore.getUser(),
        };
    }

    interval = null;

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);

        const { user } = this.state;
        const { isCordova } = this.context;
        const { pathname, query, hash } = this.props.location;
        const { router, showLoginForm } = this.context;

        if (user) {
            return router.push('/meals');
        }

        if (query.showDashboard) {
            if (!user) {
                Analytics.viewConsumerHomepage();
                return showLoginForm();
            }

            delete query.showDashboard;

            router.push({pathname, query, hash});
            return;
        }

        Analytics.viewConsumerHomepage();
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);

        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    onUserStoreChange = () => {
        const { router } = this.context;
        const user = UserStore.getUser();

        if (user) {
            return router.push('/meals');
        }

        this.setState({user});
    }

    closeProModal = () => {
        this.setState({isProModalOpen: false});
    }

    showRetrieveInvite = () => {
        const { showLoginForm } = this.context;

        return showLoginForm({
            defaultMode: 'retrieve-invite',
        });
    }

    renderProModal = () => {
        const { isProModalOpen } = this.state;

        if (!isProModalOpen) {
            return;
        }

        return (
            <Modal isOpen={true}
                onRequestClose={this.closeProModal}
                closeModal={this.closeProModal}
                contentLabel="Login"
                className="login-modal professional-modal"
                overlayClassName="login-modal-overlay"
                closeTimeoutMS={250}>
                <div className="login-form-scroll-container" onClick={this.closeProModal}>
                    <div className="login-modal-container" onClick={ev => ev.stopPropagation()}>
                        <div className="login-form"><section className="form-container pro-modal-container">
                            <header>
                                <img src="https://static.chewba.info/images/header-logo-gray-green-2017-07-03.png" />

                                <button className="close-modal-btn" onClick={this.closeProModal}>
                                    <i className="icon-close-x" />
                                </button>
                            </header>

                            <section className="dietetics">
                                <h2>Dietetics</h2>
                                <h3>Dietitians &mdash; Nutrition Specialists</h3>
                                <p>Reduce individualized meal planning time by 90% and deliver improved success for your clients</p>

                                <footer>
                                    <Link to="/pro/medical" target="lp"><button className="learn-more-btn">Learn More</button></Link>
                                </footer>
                            </section>

                            <section className="fitness">
                                <h2>Fitness</h2>
                                <h3>Trainers &mdash; Coaches &mdash; Gyms</h3>
                                <p>Increase revenue and drive client results by offering EatLove's proprietary nutrition programs to your customers</p>

                                <footer>
                                    <Link to="/pro/fitness" target="lp"><button className="learn-more-btn">Learn More</button></Link>
                                </footer>
                            </section>

                            <section className="medical">
                                <h2>Other Health Professionals</h2>
                                <h3>Hospitals & Other Professional Inquiries</h3>

                                <footer>
                                    <Link to='/pro' target="lp"><button className="learn-more-btn">Learn More</button></Link>
                                </footer>
                            </section>
                        </section></div>
                    </div>
                </div>
            </Modal>

        );
    }

    renderWebHomePage = () => {
        const { showLoginForm, isMobile, isCordova } = this.context;

        return (
            <div className="home-page">
                <div className="main-banner">
                    <Header isMobile={isMobile} showLoginForm={showLoginForm} />

                    <Hero showRetrieveInvite={this.showRetrieveInvite} />
                </div>

                <div className="banner-conditions">
                    <ConditionsScroller title="LIFESTYLE GOALS" conditions={lifestyleConditions} />
                    <ConditionsScroller title="MEDICAL CONDITIONS" conditions={healthConditions} />
                </div>

                <div className="banner-featured-on">
                    <section className="container-featured">
                        <h3>Trusted by the top names in Health and Fitness</h3>
                        <img src="https://static.chewba.info/images/c058064e-8886-4f01-854f-0115712f22f6.jpg" className="amazon-fresh img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/5750e88e-ee09-46b6-a0cd-e2b021d24334.png" className="american img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/pro-hp/anytime-fitness-logo-2.svg" className="img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/pro-hp/canyon-ranch-logo-1.png" className="img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/a7e101e8-f40a-463f-a7eb-067c2fb044db.jpg" className="cooper-clinic img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/pro-hp/fitnesssf-logo.svg" className="ftsf img-featured responsive-image" />
                        <img data-long={true} src="https://static.chewba.info/images/61fff056-ea2e-4c2e-809e-90af14384d87.png" className="fordham img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/ae02b84e-4715-4260-b347-2bd8dfcaafdf.png" className="img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/pro-hp/keck-school-of-medicine-logo-1.svg" className="keck img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/7c58cca5-2b2a-411f-a1d3-caa015cb6a6f.jpg" className="mayo img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/14b16c4b-df76-48ac-9d37-16c11be372fa.png" className="img-featured responsive-image" />
                        <img data-long={true} src="https://static.chewba.info/images/e639deb2-9206-4053-8b9a-c4a6186fbf6d.jpg" className="panasonic img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/3899e7f0-3ffb-4608-91e5-35b028326fef.png" className="img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/pro-hp/canada-wellness-institute-logo-1.png" className="ca-wellness img-featured responsive-image" />
                        <img src="https://static.chewba.info/images/youfit-logo-charcoal-2021-10.svg" className="img-featured responsive-image" />
                    </section>
                </div>

                <HowItWorks />
                <PlanningTool moduleTitle="An Intelligent App in the Palm of Your Hand"
                    firstBulletTitle="Tells You What to Eat and When"
                    showLoginForm={this.showLoginForm}
                    ctaHost={'https://' + getConfig('www_host')}
                    firstBulletContent="Swappable meals that you can plan ahead or organize in the moment. All while still meeting your lifestyle and fitness goals." />

                <div className="banner-grocery">
                    <section className="container-featured">
                        <h3>Grocery and Meal Delivery Convenience</h3>
                        <div className="grocery-logo-container">
                            <div className="grocery-logo-container-item">
                                <img src={allProviders.walmart.logo} className="img-featured responsive-image" />
                            </div>
                            <div className="grocery-logo-container-item">
                                <img src={allProviders.amazon.logo} className="img-featured responsive-image" />
                            </div>
                            <div className="grocery-logo-container-item">
                                <img src={allProviders.wholefoods.logo} className="img-featured responsive-image wholefoods-logo" />
                            </div>
                            <div className="grocery-logo-container-item">
                                <img src={allProviders.instacart.logo} className="img-featured responsive-image" />
                            </div>
                            <div className="grocery-logo-container-item">
                                <img src={allProviders.sunbasket.logo} className="img-featured responsive-image" />
                            </div>
                        </div>
                    </section>
                </div>
                <Testimonials />
                <BestOfBest />

                <div className="banner-consultation">
                    <div className="inner-container">
                        <header className="header-row">
                            <h2>Chat with a Registered Dietitian</h2>
                        </header>
                        <div className="content-consultation">
                            <p>
                                For one-on-one support from a nutrition expert, we can have one of our registered dietitians contact you via email or phone. Our dietitians will use EatLove PRO to set a completely personalized nutrition prescription for you.
                            </p>
                            <p className="btn-container">
                                <a href="mailto:support@eatlove.is?subject=EatLove - RD consultation request" className="request-consult-btn">request consultation</a>
                            </p>
                        </div>
                    </div>
                </div>

                <CpeAccredited />

                <Footer />
                {this.renderProModal()}
            </div>
        );
    }

    render = () => {
        const { showLoginForm, isMobile, isCordova } = this.context;
        const { user } = this.state;
        const { children, location } = this.props;

        if (isCordova) {
            return <AppHomeScreen {...this.props} />
        }

        return this.renderWebHomePage();
    }
}
