'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Expander from '../Expander.react';
import SortFilter from '../Filters/Sort.react.js';

import sortables from '../SortableFields';

import './Sort.scss';

export default class Sort extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
    };

    static defaultProps = {
        isExpander: false,
    };

    render() {
        const { params, onChangeParams, isExpander } = this.props;

        const selectedSort = sortables.filter(sortable => {
            return params.sort_by === sortable.field &&
                   ((!sortable.order && !params.sort_order) || (sortable.order && sortable.order === params.sort_order));
        })[0];

        let badge = <em />

        const button = (
            <span className="global-search-filter-btn">
                Sort By{selectedSort ? (': ' + selectedSort.name) : null} {badge}
            </span>
        );

        return (
            <span className="global-search-hideable-filter global-search-sort" data-active={true}>
                <Expander button={button}>
                    <SortFilter params={params} onChangeParams={onChangeParams} />
                </Expander>
            </span>
        );
    }
}
