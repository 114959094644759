'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './MacrosPieChart.scss';

export default class MacrosPieChart extends Component {
    static propTypes = {
        nutrients: PropTypes.object,
        className: PropTypes.string,
        portion: PropTypes.number,
        macrosGoals: PropTypes.object,
    };

    static defaultProps = {
        nutrients: {},
        className: 'macros-pie-chart',
        portion: 1,
        macrosGoals: null
    };

    constructor(props, context) {
        super(props, context);

        // start theta off somewhere random
        const thetaStart = 2 * Math.PI * Math.random();

        const { slices, cho, pro, fat } = this.getMacroPortionSlices(props.nutrients, thetaStart, props.portion);

        this.state = {
            thetaStart,
            slices, cho, pro, fat,
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const { thetaStart } = this.state;

        // Update our slices computations
        const { slices, cho, pro, fat } = this.getMacroPortionSlices(nextProps.nutrients, thetaStart, nextProps.portion);

        this.setState({slices, cho, pro, fat});
    }

    getCarbsProteinFat = (kCal, choGrams, proGrams, fatGrams, portion) => {
        let cho = (choGrams * 4 || 0) * portion;
        let fat = (fatGrams * 9 || 0) * portion;
        let pro = (proGrams * 4 || 0) * portion;

        if (typeof choGrams === 'undefined' &&
            typeof fatGrams === 'number' &&
            typeof proGrams === 'number') {
            cho = ((kCal - (proGrams * 4 + fatGrams * 9))) * portion;
        } else if (typeof choGrams === 'number' &&
            typeof fatGrams === 'undefined' &&
            typeof proGrams === 'number'){
            fat = ((kCal - (proGrams * 4 + choGrams * 4))) * portion;
        } else if (typeof choGrams === 'number' &&
            typeof fatGrams === 'number' &&
            typeof proGrams === 'undefined'){
            pro = ((kCal - (choGrams * 4 + fatGrams * 9))) * portion;
        } else if (typeof choGrams === 'number' &&
            typeof fatGrams === 'undefined' &&
            typeof proGrams === 'undefined'){
            let remainder = (kCal - (choGrams * 4)) / 2
            pro = remainder * portion
            fat = remainder * portion
        } else if (typeof choGrams === 'undefined' &&
            typeof fatGrams === 'undefined' &&
            typeof proGrams === 'number'){
            let remainder = (kCal - (proGrams * 4)) / 2
            cho = remainder * portion
            fat = remainder * portion
        } else if (typeof choGrams === 'undefined' &&
            typeof fatGrams === 'number' &&
            typeof proGrams === 'undefined'){
            let remainder = (kCal - (fatGrams * 9)) / 2
            cho = remainder * portion
            pro = remainder * portion
        }

        return { cho, pro, fat };
    }

    getMacroPortionSlices = (nutrients, thetaStart, portion) => {
        const { macrosGoals } = this.props;

        const slices = [];

        // First, cho pro & fat are in grams. Convert to calories.
        let { cho, pro, fat } = this.getCarbsProteinFat(
            nutrients[208], nutrients[205], nutrients[203], nutrients[204], portion
        );

        const goals = macrosGoals ?
            this.getCarbsProteinFat(null, macrosGoals[205], macrosGoals[203], macrosGoals[204], portion)
        : {cho: null, pro: null, fat: null};

        const choGrams =  nutrients[205] || cho/portion/4;
        const proGrams =  nutrients[203] || pro/portion/4;
        const fatGrams =  nutrients[204] || fat/portion/9;

        const width = 120;
        const height = 120;

        const cx = width / 2;
        const cy = height / 2;
        const radius = width / 2;
        const calories = (cho + pro + fat);
        const goalCalories = (goals.cho + goals.pro + goals.fat);
        let theta = thetaStart;

        if  (calories === 0 && goalCalories === 0)  {
            slices.push({
                circle: true,
                rx: radius,
                ry: radius,

                cx, cy,
                color: '#324253',
                label: 'none',
            });

            return slices;
        }

        // radians per calorie
        const radsPerCalorie = Math.PI * 2 / (calories || goalCalories);

        const macros = [
            {
                kcal: cho,
                kcal_goal: goals.cho,
                color: '#e97a99',
                label: 'Carbs',
                key: 'cho',
                nutrNo: 205,
                grams: (Math.round(choGrams) || 0) * portion,
            },
            {
                kcal: pro,
                kcal_goal: goals.pro,
                color: '#2cd296',
                label: 'Protein',
                key: 'pro',
                nutrNo: 203,
                grams: (Math.round(proGrams) || 0) * portion,
            },
            {
                kcal: fat,
                kcal_goal: goals.fat,
                color: '#a8acbe',
                label: 'Fat',
                key: 'fat',
                nutrNo: 204,
                grams: (Math.round(fatGrams) || 0) * portion,
            },
        ];

        macros.forEach(macro => {
            let step = radsPerCalorie * (macro.kcal || macro.kcal_goal);

            let percent = calories > 0 ? Math.round(macro.kcal / calories * 100) : 0;
            const percentGoal = calories > 0 ? Math.round(macro.kcal_goal / goalCalories * 100) : 0;

            const slice = {
                ...macro,
                percent,
                percentGoal,
                calories,
            }

            if (step > 0) {
                Object.assign(slice, {
                    circle: step >= 2 * Math.PI,

                    // radius X and Y (so we can do ovals :)
                    rx: radius,
                    ry: radius,

                    // long arc flag (1 or 0), if the arc is > 180deg, the long arc flag needs to be 1.
                    la: step > Math.PI ? 1 : 0,

                    // Arc's circle center X and Y
                    cx: cx,
                    cy: cy,

                    // Arc start position
                    sx: cx + radius * Math.cos(theta),
                    sy: cy + radius * Math.sin(theta),

                    // Arc end position
                    ex: cx + radius * Math.cos(theta + step),
                    ey: cy + radius * Math.sin(theta + step),

                    // Percentage of the meal
                    percent,
                    calories,
                });

                theta += step;
            }

            slices.push(slice);
        });

        return {slices, pro, cho, fat};
    }

    render = () => {
        const { className } = this.props;
        const { slices, goals, pro, cho, fat } = this.state;

        if (typeof pro === 'undefined' || typeof cho === 'undefined' || typeof fat === 'undefined') {
            return <span style={{display: 'none'}} />
        }

        return (
            <div className={className}>
                <svg className="chart" viewBox="0 0 120 120">
                {slices.map((p, i) => {
                    if (p.circle) {
                        return (
                            <g key={i}>
                                <circle cx={p.cx} cy={p.cy} r={p.rx} fill={p.color} />
                            </g>
                        );
                    }

                    return (
                        <g key={i}>
                            <path d={`M ${p.sx} ${p.sy} A ${p.rx} ${p.rx} 0 ${p.la} 1 ${p.ex} ${p.ey} L ${p.cx} ${p.cy}`}
                                strokeWidth="0" fill={p.color} strokeLinecap="round">
                            </path>
                        </g>
                    );
                })}
                </svg>

                <ul className="macros-legend">
                    {slices.map(macro => macro.key ? (
                        <li key={macro.key}>
                            <span className={"color-key " + macro.key}><i className="icon-checkbox-box" /></span>
                            <span className="name" data-has-goal={(macro.percentGoal && macro.percentGoal > 0) ? true : false}>
                                <span>{macro.label}</span> {macro.percentGoal ? `(${macro.percentGoal}%)` : null}
                            </span>
                            <span>{macro.percent}%</span>
                            <span>{macro.grams}g</span>
                        </li>
                    ) : null)}
                </ul>

            </div>
        );
    }
}
