'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import BoardPicker from './BoardPicker.react';

import BoardActions from '../../../actions/BoardActions';

import '../../FeedModals.scss';
import './BoardPickerModal.scss';


export default class BoardPickerModal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool,
    };

    static defaultProps = {
        isOpen: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            drawer: false,
        };
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({drawer: true}));
    }

    closeModal = (ev) => {

        if (ev) {
            ev.stopPropagation();
        }

        this.setState({drawer: false}, () => {
            setTimeout(this.props.closeModal, 333);
        });
    }

    render() {
        const { drawer } = this.state;
        const { isOpen } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                className="board-picker-modal"
                overlayClassName="feed-modal-overlay"
                contentLabel="Favorites">
                <div className="board-picker-modal-container" data-drawer={drawer}>
                    {isOpen ? <BoardPicker {...this.props} closePopover={this.closeModal} /> : null}
                </div>
            </Modal>
        );
    }
}
