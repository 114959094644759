'use strict';

import uuid from 'uuid';
import store from 'store';
import indexBy from 'lodash.indexby';

import groceryCategories from '../tables/grocery-categories';
import { getGroceriesForMeals, updateGroceryPackaging } from './Grocery';

import allNutrients from '../tables/nutrients';
import recommendations from '../tables/recommendations';
import allDiets from '../tables/diets';

const dietNames = allDiets.map(d => d.name);
const freshCategories = groceryCategories.filter(c => c.fresh).map(c => c.label);

export function computeMatchAverages(plan, recipes) {
    var matches = {}, count = 0;

    plan.items.forEach(meal => {
        const recipe = recipes[meal.recipe_uuid];

        if (!(recipe && recipe.match)) {
            return;
        }

        count++;

        Object.keys(recipe.match).forEach(key => {
            if (matches[key]) {
                matches[key] += recipe.match[key];
            } else {
                matches[key] = recipe.match[key];
            }
        });
    });

    return Object.keys(matches).forEach(key => {
        matches[key] = matches[key] / count;
    });
}

export function getFreshAndPantryCounts(groceries) {


    let totalFresh = 0, totalPantry = 0;

    groceries.forEach(grocery => {
        // Filter out the excluded groceries
        if (grocery.exclude_from_groceries) {
            return;
        }

        if (freshCategories.indexOf(grocery.category) != -1) {
            totalFresh++;
        } else {
            totalPantry++;
        }
    });

    return {totalFresh, totalPantry};
}

export function computeEfficiencyScore(plan, assets, units_mode) {
    let groceries = getGroceriesForMeals(plan.items, assets);
    groceries = updateGroceryPackaging(groceries, assets.foods, units_mode);

    const efficiencyCategories = ['Produce', 'Produce - Fruit', 'Produce - Vegetables'];

    let freshCount      = 0;

    let totalMass       = 0;
    let totalMassExcess = 0;

    let totalCost       = 0;
    let totalCostExcess = 0;

    groceries.forEach(grocery => {
        if (!efficiencyCategories.includes(grocery.category)) {
            return;
        }

        if (grocery.exclude_from_food_waste) {
            return;
        }

        freshCount++;

        totalMass       += grocery.pkg_grams * grocery.quantity;
        totalMassExcess += grocery.excess;

        totalCost       += grocery.price;
        totalCostExcess += grocery.excess_price;
    });

    if (!freshCount || !totalMass || !totalCost) {
        return { score: 0, groceries };
    }

    let freshCountScore = 1;

    [
        {min:  0, max: 10, score: 0.0},
        {min: 11, max: 15, score: 0.1},
        {min: 16, max: 20, score: 0.2},
        {min: 21, max: 25, score: 0.3},
        {min: 26, max: 30, score: 0.4},
        {min: 31, max: 35, score: 0.5},
        {min: 36, max: 45, score: 0.6},
        {min: 46, max: 55, score: 0.7},
        {min: 56, max: 65, score: 0.8},
        {min: 66, max: 70, score: 0.9},
    ].forEach(bracket => {
        if (freshCount >= bracket.min && freshCount <= bracket.max) {
            freshCountScore = bracket.score;
        }
    });

    // Efficiency score formula EF1
    const score = 1 - ((totalMassExcess / totalMass * 0.9) +
                       (totalCostExcess / totalCost * 0.0) +
                       (freshCountScore             * 0.1));

    return { score, groceries };
}

export function doesEnvelopeMatchNutrients(envelope, nutrients) {
    let matches = true;

    Object.keys(envelope).forEach(nutrNo => {
        // Make sure the nutrient value is not null, must be a real number
        const value = nutrients[nutrNo] || 0;
        const range = envelope[nutrNo];

        if (typeof range.min !== 'undefined' && value < range.min) {
            matches = false;
        }

        if (typeof range.max !== 'undefined' && value > range.max) {
            matches = false;
        }
    });

    return matches;
}


export function computeAverageNutrients(mealNutrients) {
    // Aggregate all the nutrients together
    let result = Object.keys(mealNutrients).reduce((carry, i) => {
        const item = mealNutrients[i];

        Object.keys(item).forEach(key => {
            const value = item[key];

            if (carry[key]) {
                carry[key] += value;
            } else {
                carry[key] = value;
            }
        });

        return carry;
    }, {});

    // Average by number of discrete units
    Object.keys(result).forEach(key => {
        result[key] = result[key] / Object.keys(mealNutrients).length;
    });

    return result;
}

export function computeMinMaxNutrients(mealNutrients) {
    const results = {};

    Object.keys(mealNutrients).forEach(offset => {
        const meal = mealNutrients[offset];

        Object.keys(meal).forEach(nutrNo => {
            const value = meal[nutrNo];

            results[nutrNo] = results[nutrNo] || {min: value, max: value};

            if (results[nutrNo].min > value) {
                results[nutrNo].min = value;
            }

            if (results[nutrNo].max < value) {
                results[nutrNo].max = value;
            }
        });
    });

    return results;
}

export function getItemFoodNutrients(item, product) {
    const grams = item.logged_grams || 0;
    const ml    = item.logged_milliliters || 0;

    if (!grams && !ml) {
        return {};
    }

    // default of 100 grams / serving estimate
    const gramsPerServing = product.grams_per_serving || 100;
    const mlPerServing = product.milliliters_per_serving || 100;

    const source = (product.nutrients && product.nutrients.values) || {};

    // Scale the item to the amount logged
    const nutrients = [];
    Object.keys(source).forEach(nutrNo => {
        const value = source[nutrNo];
        nutrients[nutrNo] = product.serving_unit == 'ml'
                          ? (value / mlPerServing) * ml
                          : (value / gramsPerServing) * grams;
    });

    return nutrients;
}


export function getFoodsInfo(foods) {
    let names = [];
    let totalPantry = 0;
    let totalFresh = 0;

    Object.keys(foods).forEach(uuid => {
        const food = foods[uuid];
        names.push(food.pretty_name || food.name);

        if (food.exclude_from_groceries) {
            return;
        }

        if (freshCategories.includes(food.category)) {
            totalFresh++;
        } else {
            totalPantry++;
        }
    });

    names.sort((a, b) => a.localeCompare(b));

    return { totalFresh, totalPantry, names };
}

export function computeTags(plan, {recipes, products}) {
    let collector = [];
    let tags = [];

    plan.items.forEach(item => {
        let itemTags = [], recipe, product;

        // Do not allow recipes with duplicate tags to double count
        if (item.recipe_uuid && (recipe = recipes[item.recipe_uuid])) {
            itemTags = (recipe.tags || []).slice();
        }

        if (item.product_uuid && (product = products[item.product_uuid])) {
            itemTags = (product.tags || []).slice();
        }

        // De-duplicate item tags before counting so a double-tagged recipe or product doesn't double count.
        itemTags = itemTags.filter((v, i) => i === itemTags.indexOf(v));

        itemTags.forEach(tag => {
            collector[tag] = collector[tag] || 0;
            collector[tag]++;
        });
    });


    // Filter out diet tags, we're going to use meal analysis and avoidances to pick diet tags
    tags = Object.keys(collector).filter((tag) => {
        // Filter out any tag that isn't in every recipe
        return collector[tag] == plan.items.length &&
               !dietNames.includes(tag) &&
               !['Beginner', 'Intermediate', 'Advanced'].includes(tag);
    });

    if (collector['Advanced']) {
        tags.push('Advanced');
    } else if (collector['Intermediate']) {
        tags.push('Intermediate');
    } else if (collector['Beginner']) {
        tags.push('Beginner');
    }

    tags = tags.concat(plan.extratags).filter((v, i) => i === tags.indexOf(v));

    tags.sort((a, b) => a.localeCompare(b));

    return tags;
}

export function updatePlanItems(plan, assets) {
    let uniqueMeals = {};
    let handsOnByOffset = {};
    let nutrientsByOffset = {};
    let costPerServingTotal = 0, totalTimeTotal = 0;
    let ingredientTags = [];
    let collector = {};

    plan.items = plan.items || [];

    plan.items.forEach(meal => {
        meal.offset = meal.offset || 0;
        meal.meal_type = meal.meal_type || 'fresh';
        meal.meal = meal.meal || 'Snack';

        const mealKey = `${meal.offset}-${meal.meal}`;
        uniqueMeals[mealKey] = true;
        let contribs = {};

        handsOnByOffset[meal.offset] = handsOnByOffset[meal.offset] || 0;

        if (meal.recipe_uuid && assets.recipes[meal.recipe_uuid]) {
            const recipe = assets.recipes[meal.recipe_uuid];
            contribs = (recipe && recipe.nutrients && recipe.nutrients.values) || {};

            (recipe.tags || []).forEach(tag => {
                collector[tag] = collector[tag] || 0;
                collector[tag]++;
            });

            meal.details_uuid = recipe.details;
            meal.recipe_image = recipe.image;
            meal.recipe_title = recipe.title;

            if (meal.meal_type === 'fresh') {
                if (recipe.avg_cost_per_serving) costPerServingTotal += recipe.avg_cost_per_serving;
                if (recipe.total_time) totalTimeTotal += recipe.total_time;
                if (recipe.hands_on_time) handsOnByOffset[meal.offset] += recipe.hands_on_time;

                ingredientTags = ingredientTags.concat(recipe.ingredient_tags || []);
            }

        } else if (meal.food_uuid && assets.foods[meal.food_uuid]) {
            const food = assets.foods[meal.food_uuid];
            contribs = getItemFoodNutrients(meal, food);
            ingredientTags = ingredientTags.concat(food.ingredient_tags || []);
        }

        Object.keys(contribs).forEach(nutrNo => {
            nutrientsByOffset[meal.offset] = nutrientsByOffset[meal.offset] || {};
            nutrientsByOffset[meal.offset][nutrNo] = nutrientsByOffset[meal.offset][nutrNo] || 0;
            nutrientsByOffset[meal.offset][nutrNo] += contribs[nutrNo];
        });
    });

    // Sort and de-duplicate ingredient tags
    ingredientTags.sort((a, b) => a.localeCompare(b));
    plan.ingredient_tags = ingredientTags.filter((v, i) => i == ingredientTags.indexOf(v));

    // Update the total time and average cost per serving fields
    plan.avg_cost_per_serving = Object.keys(uniqueMeals).length > 0
                              ? Math.round(costPerServingTotal / Object.keys(uniqueMeals).length * 100) / 100
                              : 0;

    plan.total_time = totalTimeTotal;
    plan.hands_on_time = 0;
    if (Object.keys(handsOnByOffset).length > 0) {
        // Add up all the hands on times
        const totalHandsOnTime = Object.keys(handsOnByOffset).reduce((c, k) => c + handsOnByOffset[k], 0);

        // Divide by number of days to get average daily hands on time.
        plan.hands_on_time = Math.round(totalHandsOnTime / Object.keys(handsOnByOffset).length);
    }

    const { totalFresh, totalPantry, foodNames } = getFoodsInfo(assets.foods);
    plan.total_fresh = totalFresh;
    plan.total_pantry = totalPantry;
    plan.food_names = foodNames;
    plan.tags = computeTags(plan, assets);

    const values = computeAverageNutrients(nutrientsByOffset)

    plan.nutrients = {values};

    return plan;
}

export function getMealCountByType(plan, mealName) {
    const collector = {};

    plan.items.filter((item) => item.meal === mealName)
              .forEach(item => {
                collector[item.offset] = collector[item.offset] || true;
              });

    return Object.keys(collector).length;
}

export function updateMealCounts(plan) {
    plan.breakfasts = getMealCountByType(plan, 'Breakfast');
    plan.lunches    = getMealCountByType(plan, 'Lunch');
    plan.dinners    = getMealCountByType(plan, 'Dinner');
    plan.snacks     = getMealCountByType(plan, 'Snack');

    return plan;
}

export function analyzePlan(plan, assets, units_mode) {
    // Is tags not set? Create it
    plan.tags = plan.tags || [];
    plan.extratags = plan.extratags || [];

    plan = updatePlanItems(plan, assets);
    plan = updateMealCounts(plan);

    // Calculate the efficiency score
    const { score, groceries } = computeEfficiencyScore(plan, assets, units_mode);
    plan.efficiency_score = score;

    return { plan, assets, groceries };
}

export function processVirtualPlans(vms = []) {
    vms = vms.map((vm, key) => {
        // Assign the virtual menu a uuid if it doesn't have one
        vm.uuid = vm.uuid || uuid.v4();
        return vm;
    });

    let existingIndexed = store.get('virtual-menus') || {};
    let newIndexed      = indexBy(vms, 'uuid');

    // Merge existing and incoming
    let virtualPlans = {...existingIndexed, ...newIndexed};

    // De-index them
    virtualPlans = Object.keys(virtualPlans).map(k => virtualPlans[k]);

    // Limit to only 10 items in the stack, remove old items from the front of the list
    if (virtualPlans.length > 10) {
        virtualPlans.splice(0, virtualPlans.length - 10);
    }

    // Strip unnecessary crap from the meal plan object. We don't need to store most of it.
    const keepKeys = ['items', 'title', 'image', 'uuid', 'type', 'meal_types', 'breakfasts', 'lunches',
                      'dinners', 'snacks', 'total_fresh', 'total_pantry', 'replaced_by', 'sizes', 'explain'];
    virtualPlans.forEach(vm => {
        Object.keys(vm).forEach(key => {
            if (!keepKeys.includes(key)) {
                delete vm[key];
            }
        });

        // Loop through each item and remove stuff there too
        vm.items.forEach(item => {
            delete item.leftovers_removed;
            // delete item.type;
        });
    });

    // Reindex by uuid and put back in localstorage
    virtualPlans = indexBy(virtualPlans, 'uuid');

    store.set('virtual-menus', virtualPlans);

    return vms;
}

export function fetchAllVirtualPlans() {
    return store.get('virtual-menus') || {};
}

export function fetchVirtualPlan(uuid) {
    const virtualPlans = store.get('virtual-menus') || {};

    return virtualPlans[uuid];
}

export function deleteVirtualPlan(uuid, alias) {
    const virtualPlans = store.get('virtual-menus') || {};

    if (alias) {
        if (virtualPlans[uuid]) {
            virtualPlans[uuid].replaced_by = alias;
        }
    } else {
        delete virtualPlans[uuid];
    }

    store.set('virtual-menus', virtualPlans);
}
