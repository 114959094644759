'use strict';

import { Component } from 'react';
import moment from 'moment';

import './FamilyMembers.scss';

export default class FamilyMembers extends Component {
    renderFamilyMember = (member, i) => {
        const { preferences = {} } = this.props.profile;
        const age = member.birthdate ? moment().diff(member.birthdate, 'year') : null;

        return (
            <tr key={i}>
                <td className="person-info">
                    <em>{member.name}</em>
                    {age ? <span>, {age} year old</span> : null}
                    {member.gender ? <span>, {member.gender}</span>: null}
                    <span><br />Portion size: {member.portion}</span>
                </td>
                {preferences ? preferences.meal_types
                    .filter(p => p.main_dish != 'Snack')
                    .map(p => (
                        <td>
                            <p>{p.name}</p>
                            <i className={member?.meal_types?.some(m => m.name == p.name && !!m.enabled)
                                ? "icon-check2" : "icon-silverware2"} />
                        </td>
                    )) : null}
            </tr>
        );
    }

    render = () => {
        const { profile } = this.props;
        const { preferences = {}, family = [] } = profile;

        if (family.length <= 0) {
            return (
                <div className="patient-family-members">
                    <p>{profile.first_name} eats alone.</p>
                </div>
            );
        }

        return (
            <div className="patient-family-members">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {preferences ? preferences.meal_types
                                .filter(p => p.main_dish != 'Snack')
                                .map(p => (
                                    <th>{p.name}</th>
                                )) : null}
                        </tr>
                    </thead>
                    <tbody>
                        {family.map(this.renderFamilyMember)}
                    </tbody>
                </table>
            </div>
        );
    }
}
