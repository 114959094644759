'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Expander from '../Expander.react';
import AvoidancesFilter from '../Filters/Avoidances.react.js';

export default class Avoidances extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
    };

    static defaultProps = {
        isExpander: false,
    };

    render() {
        const { params, onChangeParams, isExpander } = this.props;

        let badge = <em />

        // Are any diets selected?
        const selectedAvoidances = (params.filters['!ingredient_tags'] || []).length +
                                   (params.filters['!foods'] || []).length;

        if (selectedAvoidances > 0) {
            badge = <em data-active={true}>{selectedAvoidances}</em>
        }

        const button = (
            <span className="global-search-filter-btn">
                Avoidances {badge}
            </span>
        );

        return (
            <span className="global-search-hideable-filter" data-active={true}>
                <Expander button={button}>
                    <AvoidancesFilter params={params} onChangeParams={onChangeParams} />
                </Expander>
            </span>
        );
    }
}
