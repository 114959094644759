'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

const calorieFilters = [
    {label: 'Any Calories',      v: false},
    {label: 'Under 150',         v: {lte: 150}},
    {label: 'Between 151 - 300', v: {gte: 151, lte: 300}},
    {label: 'Between 301 - 450', v: {gte: 301, lte: 450}},
    {label: 'Between 451 - 600', v: {gte: 451, lte: 600}},
    {label: 'Above 600',         v: {gte: 601}},
];

export default class Calories extends Component {
    static propTypes = {
    };

    static contextTypes = {
        setParamsToRecipeOnly: PropTypes.func,
    };

    getValueFromParams = (params) => {
        let index = 0, label = calorieFilters[0].label;
        const calories = params && params.filters && params.filters.calories;

        if (!calories) {
            return { index, label };
        }

        calorieFilters.forEach((def, i) => {
            if (!def.v) {
                return;
            }

            if (((!def.v.gte && !calories.gte) || (def.v.gte && calories.gte == def.v.gte)) &&
                ((!def.v.lte && !calories.lte) || (def.v.lte && calories.lte == def.v.lte))) {
                index = i;
                label = def.label;
            }
        });

        return { index, label };
    }

    onChangeCalories = (calories) => {
        const { params, onChangeParams } = this.props;
        const { setParamsToRecipeOnly } = this.context;

        if (calories.v) {
            params.filters.calories = calories.v;

            setParamsToRecipeOnly(params);
        } else {
            delete params.filters.calories;
        }

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;
        const { index, label } = this.getValueFromParams(params);

        return (
            <div className="global-search-tag-group global-search-calories-filter">
                <ul>
                    {calorieFilters.map((calories, i) => {
                        return (
                            <li key={i}>
                                <button className="el-radio-btn" data-active={i === index}
                                    onClick={e => this.onChangeCalories(calories)}>
                                    {calories.label}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
