'use strict';

import jwtDecode from 'jwt-decode';
import store from 'store';
import moment from 'moment-timezone';
import * as Sentry from "@sentry/react";

import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';
import AuthConstants from '../constants/AuthConstants';
import UserStore from '../stores/UserStore';
import AuthStore from '../stores/AuthStore';
import { getConfig } from '../utils/Env';
import Analytics from '../utils/Analytics';
import SessionRecorder from '../utils/SessionRecorder';


let checkCookieInterval = false;
let lastToken = null;

// This function polls our cookie every half second to check to see if it's changed. For instance,
var pollAuthChanges = (function () {
    lastToken = AuthStore.getToken();

    return () => {
        var currentToken = AuthStore.getToken();

        if (currentToken == lastToken) {
            return;
        }

        // This handles if we re-authenticate with a different token
        if (currentToken) {
            AuthActions.setup();
        }

        // This handles if we just signed out
        if (lastToken && !currentToken) {
            AppDispatcher.handleViewAction({
                actionType: UserConstants.USER_LOGOUT,
            });
        }

        lastToken = currentToken;
    }
})();

function saveDistinctId(links, distinct_id) {
    return AuthStore.fetch(getConfig('users_api') + links.self.href, {
        method: 'POST',
        headers: {'Content-Type': 'application/json; schema=user/1'},
        body: JSON.stringify({distinct_id}),
    });
}

function completeLogin(auth, keepRecordingSession = false) {
    // Make sure our polling doesn't fire while we're doing this.
    const myLastToken = lastToken = auth.token;

    // Set the authentication tokens in the auth store first.
    if (!auth.skipReauth) {
        AuthActions.authenticate(auth);
    }

    // Remove the default email memory
    store.remove('default-email');

    // Get everything about this user. We'll hydrate the stores with the embedded data.
    const usersUrl = getConfig('users_api') + auth.links.home.href + '?embed=family,boards,meals,plans,groceries,capabilities,location';

    let userMetaPromise = fetch(usersUrl, {
        headers: {'Authorization': 'bearer ' + myLastToken}
    });

    const allPromises = [userMetaPromise];

    // This is a little convoluted because both the fetch promise needs resolve AND
    // the json promise (you can't just resolve the fetch, then get json, you have to resolve two promises
    // per-request). Maybe the await keyword could work here to simplify this?
    return Promise.all(allPromises).then(values => {
        if (!(values && values.length == allPromises.length)) {
            return Promise.reject();
        }

        if (values[0] && values[0].ok) {
            return Promise.all(values.map(r => r.json())).then(values => {

                // If the token changed inbetween when we requested it and now, then we just ignore this request and
                // drop the now invalidated data
                if (myLastToken !== lastToken) {
                    return Promise.reject();
                }

                const user = values[0];
                const distinct_id = Analytics.getDistinctId();

                // Do we already have a distinct_id saved, but not stored on this user record? Update it our local copy
                if (!user.distinct_id && distinct_id && user.uuid !== distinct_id) {
                    user.distinct_id = distinct_id;

                    saveDistinctId(user.links, distinct_id);
                }

                const {
                    preferences = {diets: [], avoidances: []},
                    family = [],
                    boards = [],
                    meals = [],
                    plans = [],
                    orders = [],
                    groceries = [],
                    capabilities = {},
                    location = {},
                } = user;

                // Identify the user now that they're logged in
                Analytics.identify(user);

                SessionRecorder.identify(user);

                if (!SessionRecorder.shouldSendData(user) && !keepRecordingSession) {
                    SessionRecorder.stop();
                }

                Sentry.setUser({
                    id: user.uuid,
                    username: user.name,
                    email: user.email,
                    segment: user.dietitian && user.dietitian.uuid ? 'pro' : 'consumer'
                });

                const decoded = jwtDecode(lastToken);

                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_COMPLETE_LOGIN,
                    user, capabilities, family, boards, meals, plans, orders, groceries, location,
                    dnt: decoded.dnt ? true : false
                });

                // Return all the data to the caller (probably the Login Form)
                return {
                    auth,
                    user,
                    preferences,
                    family,
                    boards,
                    meals,
                    plans,
                    groceries,
                    capabilities,
                    location,
                    dnt: decoded.dnt ? true : false
                };
            });
        }

        return Promise.reject();
    });
}

const AuthActions = {
    /**
     * Does the JWT in our cookie match the user in localstorage? If not, then we need to reload
     * the user using our current token JWTs. Obviously, this will be skipped if there is no token
     *
     * 1. There is no user in localstorage and there IS a token in the cookie
     * 2. The user in localstorage doesn't match the token in the cookie
     *
     * Should be run by the root component right after initialization of environment.
     */
    setup: function() {
        // Setup our timeout function to check to make sure our cookie hasn't changed
        if (checkCookieInterval === false) {
            checkCookieInterval = setInterval(pollAuthChanges, 500);
        }

        let token = AuthStore.getToken();

        // If there's no token, there's no reason to go on in this function
        if (!token) {
            // Ensure the UserStore is also emptied, if we don't have a token, we're not logged in.
            AppDispatcher.handleViewAction({
                actionType: UserConstants.USER_LOGOUT,
            });
            return;
        }

        token = jwtDecode(token);

        // Can't decode the token, there's no reason to try to load anything.
        if (!(token && token.sub)) {
            AppDispatcher.handleViewAction({
                actionType: AuthConstants.AUTH_DEAUTHORIZE,
            });

            // Ensure the UserStore is also emptied, if we don't have a token, we're not logged in.
            AppDispatcher.handleViewAction({
                actionType: UserConstants.USER_LOGOUT,
            });
            return;
        }

        const user = UserStore.getUser();

        // If our token doesn't match our user, we immediately delete it.
        // We don't want to deauthorize because that invalidates our currently logged in token
        if (user && token.sub !== user.uuid) {
            AppDispatcher.handleViewAction({
                actionType: UserConstants.USER_LOGOUT,
            });
        }

        const req = {
            links: {
                home: {href: '/users/' + token.sub},
            },
            token: AuthStore.getToken(),
            refresh: AuthStore.getRefresh(),
            skipReauth: true,
        };

        // User record and preferences needs to be reloaded on page load.
        completeLogin(req).then(
            success => true,
            error => {
                // If for some reason we couldn't get the users meta or capabilities,
                // then consider them logged out. Most likely is the auth token has been rejected.
                AppDispatcher.handleViewAction({
                    actionType: AuthConstants.AUTH_DEAUTHORIZE,
                });
                // Ensure the UserStore is also emptied, if we don't have a token, we're not logged in.
                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_LOGOUT,
                });
            }
        );
    },

    /**
     * Since all this front-end code is in untrusted land, we have no way of
     * storing a secret key. We also need to be able to positively identify ourselves
     * to the API as someone who is allowed to register.
     *
     * So registration uses a broker who has a key, and will use their key in
     * provided their internal rate limiting has not been exceeded.
     */
    register: function(user) {
        const distinct_id = Analytics.getDistinctId();
        const initial_referrer = Analytics.getInitialReferrer();
        const time_zone = moment.tz.guess();
        const m_var = Analytics.getVisitUtmParams();
        const visit_utm_params = m_var.campaign ? {
            utm_source: m_var.source,
            utm_campaign: m_var.campaign,
            utm_medium: m_var.medium,
            utm_content: m_var.content,
        } : null;

        return new Promise((resolve, reject) => {
            fetch('/register', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=user/1'},
                body: JSON.stringify({...user, distinct_id, initial_referrer, time_zone, visit_utm_params}),
                credentials: 'same-origin',
            }).then(response => {
                if (response.ok) {
                    // Resolve promise to JSON, pass to complete login function
                    return response.json().then(auth => {
                        completeLogin(auth, true).then(resolve);
                    });
                }

                return response.json().then(reject);
            }).catch(error => {
                // @todo - need to know how to trigger this so we can handle it
                reject(error);
            });
        });
    },

    loginWithCredentials: function(email, password, tfa_token = null, is_private = false) {
        const payload = {email, password};

        const tfa_auth = AuthStore.getTfaAuth();

        // Do we have a tfa_auth cookie? Add it to the payload.
        if (tfa_auth) {
            payload.tfa_auth = tfa_auth;
        }

        if (tfa_token) {
            payload.tfa_token = tfa_token;
        }

        if (is_private) {
            payload.is_private = true;
        }

        return new Promise((resolve, reject) => {
            fetch(getConfig('users_api') + '/authenticate', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=user/credentials/1'},
                body: JSON.stringify(payload),
            }).then(
                response => {
                    if (response.ok) {
                        // Resolve promise to JSON, pass to complete login function
                        return response.json().then(auth => {
                            completeLogin(auth).then(resolve);
                        });
                    }

                    return response.json().then(reject);
                },
                error => false
            );
        });
    },

    loginWithAuthzToken: function(token) {
        return new Promise((resolve, reject) => {
            fetch(getConfig('users_api') + '/authenticate', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=authentication/1'},
                body: JSON.stringify({token}),
            }).then(response => {
                if (response.ok) {
                    // Resolve promise to JSON, pass to complete login function
                    return response.json().then(auth => {
                        completeLogin(auth).then(resolve);
                    });
                }

                return response.json().then(reject);
            }).catch(error => {
                // @todo - need to know how to trigger this so we can handle it
                reject(error);
            });
        });
    },

    loginWithPatientConfirmToken: function(token) {
        return new Promise((resolve, reject) => {
            fetch(getConfig('users_api') + '/accept-patient-invite', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=confirmation/1'},
                body: JSON.stringify({token}),
            }).then(
                response => {
                    if (response.ok) {
                        // Resolve promise to JSON, pass to complete login function
                        return response.json().then(({token}) => {
                            AuthActions.loginWithAuthzToken(token).then(resolve);
                        });
                    }

                    return response.json().then(reject);
                },
                error => false
            );
        });
    },

    loginWithDietitianConfirmToken: function(token) {
        return new Promise((resolve, reject) => {
            fetch(getConfig('users_api') + '/accept-dietitian-invite', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; schema=confirmation/1',
                },
                body: JSON.stringify({token}),
            }).then(
                response => {
                    if (response.ok) {
                        // Resolve promise to JSON, pass to complete login function
                        return response.json().then(({token}) => {
                            AuthActions.loginWithAuthzToken(token).then(resolve);
                        });
                    }

                    return response.json().then(reject);
                },
                error => reject(error)
            );
        });
    },

    loginWithAmazon: function(amazonResponse) {
        const distinct_id = Analytics.getDistinctId();

        // Sometimes the amazon response will have weird shit in it, we only want some specific
        // fields from it however.
        const { access_token, expires_in, scope, status, token_type, merchant_ref } = amazonResponse;

        return new Promise((resolve, reject) => {
            fetch(getConfig('users_api') + '/authenticate', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=amazon/login/1'},
                body: JSON.stringify({access_token, expires_in, scope, status, token_type, merchant_ref, distinct_id}),
            }).then(response => {
                if (response.ok) {
                    return response.json().then(auth => {
                        completeLogin(auth).then(resolve);
                    });
                }

                return response.json().then(error => reject(error.message));
            }).catch(error => {
                reject(error.message);
            });
        });
    },

    loginWithVirtualGym: function(vgResponse) {
        const authData = {
            'provider': 'vg',
            'virtuagymMemberId': vgResponse['virtuagym-member-id'],
            'clubId': vgResponse['club-id'],
            'externalId': vgResponse['external-id'],
            'clubMemberId': vgResponse['club-member-id'],
            'memberEmail': vgResponse['member-email'],
            'sig': vgResponse['sig'],
            'returl': vgResponse['returl'],
            'timestamp': vgResponse['timestamp'],
        };

        return new Promise((resolve, reject) => {
            fetch(getConfig('users_api') + '/authenticate', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=vg/login/1'},
                body: JSON.stringify(authData),
            }).then(response => {
                if (response.ok) {
                    return response.json().then(auth => {
                        completeLogin(auth).then(resolve);
                    });
                }

                return response.json().then(error => reject(error.message));
            }).catch(error => {
                reject(error.message);
            });
        });
    },

    loginWithOpenId: function(response) {
        const authData = {
            'provider': 'openid',
            'client_id': response['client_id'],
            'code': response['code'],
        };

        return new Promise((resolve, reject) => {
            fetch(getConfig('users_api') + '/authenticate', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=sso/openid/1'},
                body: JSON.stringify(authData),
            }).then(response => {
                if (response.ok) {
                    return response.json().then(auth => {
                        completeLogin(auth).then(resolve);
                    });
                }

                return response.json().then(error => reject(error.message));
            }).catch(error => {
                reject(error.message);
            });
        });
    },

    generateOauthToken: function(response) {
        const authData = {
            'client_id': response ? response['client_id'] : null,
            'redirect_uri': response ? response['redirect_uri'] : null
        };

        return new Promise((resolve, reject) => {
            AuthStore.fetch(UserStore.getLinks().self + '/generate-oauth-code', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(authData),
            }).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },

    linkAccounts: function(response) {
        const authData = {
            'client_id': response ? response['client_id'] : null,
            'redirect_uri': response ? response['redirect_uri'] : null
        };

        return new Promise((resolve, reject) => {
            AuthStore.fetch(UserStore.getLinks().self + '/link-accounts', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(authData),
            }).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },

    authenticate: function(auth) {
        AppDispatcher.handleViewAction({
            actionType: AuthConstants.AUTH_AUTHENTICATE,
            auth
        });
    },

    reauthenticate: function() {
        const authz = AuthStore.getRefresh();

        if (!authz) {
            return Promise.reject();
        }

        return AuthActions.loginWithAuthzToken(authz);
    },

    deauthorize: function() {
        AppDispatcher.handleViewAction({
            actionType: AuthConstants.AUTH_DEAUTHORIZE,
        });

        AppDispatcher.handleViewAction({
            actionType: UserConstants.USER_LOGOUT
        });
    },

    enableTwoFactor: function(tfa_token) {
        return AuthStore.fetch(UserStore.getLinks().tfa, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=enable/tfa/1'},
            body: JSON.stringify({tfa_token})
        }).then(
            success => {
                const user = {
                    ...UserStore.getUser(),
                    is_tfa_enabled: true,
                };

                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_UPDATE_META,
                    user,
                });

                return success;
            }
        );
    },

    disableTwoFactor: function(tfa_token) {
        return AuthStore.fetch(UserStore.getLinks().tfa, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json; schema=disable/tfa/1'},
            body: JSON.stringify({tfa_token})
        }).then(
            success => {
                const user = {
                    ...UserStore.getUser(),
                    is_tfa_enabled: false,
                };

                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_UPDATE_META,
                    user,
                });

                return success;
            }
        );
    },

    validateTokens: function(tokens) {
        return AuthStore.fetch(getConfig('users_api') + '/tokens/validate', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=collection/jwt/1'},
            body: JSON.stringify({tokens})
        }, true).then(
            success => {
                const user = {
                    ...UserStore.getUser(),
                    is_tfa_enabled: false,
                };

                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_UPDATE_META,
                    user,
                });

                return success;
            }
        );
    },
};

export default AuthActions;
