'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import UserStore from '../stores/UserStore';
import Footer from '../components/Widgets/footer/Footer.react';

import styles from './Admin.scss'

export default class Admin extends Component {

    static childContextTypes = {
        user: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            user: UserStore.getUser(),
        };
    }

    getChildContext = () => {
        const { user } = this.state;
        return { user };
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        this.setState({
            user: UserStore.getUser(),
        });
    }

    render = () => {
        const { user } = this.state;

        if (!(user && user.role === 'admin')) {
            return (
                <div className="admin-tools">
                    <Helmet title="Permission Denied | EatLove" />
                    <section className="permission-denied">
                        Sorry, this area is for EatLove staff only.
                    </section>
                    <Footer />
                </div>
            );
        }

        return (
            <div className="admin-tools">
                <Helmet title="Administration | EatLove" />
                {this.props.children}
                <Footer />
            </div>
        );
    }
}
