'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import { fetchDocumentsById } from '../../../../../utils/Content';

import ImgResized from '../../../../Widgets/ImgResized.react';

import './RecipeCard.scss';
import './CardAbstract.scss';

export default class RecipeCard extends Component {

    static contextTypes = {
        user: PropTypes.object,

        showRecipeDetails: PropTypes.func,
        onSelectRecipe: PropTypes.func,
        addSwapContext: PropTypes.object,
    }

    onSelectRecipe = () => {
        const { recipe } = this.props;
        const { onSelectRecipe, addSwapContext } = this.context;
        const { params: { sort_by, sort_order }, searchRanking} = this.props;

        onSelectRecipe(recipe, addSwapContext.participants, undefined, undefined, {searchRanking, sortBy: sort_by, sortOrder: sort_order, source: 'Smart Choices'});
    }

    render = () => {
        const { recipe } = this.props;
        const { user, showRecipeDetails } = this.context;
        const { capabilities } = user || {};

        return (
            <div className="card-abstract quick-recipe-card">
                <ImgResized className="card-image" src={recipe.image} width={470} height={356} onClick={() => showRecipeDetails(recipe)} />

                {(recipe.tags || []).includes('Grab & Go') ?
                    <div className="card-overlay" onClick={() => showRecipeDetails(recipe)} >
                        Store Bought
                    </div>
                : null}

                <div className="card-info" onClick={() => showRecipeDetails(recipe)}>
                    <h3><em>{recipe.title}</em></h3>

                    <button onClick={this.onSelectRecipe}>
                        select meal
                        {(recipe.protection !== 'public' && !capabilities?.meal_planner) ? <i className="icon-lock" /> : null}
                    </button>
                </div>
            </div>
        );
    }
}
