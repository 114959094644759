'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import uuid from 'uuid';

import Combobox from '../../../../pro/components/Widgets/Combobox.react';

import AuthStore from '../../../../stores/AuthStore';

import { getConfig } from '../../../../utils/Env';
import { fetchDocumentsById, updateCachedDocuments } from '../../../../utils/Content';

import './TaxonomyFilter.scss';

export default class BrandFilter extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            terms: '',
            results: [],
        };

        this.debounceSearchBrands = debounce(this.searchBrands, 350);
    }

    componentDidMount = async () => {
        const { params } = this.props;

        // Do we have a brands filter?
        if (!params?.filters?.brand_uuid) {
            return;
        }

        this.loadBrand(params.filters.brand_uuid);
    }

    loadBrand = async (uuid) => {
        this.setState({loading: true});

        const documents = await fetchDocumentsById([uuid]);
        const brand = documents[0];

        if (!brand) {
            this.setState({loading: false, synced: true, alertMsg: 'Brand not found'});
            return;
        }

        this.setState({brand, terms: brand.title, loading: false, dirty: false}, this.syncAssets);

        if (this.refs.combo && this.refs.combo.setTypeAhead) {
            this.refs.combo.setTypeAhead(brand.title);
        }
    }

    removeBrandFilter = () => {
        const { params, onChangeParams } = this.props;

        delete params.filters.brand_uuid;

        onChangeParams(params);

        this.setState({terms: ''});

        if (this.refs.combo && this.refs.combo.setTypeAhead) {
            this.refs.combo.setTypeAhead('');
        }
    }

    searchKey = null;

    searchBrands = async () => {
        const { terms } = this.state;

        const params = {
            types: ['brand'],
            terms,
        };

        const localKey = this.searchKey = uuid.v4().substring(0, 8);

        const results = await AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        });

        updateCachedDocuments(results.elements);

        // No longer relevant results got returned, ignore them.
        if (localKey !== this.searchKey) {
            return;
        }

        this.setState({results: results.elements});
    }

    onSelectOption = (uuid) => {
        const { params, onChangeParams } = this.props;

        params.filters.brand_uuid = uuid;

        onChangeParams(params);
        this.loadBrand(uuid);
    }

    onChangeTerms = (terms) => {
        this.setState({terms}, this.debounceSearchBrands);
    }

    render() {
        const { terms, results } = this.state;
        const { params } = this.props;

        const brandOpts = results.map(brand => ({value: brand.uuid, label: brand.title}));

        return (
            <div className="taxonomy-filter">
                <Combobox defaultClassName="el-combobox-container"
                    options={brandOpts}
                    onChangeTerms={this.onChangeTerms}
                    onSelectOption={this.onSelectOption}
                    value={terms} ref="combo"
                    placeholder="Search for a brand" />

                <button className="remove-taxonomy-filter-btn" onClick={this.removeBrandFilter}><i className="icon-close-x" /></button>
            </div>
        );
    }
}
