'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import nodeUuid from 'uuid';
import $ from 'jquery';

import ImgResized from '../ImgResized.react';
import { getAssetsForBoards, getImageForBoard } from '../../../utils/Boards';
import { getBackgroundImageUrl } from '../../../utils/Image';

import BoardStore from '../../../stores/BoardStore';
import BoardActions from '../../../actions/BoardActions';

import Analytics from '../../../utils/Analytics';

import styles from './BoardPicker.scss';

export default class BoardPicker extends Component {
    static propTypes = {
        closePopover: PropTypes.func.isRequired,

        recipe: PropTypes.object,
        plan: PropTypes.object,
        combo: PropTypes.object,
        food: PropTypes.object,
        collection: PropTypes.object,
    };

    static contextTypes = {
        onAfterUnfavorite: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            // A copy of the list of the boards the resource is currently in, must be a copy
            boards: BoardStore.getBoards().slice(0),
            isCreateBoardOpen: false,

            recipes: {},
            foods: {},
            plans: {},
            combos: {},
            foods: {},
            collections: {},
            synced: false,
        };
    }

    componentDidMount = () => {
        BoardStore.addChangeListener(this.onBoardStoreUpdate);

        this.synchronizeAssets();
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (!nextProps.isOpen) {
            this.setState({isCreateBoardOpen: false});
        }
    }

    componentWillUnmount = () => {
        BoardStore.removeChangeListener(this.onBoardStoreUpdate);
    }

    onBoardStoreUpdate = () => {
        const { uuid } = this.getResourceMeta();

        this.setState({
           boards: BoardStore.getBoards().slice(0),
        }, this.synchronizeAssets);
    }

    synchronizeAssets = () => {
        const { boards } = this.state;

        this.setState({synced: false});

        getAssetsForBoards(boards).then(({recipes, plans, combos, foods, collections}) => {
            this.setState({recipes, plans, combos, foods, collections, synced: true});
        });
    }

    getResourceMeta = () => {
        const { recipe, plan, combo, food, collection } = this.props;

        if (recipe) {
            return {
                uuid: recipe.uuid,
                title: recipe.title,
                image: recipe.image,
                type: recipe.type,
                content: recipe,
            };
        }

        if (plan) {
            return {
                uuid: plan.uuid,
                title: plan.title,
                image: plan.image,
                type: plan.type,
                content: plan,
            };
        }

        if (combo) {
            return {
                uuid: combo.uuid,
                title: combo.title,
                image: combo.image,
                type: combo.type,
                content: combo,
            };
        }

        if (food) {
            return {
                uuid: food.uuid,
                title: food.pretty_name || food.name,
                image: food.image,
                type: food.type,
                content: food,
            };
        }

        if (collection) {
            return {
                uuid: collection.uuid,
                title: collection.title,
                image: collection.image,
                type: collection.type,
                content: collection,
            };
        }

        return {
            uuid: null,
            title: null,
            image: null,
            type: null,
            content: null,
        };
    }

    isResourceInBoard = (board) => {
        const { uuid } = this.getResourceMeta();

        return (board.contents || []).filter(content => content.resource_id === uuid).length > 0;
    }

    removeFromBoard = (ev) => {
        const { boards } = this.state;
        const { closePopover } = this.props;
        const { onAfterUnfavorite } = this.context;

        const { uuid, type, content } = this.getResourceMeta();

        ev.stopPropagation();

        boards.forEach(board => {
            if (this.isResourceInBoard(board)) {
                const itemsToRemove = (board.contents || []).filter(content => content.resource_id === uuid);

                BoardActions.removeFromBoard(board, itemsToRemove);
                
                onAfterUnfavorite && onAfterUnfavorite(itemsToRemove);

                Analytics.removeFavorite(board, content);
            }
        });

        

        closePopover();
    }


    render() {
        const { closePopover } = this.props;
        const { title, image } = this.getResourceMeta();

        return (
            <div className="board-picker">
                <header>
                    <h4>Remove from your favorites?</h4>
                </header>

                <footer>
                    <button className="close-btn" onClick={this.removeFromBoard}>Yes</button>
                    <button className="cancel-btn" onClick={closePopover}>No</button>
                </footer>
            </div>
        );
    }
}
