'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import Modal from 'react-modal';
import moment from 'moment';

import ImgResized from '../../../../components/Widgets/ImgResized.react';
import Dropdown from '../../Widgets/Dropdown.react';
import Analytics from '../../../../utils/Analytics';
import UserStore from '../../../../stores/UserStore';

import modalStyles from '../../../../jsx-styles/modals';
import defaultImages from '../../../../tables/cover-images.js';

import './PlanToPdfButton.scss';

export default class PlanToPdfButton extends Component {

    static propTypes = {
        button: PropTypes.node,
        classes: PropTypes.string,
        plan: PropTypes.object,
        patient: PropTypes.object,
    };

    static contextTypes = {
        showUpgradeForm: PropTypes.func,
        router: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        const { plan, patient } = props;

        const params = {
            plan_uuid: plan.uuid,
        };

        let cover_images = defaultImages.slice();

        if (patient) {
            params.patient_uuid = patient.uuid;

            if (patient.cover_image) {
                params.cover_image = patient.cover_image;
                if (!cover_images.includes(patient.cover_image)) {
                    cover_images.push(patient.cover_image);
                }
            }

            const { practice } = UserStore.getUser();

            if (practice && practice.cover_image) {
                params.cover_image = params.cover_image || practice.cover_image;

                if (!cover_images.includes(practice.cover_image)) {
                    cover_images.push(practice.cover_image);
                }
            }

            if (patient && patient.hide_nutrition) {
                params.hide_nutrition = 1;
            }
        }

        const user = UserStore.getUser();
        const canPrintPlans = user && user.capabilities && user.capabilities.print_plan;

        this.state = {
            user,
            params,
            canPrintPlans,

            cover_images,
        }
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        const user = UserStore.getUser();
        const canPrintPlans = user?.capabilities?.print_plan;

        this.setState({user, canPrintPlans});
    }

    toggleParam = (key) => {
        const { params } = this.state;

        if (params[key]) {
            delete params[key];
        } else {
            params[key] = 1;
        }

        this.setState({params});
    }

    setIngredientMode = (mode) => {
        const { params } = this.state;

        if (!mode) {
            delete params.ingr_mode;
        } else {
            params.ingr_mode = mode;
        }

        this.setState({params});
    }

    trackPdfDownload = () => {
        Analytics.downloadMealPlanPDF({
            'Patient UUID': this.state?.params?.patient_uuid
        });
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    setCoverImage = (url) => {
        const { params } = this.state;

        params.cover_image = url;

        this.setState({params});
    }

    subscriptionRequired = () => {
        const { showUpgradeForm } = this.context;

        showUpgradeForm({feature: 'print_plan'});
    }

    renderPdfDialog = () => {
        const { params, cover_images, isModalOpen, canPrintPlans } = this.state;

        if (!isModalOpen) {
            return null;
        }

        const using = params.cover_image || cover_images[0];

        return (
            <Modal isOpen={true}
                closeModal={this.closeModal}
                contentLabel="Meal Plan Print Options"
                style={modalStyles.largeSquareModal}
                className="dialog-form-modal plan-to-pdf-modal"
                overlayClassName="modal-confirmation-overlay"
                closeTimeoutMS={250}>

                <section className="modal-container plan-to-pdf-container">
                    <header>
                        <button onClick={this.closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="modal-scroll-container">
                        <div className="plan-to-pdf-form">
                            <h1>Meal Plan Print Options</h1>

                            <h2>Include:</h2>
                            <div className="toggle-group">
                                <button className="toggle-btn" data-selected={params.hide_cover ? false : true} onClick={() => this.toggleParam('hide_cover')}>
                                    Cover Page
                                </button>

                                <button className="toggle-btn" data-selected={params.hide_care ? false : true} onClick={() => this.toggleParam('hide_care')}>
                                    Care Plan
                                </button>

                                <button className="toggle-btn" data-selected={params.hide_grocery ? false : true} onClick={() => this.toggleParam('hide_grocery')}>
                                    Grocery List
                                </button>

                                <button className="toggle-btn" data-selected={params.hide_grid ? false : true} onClick={() => this.toggleParam('hide_grid')}>
                                    Meal Schedule Grid
                                </button>

                                <button className="toggle-btn" data-selected={params.hide_photos ? false : true} onClick={() => this.toggleParam('hide_photos')}>
                                    Recipe Photos
                                </button>

                                <button className="toggle-btn" data-selected={params.hide_dtl ? false : true} onClick={() => this.toggleParam('hide_dtl')}>
                                    Day in the Life
                                </button>
                            </div>

                            <h2>Ingredients Options:</h2>
                            <div className="toggle-group">
                                <button className="toggle-btn radio-btn" data-selected={params.ingr_mode == 'default' || !params.ingr_mode} onClick={() => this.setIngredientMode()}>
                                    Cups
                                </button>

                                <button className="toggle-btn radio-btn" data-selected={params.ingr_mode == 'grams'} onClick={() => this.setIngredientMode('grams')}>
                                    Grams
                                </button>

                                <button className="toggle-btn radio-btn" data-selected={params.ingr_mode == 'ounces'} onClick={() => this.setIngredientMode('ounces')}>
                                    Ounces
                                </button>
                            </div>

                            <h2>Cover Image:</h2>
                            <ul className="cover-image-picker">
                                {cover_images.map((url, i) => {
                                    return (
                                        <li key={i} data-selected={using === url} onClick={() => this.setCoverImage(url)}>
                                            <ImgResized src={url} width={225} />
                                        </li>
                                    );
                                })}
                            </ul>

                        </div>
                    </div>

                    <footer className="modal-action-footer">
                        <button className="cancel-btn" onClick={this.closeModal}>Close</button>
                        {!canPrintPlans ?
                            <button className="ok-btn" onClick={this.subscriptionRequired}>Generate <i className="icon-lock" /></button>
                        : null}
                        {canPrintPlans ?
                            <a target="_blank" onClick={this.trackPdfDownload} href={'/plan-to-pdf?' + qs.stringify(params)}>
                                <button className="ok-btn">Generate</button>
                            </a>
                        : null}
                    </footer>
                </section>
            </Modal>
        );
    }


    render = () => {
        const { classes, button } = this.props;

        return (
            <>
                  <button className={classes || "ok-btn"}  onClick={this.openModal}>{button || <i className="icon-print" />}</button>
                {this.renderPdfDialog()}
            </>
        );
    }
}
