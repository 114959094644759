'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Select from '../../pro/components/Widgets/Select.react';
import NutrientEditor from '../Admin/Brands/Products/NutrientEditor.react';
import EditImage from '../Recipes/Editor/EditImage.react';

import { getConfig } from '../../utils/Env';
import { inquire } from '../../utils/Enforcer';
import Analytics from '../../utils/Analytics';
import { getNutrNosForProfile } from '../../pro/utils/Patients';
import { createNewDocument, fetchDocumentsById, updateCachedDocuments } from '../../utils/Content';
import { roundForHumans } from '../../utils/Math';
import { scrollTextFieldIntoView } from '../../utils/Keyboard';

import allCategories from '../../tables/grocery-categories';
import allNutrients from '../../tables/nutrients';

import BoardStore from '../../stores/BoardStore';
import BoardActions from '../../actions/BoardActions';
import UserStore from '../../stores/UserStore';
import AuthStore from '../../stores/AuthStore';
import './Editor.scss';
import Alert from '../Widgets/Alert/Alert.react';
import ConfirmNutritionModal from '../Planner/Modals/ConfirmNutritionModal.react';

export default class FoodEditor extends Component {
    static propTypes = {
        uuid: PropTypes.string,
        profile: PropTypes.object,
        saveDirtyBtnText: PropTypes.string,
        saveCleanBtnText: PropTypes.string,
        introMessage: PropTypes.node,
        defaultFoodName: PropTypes.string,
        defaultBarcode: PropTypes.string,
        defaultLanguage: PropTypes.string,
        defaultBrandName: PropTypes.string,
        defaultBrandUuid: PropTypes.string,
        defaultCategory: PropTypes.string,
        defaultProductType: PropTypes.string,
        retryBarcodeScan: PropTypes.func,
        onSaveFood: PropTypes.func,
        setSaving: PropTypes.func,
        closeModal: PropTypes.func,
        scrollToOffset: PropTypes.func,
    };

    static defaultProps = {
        saveDirtyBtnText: 'save food',
        saveCleanBtnText: 'close',

        defaultBarcode: '',
        defaultLanguage: 'en',
    };

    static contextTypes = {
        profile: PropTypes.object,
        synchronizeAssets: PropTypes.func,
        confirm: PropTypes.func,
        isMobile: PropTypes.bool,
        isAndroid: PropTypes.bool,
    };

    static childContextTypes = {
        food: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        const { profile, defaultBarcode } = props;


        const user = UserStore.getUser();
        const units_mode = (profile && profile.units_mode) || (user && user.units_mode) || 'english';

        this.state = {
            screen: 'editor', // can also be 'intro'

            food: null,
            user,

            units_mode,
            package_size: '',
            package_size_unit: units_mode === 'metric' ? 'g' : 'oz',

            serving_amount: 1,
            serving_description: '',
            serving_unit: 'g',
            serving_size: units_mode == 'metric' ? 100 : 1,
            serving_size_unit: units_mode == 'metric' ? 'g' : 'oz',
            gtin_upc: defaultBarcode,

            canCancel: true,
            areNutrientsVisible: false,
            pictureUploadsVisible: true,
            isNewFood: false,
            showPhotoSubmissionTips: false,
            workingScanningLabel: false,
        };
    }

    componentDidMount = () => {
        this.loadFood();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.defaultBarcode !== this.props.defaultBarcode) {
            this.setState({ gtin_upc: this.props.defaultBarcode });
        }
    }

    stopScanning = () => {
        const { abortController } = this.state;
        abortController && abortController.abort('abortRequest');
        this.setState({ workingScanningLabel: false });
    };

    loadFood = () => {
        const {
            uuid,
            defaultLanguage = 'en',
            defaultFoodName = '',
            defaultBrandName,
            defaultBrandUuid,
            defaultCategory,
            defaultProductType,
            introMessage
        } = this.props;
        const { profile } = this.context;

        if (!uuid) {
            this.setState({
                screen: introMessage ? 'intro' : 'editor',
                food: {
                    language: defaultLanguage || profile?.language || 'en',
                    name: defaultFoodName,
                    brand_name: defaultBrandName,
                    brand_uuid: defaultBrandUuid,
                    category: defaultCategory,
                    product_type: defaultProductType,
                    nutrients: { values: {}, available: {} },
                    refuse: 0,
                    serving_unit: 'g',
                    exclude_from_groceries: defaultCategory === 'Restaurant Meal',
                },
                dirty: true,
                canWrite: true,
                canPublish: false,
                areNutrientsVisible: false,
                pictureUploadsVisible: true,
                isNewFood: true,
            });
            return;
        }

        // First, inquire to see if we have write and publish permission.
        const inquiries = [
            { action: 'publish', resource: uuid },
            { action: 'write', resource: uuid },
        ];

        inquire(inquiries).then((decisions) => {
            if (!decisions) {
                this.setState({ canWrite: false });
                return;
            }

            let canPublish = decisions.filter((d) => d.action == 'publish')[0].decision === 'permit';
            let canWrite = decisions.filter((d) => d.action == 'write')[0].decision === 'permit';

            this.setState({ canPublish, canWrite }, () => {
                this.loadFoodData(uuid);
            });
        });
    };

    loadFoodData = async (uuid, food) => {
        const { user, units_mode } = this.state;
        const hideNutritents = food ? true : false;
        this.setState({ loading: true });
        let package_description;

        if (uuid) {
            const first = await fetchDocumentsById([uuid]);
            food = first[0];
        }

        let serving_unit = food.serving_unit;
        let serving_size = food.serving_unit === 'ml' ? food.milliliters_per_serving : food.grams_per_serving;
        let serving_size_unit = '';
        let package_size = '';
        let serving_amount = 1;
        let serving_description = (food.default_unit && food.default_unit.description) || '';
        let gtin_upc = (food.gtin_upc && food.gtin_upc[0]) || this.state.gtin_upc || '';
        let areNutrientsVisible = !hideNutritents && Object.keys(food.nutrients.values).length > 0;
        let pictureUploadsVisible = !areNutrientsVisible;

        // Are we in english mode? Convert serving_size and package_size to ounces
        if (units_mode === 'english') {
            serving_size =
                serving_unit === 'ml'
                    ? roundForHumans(serving_size / 29.5735) // milliliters per fluid oz
                    : roundForHumans(serving_size / 28.3495); // grams per ounce
            serving_size_unit = serving_unit === 'ml' ? 'fl oz' : 'oz';
        } else {
            serving_size_unit = serving_unit === 'ml' ? 'ml' : 'g';
        }

        if (!food) {
            this.setState({ food: null, loadingError: 'Ingredient not found' });
            return;
        }

        if (uuid && !(food && food.type === 'food')) {
            this.setState({ food: null, loadingError: 'Incorrect document type' });
            return;
        }

        if (uuid && food.status === 'live') {
            this.setState({ food: null, loadingError: 'Cannot edit a live ingredient with this tool' });
            return;
        }

        if (uuid && food.owner !== user.uuid) {
            this.setState({ food: null, loadingError: 'You are not the owner of this ingredient and cannot edit it' });
            return;
        }

        if (food.units && food.units.length) {
            serving_amount = food.default_unit.amount || 1;
        }

        if (food.packaging && food.packaging.length && food.units && food.units.length) {
            package_size = serving_unit === 'ml' ? food.packaging[0].milliliters : food.packaging[0].grams;

            if (units_mode === 'english') {
                package_size =
                    serving_unit === 'ml'
                        ? roundForHumans(package_size / 29.5735)
                        : roundForHumans(package_size / 28.3495);
            }
        }

        this.setState({
            food,
            serving_unit,
            screen: 'editor',
            serving_size_unit,
            serving_amount,
            serving_description,
            serving_size,
            package_description,
            package_size,
            units_mode,
            gtin_upc,
            loading: false,
            areNutrientsVisible,
            pictureUploadsVisible,
        });
    };

    applyScannedLabel = (nextFood) => {
        let { food } = this.state;

        food.tags = food.tags || [];

        // if (nextFood.tags.includes('AI Scanned Product Title')) {
        //     food.name = nextFood.name;
        // }

        // if (nextFood.tags.includes('AI Scanned Brand Name') && !food.brand_uuid) {
        //     food.brand_name = nextFood.brand_name;
        // }

        if (nextFood.tags.includes('AI Scanned Nutrition') && nextFood.nutrients) {
            let { nutrients = {} } = food;

            nutrients.available = nutrients.available || {};
            nutrients.values = nutrients.values || {};

            Object.keys(nextFood.nutrients.available).forEach((nutrNo) => {
                if (nextFood.nutrients.available[nutrNo]) {
                    nutrients.available[nutrNo] = true;
                    nutrients.values[nutrNo] = nextFood.nutrients.values[nutrNo];
                }
            });

            food.nutrients = nutrients;
        }

        if (nextFood.tags.includes('AI Scanned Serving Size')) {
            food.serving_description = nextFood.serving_description;
            food.serving_unit = nextFood.serving_unit;
            food.grams_per_serving = nextFood.grams_per_serving;
            food.milliliters_per_serving = nextFood.milliliters_per_serving;
            food.units = nextFood.units;
            food.default_unit = nextFood.default_unit;
        }

        if (nextFood.tags.includes('AI Scanned Ingredients')) {
            food.ingredients_description = nextFood.ingredients_description;
        }

        if (nextFood.tags.includes('AI Scanned Package')) {
            food.packaging = nextFood.packaging;
        }

        food.tags = food.tags.concat(nextFood.tags);

        this.setState({ food }, () => this.loadFoodData(null, food));
    };

    getChildContext = () => {
        const { food } = this.state;

        return {
            food,
        };
    };

    openConfirmNutrition = () => {
        this.setState({ showNutritionInfoModal: true });
    };

    closeConfirmNutrition = () => {
        this.setState({ showNutritionInfoModal: false });
    };

    onChangeName = (ev) => {
        const { food } = this.state;
        food.name = ev.target.value;
        this.setState({ food, dirty: true });
    };

    translateErrorMessage = (message) => {
        if (message == 'Too Many Requests') {
            message = 'We’re sorry, you’ve reached the photo upload limit. Please try again later';
        }

        return (
            message ||
            "We couldn't retrieve nutrition details from this image. Please upload a clearer picture of the nutrition label for accurate results."
        );
    };

    scanLabel = async (imageUrl, labelImage = false) => {
        const { confirm, isMobile } = this.context;
        const { food, user } = this.state;

        let ac = null;
        if ('AbortController' in window) {
            ac = new AbortController();
            this.setState({ abortController: ac });
        }

        this.setState({ workingScanningLabel: true });

        let response;

        try {
            response = await AuthStore.fetch(getConfig('recipe_api') + '/scan-label', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;' },
                body: JSON.stringify({ imageUrl }),
            });
        } catch (error) {
            if (labelImage) {
                Analytics.failedFoodScan({
                    'Image URL': imageUrl,
                    Error: error?.message,
                    'Food UUID': food.uuid,
                    'User UUID': user.uuid,
                });

                confirm(
                    this.translateErrorMessage(error?.message),
                    (accept) => false,
                    (reject) => false,
                    { rejectText: null, acceptText: 'OK' }
                );
            }

            this.setState({ workingScanningLabel: false });

            return;
        }

        if (response?.food) {
            this.applyScannedLabel(response.food);
        } else if (response?.job_uuid) {
            let job = await AuthStore.fetch(
                getConfig('recipe_api') + '/jobs/' + response.job_uuid,
                {},
                false,
                false,
                ac
            );
            let governor = 500;

            // stop polling on mobile devices if exceeds 60 secs
            let polling = true;
            isMobile &&
                setTimeout(() => {
                    polling = false;
                }, 60000);

            while (job?.status !== 'completed' && job?.status !== 'failed' && governor-- > 0 && polling) {
                await new Promise((resolve) => setTimeout(resolve, 3000)); // sleep 3 seconds
                job = await AuthStore.fetch(getConfig('recipe_api') + '/jobs/' + response.job_uuid, ac);
            }

            if (job?.status === 'completed' && job?.output?.food) {
                this.applyScannedLabel(job?.output?.food, job?.uuid);
            } else if (polling) {
                if (labelImage) {
                    Analytics.failedFoodScan({
                        'Image URL': imageUrl,
                        Error: job?.output?.error,
                        'Food UUID': food.uuid,
                        'User UUID': user.uuid,
                    });

                    confirm(
                        this.translateErrorMessage(job?.output?.error),
                        (accept) => false,
                        (reject) => false,
                        { rejectText: null, acceptText: 'OK' }
                    );
                }
            }

            this.setState({ workingScanningLabel: false });
        }
    };

    onChangeImage = (url) => {
        const { food } = this.state;
        food.image = url;
        this.setState({ food, dirty: true });
        url && this.setState({ lastUploaded: 'image' });
        url && this.scanLabel(url);
    };

    onChangeNfpImage = (url) => {
        const { food } = this.state;
        food.nfp_image = url;
        this.setState({ food, dirty: true });
        url && this.setState({ lastUploaded: 'nfp_image' });
        url && this.scanLabel(url, true);
    };
    onChangeIngredientsImage = (url) => {
        const { food } = this.state;
        food.ingredients_image = url;
        this.setState({ food, dirty: true });
        url && this.setState({ lastUploaded: 'ingredients_image' });
        url && this.scanLabel(url);
    };

    onChangeBrandName = (ev) => {
        const { food } = this.state;
        food.brand_name = ev.target.value;
        this.setState({ food, dirty: true });
    };

    onChangeCategory = (category) => {
        const { food } = this.state;
        food.category = category;
        food.exclude_from_groceries = category === 'Restaurant Meal';
        this.setState({ food, dirty: true });
    };

    onChangePackageSize = (ev) => {
        this.setState({ package_size: ev.target.value, dirty: true });
    };

    onChangeServingDescription = (ev) => {
        this.setState({ serving_description: ev.target.value, dirty: true });
    };

    onChangeServingAmount = (ev) => {
        this.setState({ serving_amount: ev.target.value, dirty: true });
    };

    onChangeServingSize = (ev) => {
        this.setState({ serving_size: ev.target.value, dirty: true });
    };

    onChangeGtinUpc = (ev) => {
        this.setState({ gtin_upc: ev.target.value, dirty: true });
    };

    onChangeNutrients = (nutrients) => {
        const { food } = this.state;

        food.nutrients = nutrients;

        this.setState({ food, dirty: true });
    };

    setError(errorField, error) {
        const { onError } = this.props;
        this.setState({ errorField, error });
        onError && onError(error);
    }

    validate = () => {
        // First, we need to validate that we have everything we need.
        let { screen, food, serving_size, serving_amount } = this.state;

        food.name = (food.name || '').trim();

        if (!food.name) {
            this.setError('name', 'Food name is required');
            return false;
        }

        if (parseFloat(serving_amount) <= 0 || isNaN(parseFloat(serving_amount))) {
            this.setError('serving_amount', 'Serving amount is required');
            return false;
        }

        if (parseFloat(serving_size) <= 0 || isNaN(parseFloat(serving_size))) {
            this.setError('serving_size', 'Serving size is required');
            return false;
        }

        if (food.category === 'Restaurant Meal' && !food.brand_name) {
            this.setError('brand_name', 'Brand name is required');
            return false;
        }

        return true;
    };

    addFoodToFavorites = (food) => {
        const lastBoard = BoardStore.getDefaultBoard();
        const newItem = {
            resource_id: food.uuid,
            resource_type: food.type,
        };

        if (lastBoard.links) {
            // Board already exists, just add to it.
            BoardActions.addToBoard(lastBoard, [newItem]);
        } else {
            lastBoard.contents = lastBoard.contents || [];
            lastBoard.contents.push(newItem);

            // Board does not exist, create a whole new one
            BoardActions.upsertBoards([lastBoard]);
        }
    };

    shouldOverwriteFood = (food) => {
        const { user, canWrite } = this.state;

        if (!canWrite) {
            return false;
        }

        // We never overwrite a live food
        if (food.status === 'live') {
            return false;
        }

        // the document must be saved new
        if (!food.links || !food.uuid) {
            return false;
        }

        // We only ever overwrite our own foods
        return food.owner === user.uuid;
    };

    getFoodFromState = () => {
        let { food, screen, gtin_upc } = this.state;

        food.product_type = 'UGC';
        food.gtin_upc = gtin_upc ? [gtin_upc] : [];

        if (screen === 'editor') {
            let {
                units_mode,
                package_size,
                package_description,
                serving_amount,
                serving_description,
                serving_unit,
                serving_size,
                serving_size_unit,
            } = this.state;

            serving_size = parseFloat(serving_size);

            // per serving
            const milliliters = ['ml', 'fl oz'].includes(serving_size_unit)
                ? serving_size_unit === 'fl oz'
                    ? serving_size * 29.5735
                    : serving_size
                : null;

            const grams = ['g', 'oz'].includes(serving_size_unit)
                ? serving_size_unit === 'oz'
                    ? serving_size * 28.3495
                    : serving_size
                : null;

            // per package
            const pkg_milliliters = ['ml', 'fl oz'].includes(serving_size_unit)
                ? serving_size_unit === 'fl oz'
                    ? package_size * 29.5735
                    : package_size
                : null;

            const pkg_grams = ['g', 'oz'].includes(serving_size_unit)
                ? serving_size_unit === 'oz'
                    ? package_size * 28.3495
                    : package_size
                : null;

            if (serving_size) {
                food.serving_description = `${serving_amount} ${serving_description} (${serving_size} ${serving_size_unit})`;
                food.serving_unit = serving_unit;
                food.milliliters_per_serving = milliliters;
                food.grams_per_serving = grams;

                food.default_unit = {
                    amount: serving_amount,
                    description: serving_description || 'serving',
                    grams,
                    milliliters,
                };

                food.units = [
                    {
                        // the "serving size" unit
                        amount: serving_amount,
                        description: serving_description || 'serving',
                        grams,
                        milliliters,
                    },
                    {
                        // the "package size" unit
                        amount: 1,
                        description: package_description,
                        grams: pkg_grams,
                        milliliters: pkg_milliliters,
                    },
                ];

                food.packaging = [
                    {
                        description: package_description,
                        display_mode: 'prefix-of',
                        amount: 1,
                        grams: pkg_grams,
                        milliliters: pkg_milliliters,
                        mode: 'discrete',
                        units: units_mode,
                    },
                ];
            }
        }

        return food;
    };

    storeNewFood = async (food) => {
        food = await createNewDocument('food', food, 'food/1');

        return { food };
    };

    overwriteFood = async (food) => {
        const { uuid, links, created, last_updated, type, status, ...rest } = food;

        food = await AuthStore.fetch(getConfig('recipe_api') + links.self.href, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=food/1' },
            body: JSON.stringify(rest),
        });

        return { food };
    };

    onSaveFood = async () => {
        const { onSaveFood } = this.props;
        const { dirty, workingScanningLabel, abortController, isNewFood } = this.state;
        const { confirm, isMobile } = this.context;

        if (workingScanningLabel && isMobile) {
            confirm(
                <div>
                    <p>
                        Nutrition analysis is still in progress. If you cancel, your food will be saved without
                        nutrition information.
                    </p>
                </div>,
                (accept) => false,
                (reject) => {
                    abortController.abort('abortRequest');
                    this.setState({ workingScanningLabel: false });
                },
                { rejectText: 'Cancel Anyway', acceptText: 'Continue Analyzing Nutrition' }
            );
        }

        if (!dirty) {
            onSaveFood && onSaveFood(this.state.food);
            return;
        }

        if (!this.validate()) {
            return;
        }

        if (isNewFood && dirty) {
            this.openConfirmNutrition();
            return;
        }

        await this.save();
    };

    save = async () => {
        const { onSaveFood, setSaving } = this.props;
        const { synchronizeAssets } = this.context;

        this.setState({ saving: true, error: null, errorField: null });

        setSaving && setSaving(true);

        let food = this.getFoodFromState();

        let newState = {
            lastSaved: moment(),
            saveError: false,
            saving: false,
            canCancel: false,
            dirty: false,
        };

        let saved;

        if (this.shouldOverwriteFood(food)) {
            saved = await this.overwriteFood(food);
        } else {
            saved = await this.storeNewFood(food);

            newState.canWrite = true;

            this.addFoodToFavorites(saved.food);
        }

        food = saved.food;

        updateCachedDocuments([food]);
        synchronizeAssets && synchronizeAssets();

        this.setState({ food, ...newState }, this.resetLastSaved);

        setSaving && setSaving(false);

        onSaveFood(saved.food);
    };

    onCancel = () => {
        this.setState({ showNutritionInfoModal: false, areNutrientsVisible: true, pictureUploadsVisible: false });
    };

    onChangeServingSizeUnit = (serving_size_unit) => {
        let serving_unit = ['ml', 'fl oz'].includes(serving_size_unit) ? 'ml' : 'g';

        this.setState({ serving_size_unit, serving_unit, dirty: true });
    };

    retryBarcode = () => {
        const { closeModal, retryBarcodeScan } = this.props;

        retryBarcodeScan();
        closeModal();
    };

    renderIntro = () => {
        const { introMessage } = this.props;
        const { gtin_upc } = this.state;

        return (
            <div className="food-form-intro">
                {introMessage ? (
                    <div className="barcode-warning">
                        <i className="icon-barcode2" />
                        <label>
                            <p>No food found for barcode</p>
                            {gtin_upc}
                        </label>
                    </div>
                ) : null}
            </div>
        );
    };

    renderSubmissionTipsToggle = () => {
        const { showPhotoSubmissionTips } = this.state;
        return (
            <span
                className="submission-tips-toggle"
                onClick={() =>
                    this.setState((prevState) => ({ showPhotoSubmissionTips: !prevState.showPhotoSubmissionTips }))
                }
            >
                <button className="sub-action-btn">{`${showPhotoSubmissionTips ? 'Hide' : 'Show'} photo submission tips`}</button>
                <i className={showPhotoSubmissionTips ? 'icon-chevron-up' : 'icon-chevron-down'}></i>
            </span>
        );
    };

    renderPicsOrItDidntHappen = () => {
        const { food, errorField, showPhotoSubmissionTips, workingScanningLabel, lastUploaded } = this.state;
        const { isAndroid } = this.context;

        return (
            <div className="image-editor" data-error={errorField === 'image-editor'}>
                <p>Upload photos to automatically scan nutrition information for this food.</p>
                <div className="image-inputs">
                    <div className="image-input" data-error={errorField === 'image'}>
                        <EditImage
                            className="feather feather-camera"
                            uploading="uploading"
                            image={food.image}
                            imageLabel="Add an image"
                            onChangeImage={this.onChangeImage}
                            workingScanningLabel={workingScanningLabel}
                            stopScanning={this.stopScanning}
                            lastUploaded={lastUploaded === 'image'}
                            popupClassName="food-editor-photo-btn-popup"
                            showPopup={isAndroid}
                        />
                        <label>Front of item</label>
                    </div>

                    <div className="image-input" data-error={errorField === 'nfp_image'}>
                        <EditImage
                            className="feather feather-camera"
                            uploading="uploading"
                            image={food.nfp_image}
                            imageLabel="Add an image"
                            onChangeImage={this.onChangeNfpImage}
                            workingScanningLabel={workingScanningLabel}
                            stopScanning={this.stopScanning}
                            lastUploaded={lastUploaded === 'nfp_image'}
                            popupClassName="food-editor-photo-btn-popup"
                            showPopup={isAndroid}
                        />
                        <label>Nutrition facts label</label>
                    </div>

                    <div className="image-input" data-error={errorField === 'ingredients_image'}>
                        <EditImage
                            className="feather feather-camera"
                            uploading="uploading"
                            image={food.ingredients_image}
                            imageLabel="Add an image"
                            onChangeImage={this.onChangeIngredientsImage}
                            workingScanningLabel={workingScanningLabel}
                            stopScanning={this.stopScanning}
                            lastUploaded={lastUploaded === 'ingredients_image'}
                            popupClassName="food-editor-photo-btn-popup"
                            popupPosition="el-popup-top-right"
                            showPopup={isAndroid}
                        />
                        <label>Ingredients</label>
                    </div>
                </div>

                {!showPhotoSubmissionTips ? this.renderSubmissionTipsToggle() : null}

                {showPhotoSubmissionTips ? (
                    <div className="photo-tips">
                        <p>
                            <em>Picture submission tips</em>
                        </p>
                        <ul>
                            <li>
                                Make sure the package size, serving size, calories, fat, carbs and protein are readable
                                in your photos.
                            </li>
                            <li>Submit only photos that you take yourself.</li>
                            <li>
                                If the food label or package is ripped, torn, or creased, please find another package to
                                photograph.
                            </li>
                        </ul>
                    </div>
                ) : null}

                {showPhotoSubmissionTips ? this.renderSubmissionTipsToggle() : null}
            </div>
        );
    };

    onClickToggleButton = () => {
        const { scrollToOffset } = this.props;
        const { areNutrientsVisible, pictureUploadsVisible } = this.state;
        if (scrollToOffset) {
            scrollToOffset(this.toggleBtns.offsetTop + 100);
        }
        this.setState({ areNutrientsVisible: !areNutrientsVisible, pictureUploadsVisible: !pictureUploadsVisible });
    };

    renderFoodForm = (food) => {
        const {
            error,
            package_size,
            serving_amount,
            serving_description,
            serving_size,
            serving_size_unit,
            gtin_upc,
            errorField,
            showAll,
            areNutrientsVisible,
            pictureUploadsVisible,
            workingScanningLabel,
        } = this.state;
        const { profile } = this.context;

        const categoryOpts = allCategories.map((cat) => ({ value: cat.label, label: cat.label }));

        const image = food.image || food.nfp_image || food.ingredients_image;

        // Given the current profile, ensure that all the nutrients for that profile are visible
        const rxNutrNos = profile ? getNutrNosForProfile(profile) : [];
        let allowedNutrNos = [
            '208', // Calories
            '204', // Total Fat
            '606', // Saturated Fat
            // '645', // Monounsaturated Fat
            // '646', // Polyunsaturated Fat
            '605', // Trans Fat
            '601', // Cholesterol
            '307', // Sodium
            '306', // Potassium
            '305', // Phosphorus
            '205', // Carbs
            '291', // Fiber
            '269', // Sugars, Total
            'ASG', // Added Sugar
            '203', // Protein
            '324', // Vitamin D
            '301', // Calcium
            '303', // Iron
            ...rxNutrNos,
        ];

        if (showAll) {
            const otherNutrients = Object.keys(allNutrients).filter(
                (nutrNo) =>
                    !allowedNutrNos.includes(nutrNo) && allNutrients[nutrNo].Units && allNutrients[nutrNo].Units.length
            );
            allowedNutrNos = allowedNutrNos.concat(otherNutrients);
        }

        const unitOpts = [
            { label: 'g', value: 'g' },
            { label: 'oz', value: 'oz' },
            { label: 'fl oz', value: 'fl oz' },
            { label: 'ml', value: 'ml' },
        ];

        return (
            <div className="el-list-form">
                <div className="recipe-fields">
                    <div className="el-list-field el-labeled-input">
                        <div className="with-label" data-error={errorField === 'name'}>
                            <label>Food Name:</label>
                            <input
                                type="text"
                                value={food.name}
                                placeholder="ex. Noodle Soup"
                                onChange={this.onChangeName}
                                onFocus={scrollTextFieldIntoView}
                            />
                        </div>
                    </div>

                    <div className="el-list-field el-labeled-input">
                        <div className="with-label food-brand-name" data-error={errorField === 'brand_name'}>
                            <label>
                                Brand Name: {food.category !== 'Restaurant Meal' ? <em>(optional)</em> : null}{' '}
                            </label>
                            <input
                                type="text"
                                value={food.brand_name}
                                placeholder={food.category !== 'Restaurant Meal' ? 'ex. Campbells' : 'ex. Starbucks'}
                                onChange={this.onChangeBrandName}
                                onFocus={scrollTextFieldIntoView}
                            />
                        </div>
                        {errorField === 'brand_name' ? <p className="error-msg">{error}</p> : null}
                    </div>

                    <div className="el-list-field el-labeled-input">
                        <div className="with-label food-category" data-error={errorField === 'category'}>
                            <label>Category</label>
                            <Select
                                defaultClassName="el-select-container"
                                dropdownIcon="icon-down-arrow"
                                placeholder="select a category"
                                value={food.category}
                                options={categoryOpts}
                                onChange={this.onChangeCategory}
                            />
                        </div>
                        {errorField === 'category' ? <p className="error-msg">{error}</p> : null}
                    </div>

                    {food.category !== 'Restaurant Meal' ? (
                        <div className="el-list-field el-labeled-input">
                            <div className="with-label sizes-container package-size">
                                <label>Package Size</label>
                                <div className="with-label size" data-error={errorField === 'package_size'}>
                                    <input
                                        type="number"
                                        min="0"
                                        max="9999"
                                        placeholder="--"
                                        value={package_size}
                                        onChange={this.onChangePackageSize}
                                        onFocus={scrollTextFieldIntoView}
                                    />
                                    <Select
                                        defaultClassName="el-select-container"
                                        dropdownIcon="icon-down-arrow"
                                        className="unit-opts"
                                        options={unitOpts}
                                        value={serving_size_unit}
                                        onChange={this.onChangeServingSizeUnit}
                                    />
                                </div>
                                {errorField === 'package_size' ? <p className="error-msg">{error}</p> : null}
                            </div>
                        </div>
                    ) : null}

                    {food.category !== 'Restaurant Meal' ? (
                        <div className="el-list-field el-labeled-input">
                            <div className="with-label sizes-container serving">
                                <label>Serving Size</label>
                                <div className="with-label size">
                                    <input
                                        type="number"
                                        min="0"
                                        max="9999"
                                        placeholder="1"
                                        value={serving_amount}
                                        onChange={this.onChangeServingAmount}
                                        onFocus={scrollTextFieldIntoView}
                                    />
                                    <input
                                        className="serving-description"
                                        type="text"
                                        value={serving_description}
                                        placeholder="serving"
                                        onChange={this.onChangeServingDescription}
                                        onFocus={scrollTextFieldIntoView}
                                    />

                                    <span className="left-paren">{'('}</span>
                                    <input
                                        type="number"
                                        min="0"
                                        max="9999"
                                        placeholder="--"
                                        value={serving_size}
                                        onChange={this.onChangeServingSize}
                                        onFocus={scrollTextFieldIntoView}
                                    />
                                    <Select
                                        defaultClassName="el-select-container"
                                        dropdownIcon="icon-down-arrow"
                                        className="unit-opts"
                                        options={unitOpts}
                                        value={serving_size_unit}
                                        onChange={this.onChangeServingSizeUnit}
                                    />
                                    <span className="right-paren">{')'}</span>
                                </div>
                                {errorField === 'serving' ? <p className="error-msg">{error}</p> : null}
                            </div>
                        </div>
                    ) : null}

                    {food.category !== 'Restaurant Meal' ? (
                        <div className="el-list-field el-labeled-input">
                            <div className="with-label gtin-upc" data-error={errorField === 'gtin_upc'}>
                                <label>
                                    Barcode <em>(optional)</em>
                                </label>
                                <input
                                    type="text"
                                    pattern="\d*"
                                    placeholder="enter barcode"
                                    value={gtin_upc}
                                    onChange={this.onChangeGtinUpc}
                                    onFocus={scrollTextFieldIntoView}
                                />
                            </div>
                            {errorField === 'gtin_upc' ? <p className="error-msg">{error}</p> : null}
                        </div>
                    ) : null}
                </div>

                <div className="food-nutrition">
                    <div className="food-nutrition-header with-label">
                        <label>
                            {areNutrientsVisible ? (
                                <span>
                                    Nutrition Facts <em>(per serving)</em>
                                    <p className="nutrition-header-text">
                                        To have this food reflected in your daily nutrition calculations, please enter
                                        the nutrition information
                                    </p>
                                </span>
                            ) : (
                                <span>
                                    <p>
                                        Nutrition Information* <em> (optional)</em>
                                    </p>
                                    <p className="nutrition-header-text">
                                        To have this food reflected in your daily nutrition calculations, please enter
                                        the nutrition information
                                    </p>
                                </span>
                            )}
                        </label>

                        <div className="toggle-btn-group" ref={(el) => (this.toggleBtns = el)}>
                            <button
                                className="toggle-btn"
                                data-inactive={areNutrientsVisible && !pictureUploadsVisible}
                                onClick={this.onClickToggleButton}
                            >
                                <i className="feather feather-camera" />
                                Scan photos
                            </button>

                            <button
                                className="toggle-btn"
                                data-inactive={!areNutrientsVisible && pictureUploadsVisible}
                                onClick={this.onClickToggleButton}
                            >
                                <i className="icon-pencil3" />
                                Enter manually
                            </button>
                        </div>
                    </div>

                    {areNutrientsVisible ? (
                        workingScanningLabel ? (
                            <div className="editor-scanning-text">
                                <i className="icon-spinner" />
                                <p>Analyzing Nutritional Data...</p>
                            </div>
                        ) : (
                            <NutrientEditor
                                isRecipeEditor={true}
                                barcode={gtin_upc}
                                name={food.name}
                                image={image}
                                unitOpts
                                values={food.nutrients.values}
                                available={food.nutrients.available}
                                onChange={this.onChangeNutrients}
                                onChangeServingSizeUnits={this.onChangeServingSizeUnit}
                                allowedNutrNos={allowedNutrNos}
                            />
                        )
                    ) : null}

                    {pictureUploadsVisible && food ? this.renderPicsOrItDidntHappen(food) : null}
                </div>
            </div>
        );
    };

    renderNutritionConfirmationModal = () => {
        const { user, food, showNutritionInfoModal } = this.state;

        if (!showNutritionInfoModal) {
            return;
        }

        return (
            <ConfirmNutritionModal
                user={user}
                food={food}
                onOk={this.save}
                onCancel={this.onCancel}
                onClose={this.closeConfirmNutrition}
                okBtnText="Confirm Nutrition"
                cancelBtnText="Edit Nutrition"
            />
        );
    };

    render() {
        const { screen, food, loadingError } = this.state;

        return (
            <>
                <div className="food-editor">
                    {screen === 'intro' ? this.renderIntro() : null}
                    {screen === 'editor' && food ? this.renderFoodForm(food) : null}

                    {loadingError ? <Alert type="error">{loadingError}</Alert> : null}
                    {this.renderNutritionConfirmationModal()}
                </div>
            </>
        );
    }
}
