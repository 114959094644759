'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import indexBy from 'lodash.indexby';
import Modal from 'react-modal';
import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';
import TaxonomyActions from '../../actions/TaxonomyActions';
import TaxonomyStore from '../../stores/TaxonomyStore';
import allDiets from '../../tables/diets';
import { getConfig } from '../../utils/Env';
import { updateCachedDocuments, fetchDocumentsById } from '../../utils/Content';
import allMealTypes from '../../tables/meal-types'
import { getMealSearchParamsForProfile } from '../../pro/utils/Patients';
import './AvoidanceSelect.scss';
export default class AvoidanceSelect extends Component {
    static propTypes = {
        profile: PropTypes.object.isRequired,
        onChangeProfile: PropTypes.func.isRequired,
        rd_override: PropTypes.bool,
    };

    static defaultProps = {
        rd_override: false,
    }

    static contextTypes = {
        isPro: PropTypes.bool,
        confirm: PropTypes.func,
        showUpgradeForm: PropTypes.func,
        nutritionPatterns: PropTypes.array
    };

    constructor(props, context) {
        super(props, context);
        const { profile } = props;
        const { language, preferences: { diets = [], avoidances = [], exclude_foods = [] } } = profile;
        const dietsToShow = allDiets.filter(diet => {
            if (diet.disabled) {
                return false;
            }

            return true;
        });

        this.state = {
            user: UserStore.getUser(),
            diets,
            avoidances,
            hasSetNoAvoidance: !diets.length && !avoidances.length,
            exclude_foods,
            dietsToShow,
            allergensToShow: [],
            alert: null,
            showSearch: false,
            avoidOptions: [],
            avoidResults: {},
            assets: {},
            countingAvoidances: avoidances.length || exclude_foods.length ? true : false,
            loading: TaxonomyStore.isLoading(language),
            categories: TaxonomyStore.getCategories(language),
            taxonomy: TaxonomyStore.getTaxonomy(language),
            keyIndex: TaxonomyStore.getKeyIndex(language),
            abortController: null,
        };

        this.resetInhibitUpdate = debounce(this.resetInhibitUpdate, 1500);
        this.searchForAvoids = debounce(this.searchForAvoids, 750);
    }

    inhibitUpdate = false

    resetInhibitUpdate = () => {
        this.inhibitUpdate = false;
    }

    componentDidMount = () => {
        const { profile } = this.props;

        this.setMealCounts(true);

        this.syncAssets();

        TaxonomyStore.addChangeListener(this.onTaxonomyStoreChange);
        TaxonomyActions.ensureLoaded(profile.language);
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        TaxonomyStore.removeChangeListener(this.onTaxonomyStoreChange);
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onTaxonomyStoreChange = () => {
        const { profile } = this.props;

        this.setState({
            loading: TaxonomyStore.isLoading(profile.language),
            categories: TaxonomyStore.getCategories(profile.language),
            taxonomy: TaxonomyStore.getTaxonomy(profile.language),
            keyIndex: TaxonomyStore.getKeyIndex(profile.language),
        }, this.syncAssets);
    }

    onUserStoreChange = () => {
        this.setState({user: UserStore.getUser()});
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (this.inhibitUpdate) {
            return;
        }

        const { diets = [], avoidances = [], exclude_foods = [] } = nextProps.profile.preferences;

        this.setState({diets, avoidances, exclude_foods}, this.syncAssets);
    }

    updateParent = () => {
        const { diets, avoidances, exclude_foods } = this.state;
        const { profile, onChangeProfile } = this.props;

        profile.preferences.diets = diets;
        profile.preferences.avoidances = avoidances;
        profile.preferences.exclude_foods = exclude_foods;

        this.inhibitUpdate = true;
        onChangeProfile(profile);
        this.resetInhibitUpdate();
    }

    setMealCounts = async (removeAvoidances = false) => {
        const { abortController, avoidances, exclude_foods, total  } = this.state;

        // Don't run if the user doesn't have any avoidances yet
        if (!removeAvoidances && !avoidances.length && !exclude_foods.length) {
            return;
        }

        let profile = JSON.parse(JSON.stringify(this.props.profile));
        let qualifyingTotal = 0;

        if (removeAvoidances) {
            profile.preferences.avoidances = [];
            profile.preferences.exclude_foods = [];
        }

        this.setState({ countingAvoidances: true });

        try {
            for (const { mealType, prop } of allMealTypes) {
                if (mealType === 'Snack' || !profile.preferences[prop]) {
                    continue;  // Skip snacks or inactive preferences
                }

                let params = getMealSearchParamsForProfile(mealType, profile);
                params.size = 0; // we don't need results, just the total count
                params.types = ["recipe"];

                for (const key in params.filters) {
                    if (params.filters[key].gte) {
                        delete params.filters[key].gte;
                    }
                }

                // Create a new AbortController for each request
                const ac = new AbortController();
                this.setState({ abortController: ac });

                const response = await AuthStore.fetch(getConfig('recipe_api') + '/search', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json; schema=search/advanced/1' },
                    body: JSON.stringify(params),
                }, true, false, ac);

                qualifyingTotal += response.total; // Add to cumulative total
            }

            if (removeAvoidances) {
                this.setState({ total: qualifyingTotal, countingAvoidances: false });
            } else {
                this.setState({ qualifying: qualifyingTotal, countingAvoidances: false });
            }

        } catch (error) {
            this.setState({ countingAvoidances: false });
        }
    }


    syncAssets = async () => {
        
        const { exclude_foods, categories, avoidances, profile_key } = this.state;

        const allergensToShow = (categories || []).filter(category => {
            if ((category.tags || []).includes('Common Avoidance')) {
                return true;
            }
            return false;
        }).sort((a, b) => a.title.localeCompare(b.title));

        const docs = await fetchDocumentsById(exclude_foods);
        const assets = indexBy(docs, 'uuid');

        this.setMealCounts();

        this.setState({assets, allergensToShow});
    }

    syncAssetsAndUpdateParent = () => {
        this.syncAssets();
        this.updateParent();
    }

    onChangeAvoids = (newAvoids, oldAvoids) => {
        const avoidsAdded   = newAvoids.filter(avoid => oldAvoids.indexOf(avoid) == -1),
              avoidsRemoved = oldAvoids.filter(avoid => newAvoids.indexOf(avoid) == -1);

        let { diets, keyIndex } = this.state;

        // If we removed an avoidance, see if that avoidance is one of the diets avoidances
        if (avoidsRemoved.length) {
            // Is this diet listed in any of the category.diets_avoiding? If so, remove that diet
            // from the list of diets (e.g.: you cannot be vegetarian and eat chicken)
            avoidsRemoved.forEach(key => {
                const category = keyIndex[key];

                if (!category) {
                    return;
                }

                diets = diets.filter(diet => !(category.diets_avoiding || []).includes(diet));
            })
        }

        this.setState({diets, avoidances: newAvoids, hasSetNoAvoidance: false}, this.updateParent);
    }


    toggleDiet = (diet) => {
        const { profile = {}, rd_override } = this.props;
        const { confirm, nutritionPatterns = [] } = this.context;
        const { categories, user } = this.state;

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        let diets      = [diet.name];
        let avoidances = [];

        categories.forEach(category => {
            if (!(category.diets_avoiding && category.diets_avoiding.length > 0)) {
                return;
            }

            if (category.diets_avoiding.includes(diet.name) && !avoidances.includes(category.category_key)) {
                avoidances.push(category.category_key);
            }
        });

        let matchingNutritionPatterns = profile.conditions.map(({uuid}) => nutritionPatterns.find((pattern) => pattern.uuid === uuid)).filter((v) => v);

        if (user && profile.conditions.length && matchingNutritionPatterns.length === 0) {
            profile.conditions.forEach(async (condition) => {
                const nutritionPattern = await AuthStore.fetch({
                    url: getConfig('users_api') + '/nutrition-patterns'+ `/${condition.uuid}`
                });

                avoidances = avoidances.concat(nutritionPattern.avoidances);
            });
        } else {
            // Add all selected conditions avoidances to the avoidance list as well.
            matchingNutritionPatterns.forEach((nutritionPattern) => avoidances = avoidances.concat(nutritionPattern.avoidances));
        }

        // Filter out duplicates
        avoidances = avoidances.filter((avoidance, i) => avoidances.indexOf(avoidance) === i);

        this.setState({diets, avoidances, hasSetNoAvoidance: false}, this.updateParent);
    }

    toggleAvoidance = (tag) => {
        let { avoidances, exclude_foods, avoidOptions, avoidResults } = this.state;
        const { profile = {}, rd_override } = this.props;
        const { confirm } = this.context;

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        // Make a copy of avoidances before we mutate
        const oldAvoids = avoidances.slice();

        var i = avoidances.indexOf(tag);

        if (i == -1) {
            avoidances.push(tag);
        } else {
            avoidances.splice(i, 1);
        }

        avoidOptions = avoidOptions.map(option => {
            const isCategory = option.type === "category";
            const avoidResult = avoidResults[option.value];
            const selected = isCategory 
                ? avoidances.includes(avoidResult.category_key)
                : exclude_foods.includes(option.value) || avoidances.some(avoidance => (avoidResult.ancestor_keys || []).includes(avoidance));
            return { ...option, selected };
        });

        this.setState({
            avoidOptions,
        }, this.syncAssets);


        this.onChangeAvoids(avoidances, oldAvoids);
    }

    toggleExcludeFood = (uuid) => {
        let { exclude_foods, avoidances, avoidOptions, avoidResults } = this.state;

        const { profile = {}, rd_override } = this.props;
        const { confirm } = this.context;

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        if (exclude_foods.includes(uuid)) {
            exclude_foods.splice(exclude_foods.indexOf(uuid), 1);
        } else if (exclude_foods.length < 54) {
            exclude_foods.push(uuid);
        } else {
            return confirm(
                'Avoiding too many foods will prevent our nutrition intelligence engine from being able to recommend a meal plan for you. You cannot avoid any more foods.',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        avoidOptions = avoidOptions.map(option => {
            const isCategory = option.type === "category";
            const avoidResult = avoidResults[option.value];
            const selected = isCategory 
                ? avoidances.includes(avoidResult.category_key)
                : exclude_foods.includes(option.value) || avoidances.some(avoidance => (avoidResult.ancestor_keys || []).includes(avoidance));
            return { ...option, selected };
        });

        this.setState({
            avoidOptions,
        });

        this.setState({exclude_foods}, this.syncAssetsAndUpdateParent);
    }

    showSearch = () => {
        this.setState({showSearch: true});
    }

    hideSearch = () => {
        this.setState({showSearch: false, avoidOptions: [], avoidResults: [], avoidTerms: '', resultAvoidTerms: ''});
    }

    filterTaxonomy = (taxonomy, ancestorKeysSet) => {
        return taxonomy.reduce((acc, item) => {
            if (ancestorKeysSet.has(item.category_key)) {
                const filteredChildren = this.filterTaxonomy((item.children || []), ancestorKeysSet);
                acc.push({ ...item, children: filteredChildren });
            }
            return acc;
        }, []);
    }

    combineTaxonomyAndElements = (filteredTaxonomy, responseElements) => {
        const combinedArray = [];

        function traverse(taxonomyNode, currentIndent) {
            combinedArray.push({ ...taxonomyNode, indents: currentIndent });

            if (taxonomyNode.children.length === 0) {
                const matchingElements = responseElements.filter(element =>
                    (element.ancestor_keys || []).includes(taxonomyNode.category_key)
                );
                matchingElements.forEach(element => {
                    combinedArray.push({ ...element, indents: currentIndent + 1 });
                });
            } else {
                taxonomyNode.children.forEach(child => traverse(child, currentIndent + 1));
            }
        }

        filteredTaxonomy.forEach(node => traverse(node, 0));

        return combinedArray;
    };

    groupCombinedArray = (sortedArray) => {
        const groupedArray = [];
        let currentGroup = [];

        sortedArray.forEach(item => {
            if (item.indents === 0) {
                if (currentGroup.length > 0) {
                    groupedArray.push(currentGroup);
                }
                currentGroup = [item];
            } else {
                currentGroup.push(item);
            }
        });

        if (currentGroup.length > 0) {
            groupedArray.push(currentGroup);
        }

        return groupedArray;
    };

    sortGroupedArray = (groupedArray) => {
        const groupMinIndexes = groupedArray.map(group => {
            const minIndex = group.reduce((min, item) => {
                if ('originalIndex' in item) {
                    return Math.min(min, item.originalIndex);
                }
                return min;
            }, Infinity);
            return { group, minIndex };
        });

        groupMinIndexes.sort((a, b) => a.minIndex - b.minIndex);

        const sortedGroups = groupMinIndexes.map(item => item.group);

        return sortedGroups;
    };

    assignOriginalIndexToTaxonomyRecursively = (category, elements, ancestorKeysSet) => {
        const originalIndex = elements.findIndex(element => element.category_key === category.category_key);
        if (originalIndex !== -1) {
            category.originalIndex = originalIndex;
        }

        if (category.children && category.children.length > 0) {
            category.children.forEach(child => {
                this.assignOriginalIndexToTaxonomyRecursively(child, elements, ancestorKeysSet);
            });
        }
    };

    searchForAvoids = async () => {
        const { profile } = this.props;
        const { categories, taxonomy, user, avoidTerms, avoidances, exclude_foods, keyIndex } = this.state;
        const { capabilities = {} } = user;

        // Don't re-search if nothing serious has changed
        if (avoidTerms === this.state.resultAvoidTerms) {
            return;
        }

        this.setState({working: true});

        const params = {
            language: profile.language || 'en',
            terms: avoidTerms,
            types: ['category', 'food'],
            filters: {
                product_type: 'Food',
                '!tags': ['Unavoidable', 'Common Avoidance'],
            },
            sort_by: 'avoidances',
            size: 35,
        };

        const response = await AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        }, true);

        updateCachedDocuments(response.elements);

        response.elements = response.elements.sort((a, b) => {
            if (a.type === 'category' && b.type === 'food')     return -1;
            if (a.type === 'food'     && b.type === 'category') return 1;
            return 0;
        })

        response.elements = response.elements.map((element, index) => ({
            ...element,
            originalIndex: index
        }));

        const ancestorKeysSet = new Set(
          response.elements.flatMap(element => element.ancestor_keys)
        );

        const filteredTaxonomy = this.filterTaxonomy(taxonomy, ancestorKeysSet);

        filteredTaxonomy.forEach(category => {
            this.assignOriginalIndexToTaxonomyRecursively(category, response.elements, ancestorKeysSet);
        });

        let combinedArray = this.combineTaxonomyAndElements(filteredTaxonomy, response.elements);
        combinedArray = this.sortGroupedArray(this.groupCombinedArray(combinedArray)).flat();

        let currentCategories = [];

        let selectedCategories = [];

        const avoidOptions = combinedArray.map(result => {

            const isCategory = result.type === "category";
            if (isCategory) {
                if(result.indents == 0) {
                    currentCategories = [result.category_key]                   
                } else if (result.indents > currentCategories.length - 1) {
                    currentCategories.push(result.category_key);
                } else if (result.indents <= currentCategories.length - 1) {
                    currentCategories = currentCategories.slice(0, result.indents);
                    currentCategories.push(result.category_key);
                }
            }

            let parents = currentCategories.slice();

            // category should not be a child of itself
            if (isCategory) {
                parents = parents.filter(category => category !== result.category_key);
            }

            let selected = false;

            if (isCategory) {
                selected = avoidances.includes(result.category_key) || avoidances.some(avoidance => (result.ancestor_keys || []).includes(avoidance)) || selectedCategories.some(avoidance => (result.ancestor_keys || []).includes(avoidance));
                if (!selectedCategories.includes(result.category_key) && selected) {
                    selectedCategories.push(result.category_key);
                }
            } else {
                selected = exclude_foods.includes(result.uuid) || avoidances.some(avoidance => (result.ancestor_keys || []).includes(avoidance))  || selectedCategories.some(avoidance => (result.ancestor_keys || []).includes(avoidance));
            }

            return {
                label: (collapsed) => (
                    <li className="result-item" data-indents={result.indents}>
                        {isCategory ? (
                            <i
                                data-indents={result.indents}
                                onClick={(event) => this.toggleCategory(event, result.uuid)}
                                className={collapsed ? "icon-chevron-right" : "icon-chevron-down"}
                            />
                        ) : null}
                        {result.pretty_name || result.name || result.title}
                        {!capabilities.advanced_avoidances ? <i className="icon-lock" /> : null}
                    </li>
                ),
                value: result.uuid,
                parents: parents,
                visible: true,
                collapsed: false,
                selected: selected,
            };
        });

        this.setState({
            working: false,
            avoidOptions,
            avoidResults: indexBy(combinedArray, 'uuid'),
            resultAvoidTerms: avoidTerms,
            noOptionsFound: avoidOptions.length == 0,
        });
    }

    onChangeAvoidTerms = (event) => {
        let avoidTerms  = event.target.value;

        avoidTerms = (avoidTerms || '').trim();

        if (!avoidTerms) {
            this.setState({avoidOptions: []});
            return;
        }

        this.setState({avoidTerms}, this.searchForAvoids);
    }

    isDescendantRecursive = (parentKeys, categoryKey, avoidResults) => {
        if (!parentKeys?.length) {
            return false;
        }

        if (parentKeys.includes(categoryKey)) {
            return true;
        }

        const grandParentKeys = parentKeys.flatMap(parentKey => {
            const result = Object.values(avoidResults).find(item => item.category_key === parentKey);

            if (!result || !result.ancestor_keys) {
                return [];
            }

            return result.ancestor_keys;
        });

        return this.isDescendantRecursive(grandParentKeys, categoryKey, avoidResults);
    };

    onSelectAvoidOption = (uuid) => {
        const { user, avoidOptions, avoidResults } = this.state;
        let { avoidances, exclude_foods } = this.state;
        const { profile, rd_override } = this.props;
        const { showUpgradeForm, confirm } = this.context;
        const { capabilities = {} } = user || {};

        if (!capabilities.advanced_avoidances) {
            showUpgradeForm({feature: 'advanced_avoidances'});
            return;
        }

        if (!(avoidResults && avoidResults[uuid])) {
            return;
        }

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        if (exclude_foods.length >= 54) {
            return confirm(
                <div>
                    <p>Avoiding too many foods will prevent our nutrition intelligence engine from being able to recommend a meal plan for you.</p>
                    <p>You cannot avoid any more foods.</p>
                </div>,
                accept => false, reject => false, {rejectText: ''}
            );
        }

        let alreadySelected = false;


        const selectedAvoidance = avoidResults[uuid]
        const selectedOption = avoidOptions.find(option => option.value == uuid);

        const isChildOfAlreadySelectedCategory = (selectedOption.parents || []).some(parent => avoidances.includes(parent))

        // The user cannot make exception for children of selected avoidances
        if (isChildOfAlreadySelectedCategory) {
            return; 
        }

        // Select food
        if (avoidResults[uuid].type === 'food' && !exclude_foods.includes(uuid)) {
            exclude_foods.push(uuid);
        // Select category
        } else if (avoidResults[uuid].type === 'category' && !avoidances.includes(avoidResults[uuid].category_key)) {
            avoidances.push(avoidResults[uuid].category_key);
        // Unselect
        } else {
            alreadySelected = true;
            exclude_foods = exclude_foods.filter(id => id !== uuid);
            avoidances = avoidances.filter(key => key !== avoidResults[uuid].category_key);

            // Filter out the exclude_foods and avoidances whose parent is the uuid being unselected
            const childrenToRemove = avoidOptions
                .filter(option => (option.parents || []).includes(avoidResults[uuid].category_key))
                .map(option => ({
                    value: option.value,
                    category_key: avoidResults[option.value]?.category_key
                }));

            exclude_foods = exclude_foods.filter(id => !childrenToRemove.some(child => child.value === id));
            avoidances = avoidances.filter(key => !childrenToRemove.some(child => child.category_key === key));
        }

        const updatedAvoidUptions = avoidOptions.map(option => {
            const isDirectChild = (option.parents || []).includes(selectedAvoidance.category_key);
            const isDescendant = this.isDescendantRecursive((avoidResults[option.value]?.ancestor_keys || []), selectedAvoidance.category_key, avoidResults);
            const parentsNotSelected = !(option.parents || []).some(parent => avoidances.includes(parent));

            if (isDirectChild || (isDescendant && parentsNotSelected) || option.value === uuid) {
                option.selected = !alreadySelected;
            }
            return option;
        });


        this.setState({
            avoidances,
            exclude_foods,
            avoidOptions: updatedAvoidUptions,
            resultAvoidTerms: '',
        }, this.syncAssetsAndUpdateParent);
    }

    onClickEatsMostThings = () => {
        const { profile, rd_override } = this.props;
        const { confirm } = this.context;

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        this.setState({diets: [], avoidances: [], exclude_foods: [], hasSetNoAvoidance: true}, this.syncAssetsAndUpdateParent);
    }

    toggleCategory = (event, uuid) => {
        const { avoidOptions, avoidResults } = this.state;
        event.stopPropagation();
        const selectedAvoidance = avoidResults[uuid];
        let collapsed;

        let toggledAvoidOptions = avoidOptions.map(option => {
            if (option.value === uuid) {
                collapsed = !option.collapsed;
                return { ...option, collapsed: collapsed };
            }
            return option;
        }); 

        toggledAvoidOptions.forEach(option => {
            if ((option.parents || []).includes(selectedAvoidance.category_key)) {
                option.visible = !collapsed;
            }
        })

        this.setState({
            avoidOptions: toggledAvoidOptions,
        });
    }

    renderAvoidancesSearchModal = () => {
        const { showSearch, allergensToShow, avoidances, exclude_foods, keyIndex, assets, avoidResults, avoidOptions, noOptionsFound, working, total, qualifying, countingAvoidances } = this.state;

        const totalAvoidances = avoidances.length + exclude_foods.length;

        if (!showSearch) {
            return;
        }

        return (
            <Modal isOpen={true}
                closeModal={() => false}
                contentLabel="Avoidances Search"
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}>

                <section className="el-modal-container el-modal2-container avoidances-modal-container el-fonts">
                    <header>
                        <h2>Avoidances</h2>
                    </header>

                    <div className="el-modal-body-container el-modal2-body-container">
                        <div className="selected-avoidances">
                            {avoidances.map(key => {
                                return (
                                    <button key={key}className="el-medium-btn el-grayish-blue-outline-btn" onClick={() => this.toggleAvoidance(key)}>
                                        {keyIndex && keyIndex[key] && keyIndex[key].title} <i className="icon-x" />
                                    </button>
                                );
                            })}
                            {exclude_foods.map((uuid, i) => {
                                if (!assets[uuid]) {
                                    return;
                                }

                                return (
                                    <button key={i} className="el-medium-btn el-grayish-blue-outline-btn" onClick={() => this.toggleExcludeFood(uuid)}>
                                        {assets[uuid].pretty_name || assets[uuid].name || assets[uuid].title} <i className="icon-x" />
                                    </button>
                                );
                            })}
                        <div className="avoidances-disclaimer" data-center={countingAvoidances}>
                            {!countingAvoidances && totalAvoidances && total && qualifying && (total - qualifying)  ? `${totalAvoidances > 1 ? "These" : "This"} ${totalAvoidances} ${totalAvoidances > 1 ? "avoidances" : "avoidance"} ${totalAvoidances > 1 ? "exclude" : "excludes"} ${total - qualifying}/${total} recipes. Selecting additional avoidances will result in less meal variety.`
                            : null}
                            {countingAvoidances  ? <i className="icon-spinner2"/>
                            : null}

                        </div>

                        </div>
                        <div className="with-label">
                            <div className="el-labeled-input el-input-with-icon el-search-input available-at">
                                <i class="icon-search3 left-icon" />
                                <input type="text" placeholder="Enter food avoidance" onChange={this.onChangeAvoidTerms} />
                            </div>
                        </div>

                        <div className="avoidance-search-results">
                            {!working && !noOptionsFound ?
                                    avoidOptions.filter(option => option.visible).map(option => {
                                        const isUnavoidable = avoidResults[option.value].tags.includes("Unavoidable");
                                        return (
                                            <span
                                                className="result-item-container"
                                                data-unavoidable={isUnavoidable}
                                                data-selected={option.selected}
                                                onClick={() => {
                                                    if (!isUnavoidable) {
                                                        this.onSelectAvoidOption(option.value);
                                                    }
                                                }}>
                                                {option.label(option.collapsed)}
                            
                                            </span>
                                        );
                                })
                            : !working ? <span className="no-resuls-found">No results found. Please try refining your search terms.</span> : <div className="spinner-container"> <i className="icon-spinner"/> </div>}
                        </div>


                    </div>

                    <footer>

                        <button className="el-modal-ok-btn" onClick={this.hideSearch} >done</button>
                    </footer>
                </section>
            </Modal>
        );
    }

    render() {
        const {
            diets,
            avoidances,
            exclude_foods,
            hasSetNoAvoidance,
            dietsToShow,
            allergensToShow,
            loading,
            keyIndex,
            assets
        } = this.state;

        const { isPro } = this.context;

        if (loading) {
            return (
                <div className="avoidances-select-loader">
                    <i className="icon-spinner2" />
                </div>
            );
        }

        return (
            <div className="avoidances-selector">

                <section className="choose-diets">
                    <h3>Diet</h3>
                    <p>Select a diet below and we will only show recipes that match.</p>

                    <ul className="select-bubbles">
                        <li><button onClick={this.onClickEatsMostThings} data-active={hasSetNoAvoidance ? true : false}>Eats All Things</button></li>
                        {dietsToShow.map((diet, i) => {
                            return (
                                <li key={i}>
                                    <button data-active={diets.includes(diet.name)} onClick={() => this.toggleDiet(diet)}>{diet.name}</button>
                                </li>
                            );
                        })}
                    </ul>
                </section>

                <section className="choose-allergens">
                    <h3>Allergies &amp; Avoidances</h3>
                    <p>Select household allergies and avoidances and we won’t show you recipes with them. You can add more later.</p>

                    <ul className="select-bubbles">
                        {allergensToShow.map((category, i) => (
                            <li key={i}>
                                <button data-active={avoidances.includes(category.category_key)} onClick={() => this.toggleAvoidance(category.category_key)}>
                                    {category.title}
                                </button>
                            </li>
                        ))}
                        {avoidances.map(key => {
                            if (keyIndex[key] && (keyIndex[key].tags || []).includes('Common Avoidance')) {
                                return;
                            }
                            return (
                                <li key={key}><button data-active={true} onClick={() => this.toggleAvoidance(key)}>
                                    {keyIndex && keyIndex[key] && keyIndex[key].title}
                                </button></li>
                            );
                        })}
                        {exclude_foods.map((uuid, i) => {
                            if (!assets[uuid]) {
                                return;
                            }

                            return (
                                <li key={i}><button data-active={true} onClick={() => this.toggleExcludeFood(uuid)}>
                                    {assets[uuid].pretty_name || assets[uuid].name || assets[uuid].title}
                                </button></li>
                            );
                        })}

                    </ul>
                </section>
                <section className="choose-dislikes">
                    <div className="add-avoided" data-is-pro={isPro}>
                        <button onClick={this.showSearch} className="sub-action-btn">
                            <i className="icon-plus-thin" /> add more
                        </button>
                    </div>
                </section>
                {this.renderAvoidancesSearchModal()}
            </div>
        );

    }
}
