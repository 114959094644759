'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CalendarWidget from '../Widgets/CalendarWidget.react';

import Modal from 'react-modal';

import modalStyles from '../../jsx-styles/modals';

import '../../pro/components/Modals/Modals.scss';
import './SchedulingModal.scss';

export default class SchedulingModal extends Component {
    static propTypes = {
        initialDate: PropTypes.any,
        isLocked: PropTypes.bool,
        closeModal: PropTypes.func,
        selectDate: PropTypes.func,
    };

    static defaultProps = {
        isLocked: false,
        isMealModal: false,
    };

    static contextTypes = {
        confirm: PropTypes.func,
    }

    constructor(props) {
        super(props);

        const { content, isMealModal } = props;

        this.state = {
            date: props.initialDate ? moment(props.initialDate) : moment(),
            mealType: isMealModal ? content.meal : this.getDefaultMealTypeFromTags(content?.tags || []),
        };
    }

    getDefaultMealTypeFromTags(tags = []) {
        if (tags.includes('Dinner') || tags.includes('Side Dish')) {
            return 'Dinner';
        }

        if (tags.includes('Lunch') || tags.includes('Lunch Side Dish')) {
            return 'Lunch';
        }

        if (tags.includes('Breakfast') || tags.includes('Breakfast Side Dish')) {
            return 'Breakfast';
        }

        return 'Snack';
    }

    onChangeDate = (date) => {
        this.setState({date: moment(date)});
    }

    selectDate = () => {
        const { confirm } = this.context;
        const { selectDate, closeModal } = this.props;
        const { date, mealType } = this.state;

        selectDate && selectDate(date, mealType) && closeModal && closeModal();
    }

    render() {
        const { date, mealType } = this.state;
        const { closeModal, isLocked, meal } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                contentLabel="Choose a date"
                closeTimeoutMS={250}>

                <div className="el-modal-container el-modal2-container">
                    <header>
                        <h2>When would you like to have this meal?</h2>

                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal2-body-container el-fonts">
                        <CalendarWidget
                            value={date.toDate()}
                            onChange={this.onChangeDate} />

                        <section className="meal-type-radio">
                            <label>Designate this meal as:</label>
                            {['Breakfast', 'Lunch', 'Snack', 'Dinner'].map(mt => (
                                <button key={mt} className={mealType === mt ? "active" : ""} onClick={() => this.setState({mealType: mt})}>
                                    {mt}
                                </button>
                            ))}
                        </section>
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn el-small-btn" onClick={closeModal}>cancel</button>
                        <button className="el-modal-ok-btn el-small-btn" onClick={this.selectDate}>Add to Schedule {isLocked ? <i className="icon-lock" /> : null}</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
