'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import goodSources from '../../../tables/good-sources';

export default class GoodSource extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
    };

    toggleGoodSource = (key) => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};

        if (params.filters[key]) {
            delete params.filters[key];
        } else {
            params.filters[key] = {gte: goodSources[key].v};
        }

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;

        return (
            <div className="global-search-tag-group">
                <ul>
                    {Object.keys(goodSources).map(key => {
                        return (
                            <li key={key}>
                                <button className="el-checkbox" data-active={typeof params.filters[key] === 'object'}
                                    onClick={() => this.toggleGoodSource(key)}>
                                    {goodSources[key].label}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
