import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserStore from "../../../stores/UserStore";
import AuthStore from '../../../stores/AuthStore';
import { getConfig } from "../../../utils/Env";

export default class ProfileParams extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: props.params?.profile,
        };
    }

    componentDidUpdate(prevProps) {
        const { params } = this.props;

        if (params?.profile?.uuid && params?.profile?.uuid !== prevProps.params?.profile?.uuid) {
            this.fetchProfile(params.profile.uuid, params.profile.name);
        }

        if (!params?.profile?.uuid && prevProps.params?.profile?.uuid) {
            this.setState({ profile: null });
        }
    }

    fetchProfile = (uuid, profileName) => {
        const url = `${getConfig("users_api")}${UserStore.getUser().links.patients.href}/${uuid}?embed=preferences`;

        AuthStore.fetch(url).then((response) => {
            const updatedProfile = {
                ...response,
                name: profileName,
            };

            this.setState({ profile: updatedProfile });
        });
    };

    toggleProfileFilter = () => {
        const { params, onChangeParams } = this.props;
        const { profile } = this.state;

        if (profile) {
            const { diets, avoidances } = profile.preferences || {};

            delete params.profile;

            params.filters['tags'] = (params.filters['tags'] || []).filter(tag => !diets?.includes(tag));
            params.filters['!ingredient_tags'] = (params.filters['!ingredient_tags'] || []).filter(avoid => !avoidances?.includes(avoid));
        }

        onChangeParams(params);
    };

    render() {
        const { params } = this.props;
        const { profile } = this.state;

        if (!profile || !params.profile) {
            return null;
        }

        return (
            <div className="param-item" onClick={this.toggleProfileFilter}>
                {params.profile.name} <i className="icon-close-x" />
            </div>
        );
    }
}