'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import debounce from 'lodash.debounce';

import SearchKeywords from '../Filters/SearchKeywords.react';
import DocumentTypeExpander from '../Dropdowns/DocumentType.react';
import MealTypes from '../Dropdowns/MealTypes.react';
import AvoidancesExpander from '../Dropdowns/Avoidances.react';
import SkillLevelExpander from '../Dropdowns/SkillLevel.react';
import CaloriesExpander from '../Dropdowns/Calories.react';
import BrandNameExpander from '../Dropdowns/BrandName.react';
import GoodSourceExpander from '../Dropdowns/GoodSource.react';
import TotalTimeExpander from '../Dropdowns/TotalTime.react';
import PrepTimeExpander from '../Dropdowns/PrepTime.react';
import TagGroupExpander from '../Dropdowns/TagGroup.react';
import SortExpander from '../Dropdowns/Sort.react';
import LibraryFilter from '../Filters/Library.react';
import PreferencesFilter from '../Filters/Preferences.react';
import UnpublishedFilter from '../Filters/Unpublished.react';
import NutrientFilters from '../Filters/NutrientFilters.react';
import IngredientsFilter from '../Dropdowns/Ingredients.react';
import LanguageFilter from '../Filters/LanguageFilter.react';
import Combobox from '../../../pro/components/Widgets/Combobox.react';
import PatientFilter from '../Filters/PatientFilter.react';

import MerchantExpander from '../Dropdowns/Merchant.react';
import Analytics from '../../../utils/Analytics';
import UserStore from '../../../stores/UserStore';
import AuthStore from '../../../stores/AuthStore';
import { getConfig } from '../../../utils/Env';
import { getMealSearchParamsForProfile } from '../../../pro/utils/Patients';

import allTags from '../../../tables/tags';

import './AdvancedFilters.scss';

export default class AdvancedFilters extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
        hideLibraryFilter: PropTypes.bool,
    };

    static contextTypes = {
        profile: PropTypes.object,
        isPublisher: PropTypes.bool,
        isAddSwap: PropTypes.bool,
        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            // Make a deep copy of the original parameters
            initialParams: JSON.parse(JSON.stringify(props.params)),
        };
    }

    componentDidMount = () => {
        const { contextName } = this.props;

        Analytics.advancedSearch({ Context: contextName });
    };

    cancel = () => {
        const { initialParams } = this.state;
        const { onChangeParams, closeModal } = this.props;

        onChangeParams(initialParams);
        closeModal();
    };

    render() {
        const { isPublisher, isAddSwap, isPro } = this.context;
        const { params, allowedTypes, onChangeParams, total, loading, closeModal, hideLibraryFilter, showTypePicker } =
            this.props;
        const { hide_nutrition = false } = UserStore.getUser();

        return (
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Advanced Search"
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal2-container">
                    <header>
                        <h2>Filter Results</h2>

                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal2-body-container el-fonts">
                        <PatientFilter params={params} onChangeParams={onChangeParams} />

                        {isPublisher ? <LanguageFilter params={params} onChangeParams={onChangeParams} /> : null}

                        {isAddSwap ? <SearchKeywords params={params} onChangeParams={onChangeParams} /> : null}
                        {!hideLibraryFilter ? <LibraryFilter params={params} onChangeParams={onChangeParams} /> : null}

                        {isPublisher ? <UnpublishedFilter params={params} onChangeParams={onChangeParams} /> : null}

                        {!isPublisher && !isPro ?
                            <PreferencesFilter params={params} onChangeParams={onChangeParams}>
                                Smart Choice
                            </PreferencesFilter>
                        : null}

                        {isPublisher ?
                            <PreferencesFilter params={params} onChangeParams={onChangeParams} filterName="strict_preferences">
                                Smart Choice
                            </PreferencesFilter>
                        : null}

                        {!showTypePicker ? (
                            <DocumentTypeExpander
                                params={params}
                                onChangeParams={onChangeParams}
                                allowedTypes={allowedTypes}
                            />
                        ) : null}

                        {!hide_nutrition ? (
                            <SortExpander params={params} onChangeParams={onChangeParams} />
                        ) : null}

                        <MealTypes
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                        />
                        <AvoidancesExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                        />
                        <BrandNameExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                        />
                        <IngredientsFilter
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                        />
                        <SkillLevelExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                        />
                        <TagGroupExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                            singleSelect={true}
                            group={allTags.cuisine}
                        />
                        <TagGroupExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                            singleSelect={true}
                            group={allTags.equipment}
                            onlyWorksOn="recipe"
                        />
                        <MerchantExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                        />
                        {!hide_nutrition ? (
                            <CaloriesExpander
                                isExpander={true}
                                params={params}
                                onChangeParams={onChangeParams}
                                allowedTypes={allowedTypes}
                            />
                        ) : null}

                        <TagGroupExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                            singleSelect={true}
                            group={allTags.recipeMisc}
                            onlyWorksOn="recipe"
                            translateTagMap={{ Basic: 'Simple Recipes' }}
                        />
                        <GoodSourceExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                        />
                        <TotalTimeExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                        />
                        <PrepTimeExpander
                            isExpander={true}
                            params={params}
                            onChangeParams={onChangeParams}
                            allowedTypes={allowedTypes}
                        />

                        {!hide_nutrition ? <NutrientFilters params={params} onChangeParams={onChangeParams} /> : null}

                        {loading === true ? <p>loading...</p> : null}
                        {loading !== true ? (
                            <p>
                                Filter Results: <em>{total} listings</em>
                            </p>
                        ) : null}
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.cancel}>
                            Cancel
                        </button>
                        <button className="el-modal-ok-btn" onClick={closeModal}>
                            Apply
                        </button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
