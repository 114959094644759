'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Expander from '../Expander.react';
import TagGroupFilter from '../Filters/TagGroup.react';

import './TagGroup.scss';

export default class TagGroup extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
        onlyWorksOn: PropTypes.string,
        singleSelect: PropTypes.bool,
    };

    static defaultProps = {
        isExpander: false,
        singleSelect: false,
    };

    render() {
        const { params, group, title, onChangeParams, isExpander, onlyWorksOn, singleSelect, allowedTypes } = this.props;

        if (onlyWorksOn && !allowedTypes.includes(onlyWorksOn)) {
            return <span />
        }

        // How many of these tags are currently turned on? We need to render a badge
        const filterTags = (params.filters && params.filters.tags) || [];
        const selectedTags = filterTags.filter(tag => group.tags.includes(tag));
        let badge = <em data-active={false} />;

        let isFilterAvailable = true;

        if (typeof onlyWorksOn == 'string' && !params.types.includes(onlyWorksOn)) {
            isFilterAvailable = false;
        }

        if (!isFilterAvailable) {
            return <span />
        }

        if (selectedTags.length > 0 && isFilterAvailable) {
            badge = <em data-active={true}>{selectedTags.length}</em>;
        }

        const button = (
            <span className="global-search-filter-btn">
                {group.title} {badge}
            </span>
        );

        return (
            <span className="global-search-hideable-filter" data-active={isFilterAvailable}>
                <Expander button={button}>
                    <TagGroupFilter {...this.props} singleSelect={singleSelect} onlyWorksOn={onlyWorksOn} params={params} tags={group.tags} onChangeParams={onChangeParams} />
                </Expander>
            </span>
        );
    }
}
