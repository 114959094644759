'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import PrescriptionMismatch from './PrescriptionMismatch.react';
import MealCell from './MealCell.react';
import Dropdown from '../../Widgets/Dropdown.react';

import './MobileGrid.scss';
import { getStartTimeForMeal } from '../../../../utils/Meals';

export default class MobileGrid extends Component {
    static contextTypes = {
        mismatches: PropTypes.object,
        onModifyMeals: PropTypes.func,
        getDefaultAddSwapSettings: PropTypes.func,
        hideWarnings: PropTypes.bool
    };

    render = () => {
        const { mismatches, hideWarnings } = this.context;
        const { plan, maxOffset, mealTypes, hideMismatches, profile = {} } = this.props;
        const { hide_nutrition = false, rd_override } = profile || {};

        const isAltPatient = profile?.type === "alternate-patient";
        let showDailyMismatches = !hideMismatches &&
                                  (!hide_nutrition || rd_override);

        const offsets = [];

        for(var i = 0; i <= maxOffset; i++) {
            offsets.push(i);
        }

        return (
            <div className="mobile-grid">
                <div className="meal-types-column">
                    {mealTypes.map(mealType => {
                        const startTime = getStartTimeForMeal(mealType.begin);
                        const endTime = getStartTimeForMeal(mealType.end);
                        return (
                            <div key={mealType.name} className="meal-types-row">
                                <div className="meal-details">
                                    <span>{mealType.name}</span>
                                    <span className="range">{startTime} - {endTime}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className="meals-column-viewport">
                    <div className="meals-column-container">
                        {offsets.map(offset =>(
                            <div key={offset} className="meals-column-cell">
                                <div className="day-offset-header">
                                    Day {offset+1}

                                    {showDailyMismatches && mismatches && mismatches[offset] && mismatches[offset]['all-day'] && mismatches[offset]['all-day'].length > 0 && !isAltPatient && !hideWarnings ?
                                        <Dropdown button={<i className="icon-warning1" />}>
                                            <PrescriptionMismatch mealType="Daily Totals"
                                                mismatches={(showDailyMismatches && mismatches[offset] && mismatches[offset]['all-day']) || []} />
                                        </Dropdown>
                                    : null}
                                </div>

                                {mealTypes.map(mealType => (
                                    <div key={mealType.name} className="meals-column-inner-container">
                                        <MealCell profile={profile} key={offset} offset={offset} mealType={mealType.name} plan={plan}
                                            meals={(plan.items || []).filter(meal => meal.offset == offset && meal.meal == mealType.name)} />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
