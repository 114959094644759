'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import NoResults from './Results/NoResults.react';
import Loading from'./Results/Loading.react';

import RecipeCard from './ResultsMini/RecipeCard.react';
import ComboCard from './ResultsMini/ComboCard.react';
import FoodCard from './ResultsMini/FoodCard.react';
import FoodDetailsCard from './ResultsMini/FoodDetailsCard.react';
import CollectionCard from './ResultsMini/CollectionCard.react';
import BrandCard from './ResultsMini/BrandCard.react';

import { fetchDocumentsById } from '../../utils/Content';

import './ResultsMini.scss';

export default class ResultsMini extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        results: PropTypes.array,
        noResultsCopy: PropTypes.node,
        recommendedResults: PropTypes.number
    };

    static defaultProps = {
        loading: false,
        results: [],
    };

    renderResult = (result, key) => {
        const {
            params: { sort_by, sort_order },
            recommendedResults,
        } = this.props;

        if (result.type === 'recipe') {
            return (
                <RecipeCard
                    sortBy={sort_by}
                    sortOrder={sort_order}
                    key={key}
                    organicRanking={result.organic_ranking}
                    searchRanking={key + 1}
                    resultType={result.result_type}
                    searchTerm={this.props.searchTerm}
                    recipe={result}
                />
            );
        } else if (result.type === 'combo') {
            return (
                <ComboCard
                    sortBy={sort_by}
                    sortOrder={sort_order}
                    key={key}
                    organicRanking={result.organic_ranking}
                    searchRanking={key + 1}
                    resultType={result.result_type}
                    searchTerm={this.props.searchTerm}
                    combo={result}
                />
            );
        } else if (result.type === 'food') {
            return (
                <FoodCard
                    sortBy={sort_by}
                    sortOrder={sort_order}
                    key={key}
                    organicRanking={result.organic_ranking}
                    searchRanking={key + 1}
                    resultType={result.result_type}
                    searchTerm={this.props.searchTerm}
                    food={result}
                />
            );
        } else if (result.type === 'brand') {
            return <BrandCard key={key} brand={result} />;
        } else if (result.type === 'collection') {
            return <CollectionCard key={key} collection={result} />;
        } else if (result.type === 'food_details') {
            return <FoodDetailsCard key={key} foodDetails={result} />;
        } else {
            return (
                <div key={key}>
                    unknown: {result.uuid} {result.type} {result.title}
                </div>
            );
        }
    };

    render() {
        const { params, loaded, loading, results, noResultsCopy } = this.props;

        return (
            <div className="global-search-results-mini">

                {loaded && !loading && !(results && results.length) ?
                    <NoResults noResultsCopy={noResultsCopy} params={params} />
                : null}

                <ul>
                    {results.map(this.renderResult)}
                </ul>

                {loading ?
                    <Loading />
                : null}
            </div>
        );
    }
}
