'use strict'

import React, { useEffect, useState } from 'react'
import clamp from 'lodash/clamp'
import { useSprings, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'

import MealCard from './Feed/MealCard.react';

import './SpringFeed.scss';

const CARD_WIDTH = 295; // px

export default function SpringFeed(props) {
    let [index, setIndex] = useState(props.items.indexOf(props.activeItem));

    const [cards, setCard] = useSprings(props.items.length, i => {
        return {
            x: (i - index) * CARD_WIDTH + window.innerWidth / 2 - CARD_WIDTH / 2,
            sc: 1,
            display: 'block',
        };
    });

    useEffect(() => {
        index = props.items.indexOf(props.activeItem);
        setIndex(index);

        setCard(i => {
            const x = (i - index) * CARD_WIDTH + window.innerWidth / 2 - CARD_WIDTH / 2;

            return { x, sc: 1, display: 'block', immediate: true }
        });
    }, [props.startDate, props.endDate]); // only fire this effect when start or end date change

    useEffect(() => {
        index = props.items.indexOf(props.activeItem);
        setIndex(index);

        setCard(i => {
            const x = (i - index) * CARD_WIDTH + window.innerWidth / 2 - CARD_WIDTH / 2;

            return { x, sc: 1, display: 'block', immediate: false }
        });
    }, [props.activeItem]); // only fire this effect when the activeItem changes

    const bind = useDrag((dragArgs) => {
        let { xy: [x, y], last, initial: [xInitial, yInitial], down, direction: [xDir, yDir],
                distance, cancel } = dragArgs;

        // Compute our difference from where we started to where we are now, x dimension only.
        let xDelta = x - xInitial;

        if (props.isSafariMobile) {
            cancel();
            return;
        }

        if (last) {
            cancel(); // will this stop propagation of the click event? Seems to otherwise.
        }

        // Increase the denominator to increase sensitivity
        const sensitivity = CARD_WIDTH / 5;

        if (down && Math.abs(xDelta) > sensitivity) {
            index = clamp(index + (xDir > 0 ? -1 : 1), 0, props.items.length - 1);

            if (xDir > 0) {
                props.prevCard();
            } else {
                props.nextCard();
            }

            cancel(index);
        }

        setCard(i => {
            const x = (i - index) * CARD_WIDTH + window.innerWidth / 2 - CARD_WIDTH / 2 + (down ? xDelta : 0);
            const sc = down ? 1 - Math.abs(xDelta) / CARD_WIDTH / 5 : 1

            return { x, sc, display: 'block', immediate: false }
        });
    });

    return (
        <div className="spring-feed" {...bind()}>
            {cards.map(({ x, display, sc }, i) => (
                <animated.div className="spring-feed-item" key={i} style={{ display, transform: x.interpolate(x => `translate3d(${x}px,0,0)`) }}>
                    <animated.div className="spring-feed-item-container" style={{ transform: sc.interpolate(s => `scale(${s})`)}}>
                        <MealCard item={props.items[i]} key={i}
                            ref={el => props.items[i].element = el}
                            items={props.items} currentIndex={i}
                            thumped={false}
                            activeFlipped={false}

                            activeDate={props.activeDate}
                            activeMeal={props.activeMeal}
                            activeItem={props.activeItem}
                            currentMealType={props.currentMealType}
                            onClickItemImage={props.onClickItemImage} />
                    </animated.div>
                </animated.div>
            ))}
        </div>
    );
}

