'use strict';

import { Component } from 'react';
import uuid from 'uuid';

import FamilyMember from './FamilyMember.react';
import Expander from '../../Widgets/Expander.react';

import './Family.scss';
import './PatientForm.scss';

export default class Family extends Component {
    static propTypes = {};

    static defaultProps = {};

    static contextTypes = {};

    constructor(props) {
        super(props);

        const { patient = {} } = props;
        const family = (patient.family || []).slice();
        const adults_in_family = family.filter((member) => member.type == 'adult').length;
        const children_in_family = family.filter((member) => member.type == 'child').length;
        const preteens_in_family = family.filter((member) => member.type == 'preteen').length;

        this.state = {
            adults_in_family,
            preteens_in_family,
            children_in_family,
            family,
            dirty: false,
        };
    }

    isDirty = () => {
        return this.state.dirty;
    };
 
    countFamily = (family = []) => {
        let adults_in_family = 0,
            preteens_in_family = 0,
            children_in_family = 0;

        family.forEach((member) => {
            if (member.type === 'child') {
                children_in_family++;
            } else if (member.type === 'preteen') {
                preteens_in_family++;
            } else {
                adults_in_family++;
            }
        });

        return { adults_in_family, preteens_in_family, children_in_family };
    };

    validate = () => {
        return true;
    };

    mutate = (patient) => {
        const { family } = this.state;

        patient.family = family;

        if (!patient.completed.includes('family')) {
            patient.completed.push('family');
        }

        return patient;
    };

    refineFamilyNames = (family) => {
        let adults = 0,
            preteens = 0,
            children = 0;

        family = family.map((member) => {
            if (member.name.match(/Adult \#/)) {
                member.name = 'Adult #' + ++adults;
            }

            if (member.name.match(/Preteen \#/)) {
                member.name = 'Preteen #' + ++preteens;
            }

            if (member.name.match(/Kid \#/)) {
                member.name = 'Child #' + ++children;
            }

            if (member.name.match(/Child \#/)) {
                member.name = 'Child #' + ++children;
            }

            return member;
        });

        return family;
    };

    appendAdult = () => {
        let { family } = this.state;

        family.push({
            uuid: uuid.v4(),
            name: 'Adult #',
            portion: 1,
            type: 'adult'
        });

        family = this.refineFamilyNames(family);

        this.setState({ family, dirty: true, ...this.countFamily(family) });
    };

    appendPreteen = () => {
        let { family } = this.state;

        family.push({
            uuid: uuid.v4(),
            name: 'Preteen #',
            portion: 1,
            type: 'preteen'
        });

        family = this.refineFamilyNames(family);

        this.setState({ family, dirty: true, ...this.countFamily(family) });
    };

    appendChild = () => {
        let { family } = this.state;

        family.push({
            uuid: uuid.v4(),
            name: 'Child #',
            portion: 0.5,
            type: 'child'
        });

        family = this.refineFamilyNames(family);

        this.setState({ family, dirty: true, ...this.countFamily(family) });
    };

    subtractFamilyMember(family, type) {
        // Find the last child and pop it off the end.
        var i = family.length - 1;

        for (; i >= 0; i--) {
            if (family[i].type === type) {
                break;
            }
        }

        if (i === -1) {
            return family;
        }

        family.splice(i, 1);

        return family;
    }

    subAdults = () => {
        let family = this.subtractFamilyMember(this.state.family, 'adult', 1);

        family = this.refineFamilyNames(family);

        this.setState({ family, dirty: true, ...this.countFamily(family) });
    };

    subPreteen = () => {
        let family = this.subtractFamilyMember(this.state.family, 'preteen', 1);

        family = this.refineFamilyNames(family);

        this.setState({ family, dirty: true, ...this.countFamily(family) });
    };

    subchildren = () => {
        let family = this.subtractFamilyMember(this.state.family, 'child', 0.5);

        family = this.refineFamilyNames(family);

        this.setState({ family, dirty: true, ...this.countFamily(family) });
    };

    onChangeMember = (member) => {
        const { family } = this.state;

        let i = family.indexOf(family.find((f) => f.uuid === member.uuid));

        family[i] = member;

        this.setState({ family, dirty: true });
    };

    onRemoveMember = (member) => {
        let { family } = this.state;

        family.splice(family.indexOf(member), 1);
        family = this.refineFamilyNames(family);

        this.setState({ family, ...this.countFamily(family), dirty: true });
    };

    onOpenFamilyDetails = () => {
        this.setState({ showSpinnerButtons: false });
    };

    getAgeRange = () => {
        const { patient } = this.props;
        const birthdate = new Date(patient.birthdate);
        const cur = new Date();
        const diff = cur - birthdate;
        const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));

        if (age < 7) {
            return 'child';
        }

        if (age < 13) {
            return 'preteen';
        }

        return 'adult';
    };

    render() {
        const { patient } = this.props;
        const { adults_in_family, preteens_in_family, children_in_family, family } = this.state;

        const ageRange = this.getAgeRange();
        
        const participants = [
            { label: "adults", arr: family.filter(m => m.type === "adult") },
            { label: "preteens", arr: family.filter(m => m.type === "preteen") },
            { label: "children", arr: family.filter(m => m.type === "child") }
        ].filter((participant) => participant.arr.length);

        const content = (
            <div className="family-content">
                {family.length === 0 ? (
                    <p className="empty-family">No family members regularly eat with {patient.first_name}.</p>
                ) : null}

                {participants.map(({label, arr}) => (
                    <div>
                        {arr.length > 0 ?
                            <h3>Tell us about the {label} in {patient.first_name}&apos;s family</h3>
                        : null}

                        {arr.map((member, i) => (
                            <FamilyMember
                                profile={patient}
                                member={member}
                                onChange={this.onChangeMember}
                                onRemove={this.onRemoveMember}
                                key={i}
                            />
                        ))}
                    </div>
                ))}

                <footer>
                    <button className="sub-action-btn" onClick={this.appendAdult}>
                        Add an adult
                    </button>
                    <button className="sub-action-btn" onClick={this.appendPreteen}>
                        Add a preteen
                    </button>
                    <button className="sub-action-btn" onClick={this.appendChild}>
                        Add a child
                    </button>
                </footer>
            </div>
        );

        return (
            <div className="edit-family patient-form">
                
                <p className="subtext">
                    <em>
                        How many people will be eating (including{" "}
                        {patient.first_name})?
                    </em>
                </p>

                <section className="quick-add-family" data-visible={true}>
                    <div className="spinners">
                        <div className="adult-count spinner">
                            <button className="decrement" onClick={this.subAdults}>
                                -
                            </button>
                            <button className="increment" onClick={this.appendAdult}>
                                +
                            </button>
                            <div className="content">
                                <i className="icon-adults" />
                                <em>{ageRange == 'adult' ? adults_in_family + 1 : adults_in_family}</em>
                                <p>ADULTS</p>
                            </div>
                            {ageRange == 'adult' && (
                                <div className="label">
                                    <span>Includes&nbsp;</span>
                                    <span className="name">{patient.first_name}</span>
                                </div>
                            )}
                        </div>

                        <div className="kid-count spinner">
                            <button className="decrement" onClick={this.subPreteen}>
                                -
                            </button>
                            <button className="increment" onClick={this.appendPreteen}>
                                +
                            </button>
                            <div className="content">
                                <i className="icon-kids" />
                                <em>{ageRange == 'preteen' ? preteens_in_family + 1 : preteens_in_family}</em>
                                <p>PRETEENS</p>
                            </div>
                            {ageRange == 'preteen' && (
                                <div className="label">
                                    <span>Includes&nbsp;</span>
                                    <span className="name">{patient.first_name}</span>
                                </div>
                            )}
                        </div>

                        <div className="kid-count spinner">
                            <button className="decrement" onClick={this.subchildren}>
                                -
                            </button>
                            <button className="increment" onClick={this.appendChild}>
                                +
                            </button>
                            <div className="content">
                                <i className="icon-kids" />
                                <em>{ageRange == 'child' ? children_in_family + 1 : children_in_family}</em>
                                <p>CHILDREN</p>
                            </div>
                            {ageRange == 'child' && (
                                <div className="label">
                                    <span>Includes&nbsp;</span>
                                    <span className="name">{patient.first_name}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <p>
                        * Children are age 6 and below and are calculated at half portion. <br />* Preteens are age 7 to
                        12 and calculated single portion.
                    </p>
                </section>

                {family.length ?
                    <Expander
                        button={<span>Edit Individual Family Members</span>}
                        onToggle={this.onOpenFamilyDetails}
                        isCollapsible={true}
                        className="family-details"
                    >
                        {content}
                    </Expander>
                : null}
            </div>
        );
    }
}
