'use strict';

export default [
    {
        mainDish: 'Dinner',
        plural: 'Dinners',
        threshold: 100,
        tags: {
            main: 'Dinner',
            side: 'Side Dish',
        },
    },
    {
        mainDish: 'Lunch',
        plural: 'Lunches',
        threshold: 75,
        tags: {
            main: 'Lunch',
            side: 'Lunch Side Dish',
        },
    },
    {
        mainDish: 'Breakfast',
        plural: 'Breakfasts',
        threshold: 50,
        tags: {
            main: 'Breakfast',
            side: 'Breakfast Side Dish',
        },
    },
    {
        mainDish: 'Snack',
        plural: 'Snacks',
        threshold: 20,
        tags: {
            main: 'Snack',
        },
    },
];
